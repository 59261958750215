import {
  InfoOutlined,
  DeleteOutline,  // Import the trash bin icon
} from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,  // Import IconButton for clickable icons
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomButton from "../../components/CustomButton";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import {
  BASE_URL,
  ADD_GIF,
} from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import { useDropzone } from 'react-dropzone';
import CustomDateEditPicker from "../../components/CustomDateEditPicker"
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  GROUP_NAME_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  STATE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  STTUS_REQUIRED_MESSAGE
} from "../../utills/ApplicationConstants";

const SidebarMenu = () => {
  const [currentSection, setCurrentSection] = useState('Add Gif');
  const fileTypes = ["GIF"];
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const [gifPreview, setGifPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors }, setError,
  } = useForm();

  const handleFileChange = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      setErrorMessage("No valid file uploaded.");
      setFile(null);
      setGifPreview(null);
      return;
    }

    const selectedFile = acceptedFiles[0]; // Ensure there's a file

    if (selectedFile && selectedFile.size > 10 * 1024 * 1024) {
      setErrorMessage("File size exceeds the maximum limit of 10MB.");
      setFile(null);
      setGifPreview(null);
      return;
    }

    if (selectedFile && selectedFile.type === "image/gif") { // Ensure it's a GIF file
      const previewUrl = URL.createObjectURL(selectedFile); // Create a preview URL for the GIF
      const img = new Image();
      img.src = previewUrl;

      img.onload = () => {
        const { width, height } = img;
        console.log("Width:", width);
        console.log("Height:", height);

        // Validate the width and height
        if (width < 700 || height < 80) {
          setErrorMessage("File dimensions should be above or equal to recommended dimensions.");
          setFile(null);
          setGifPreview(null);
        } else {
          setErrorMessage("");
          setFile(selectedFile);
          setGifPreview(previewUrl);
        }
      };
    } else {
      setErrorMessage("Invalid file type. Please upload a GIF file.");
      setFile(null);
      setGifPreview(null);
    }
  };



  const handleRemoveFile = () => {
    setFile(null);
    setGifPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChange,
    accept: { 'image/gif': [] },
    maxFiles: 1,
  });


  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(3, 'year').endOf('day');
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);

  const handleFromDateChange = (selectedFromDate) => {

    setToDate("");
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);

  };

  const onSubmit = async () => {

    if (!fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!file) {
      setErrorMessage("Please upload a file before submitting.");
      return;
    }


    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("startDate", dayjs(fromDate).format("YYYY-MM-DD"));
    formData.append("endDate", dayjs(toDate).format("YYYY-MM-DD"));
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: ADD_GIF, method: "post", data: formData, headers })
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            setFile(null);
            setGifPreview(null);
            setIsLoading(false);
            navigate(`/notifications`);
          }, 2000);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status", error.response.status);
        }
      });
  };

  const handleClick = () => {
    navigate(-1);
  };

  const addModelAction = (
    <CustomButton fieldType="submit" buttonName="Update" />
  );

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Add Notification" />
        {isLoading && <CustomLoading />}
        <div className="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
          <Button variant="contained" onClick={handleClick}>
            <span className="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>

      <div>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row" style={{ border: '2px solid rgba(204, 204, 204, 0.5)' }}>


          <div className="flex-1 flex items-left justify-left p-8">
            <div className="text-center w-full md:w-3/4">

              <form onSubmit={handleSubmit(onSubmit)} className="mt-[-20px]">
                <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                  <CustomDateEditPicker
                    fieldName="fromDate"
                    label="From Date"
                    fieldRequiredIcon="*"
                    control={control}
                    // requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => handleFromDateChange(date)}
                    tooltipMessage="Select from date"
                  />

                  <CustomDateEditPicker
                    fieldName="toDate"
                    label="To Date"
                    fieldRequiredIcon="*"
                    control={control}
                    // requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                    minDate={toDateMinDate}
                    maxDate={toDateMaxDate}
                    disabled={toDateDisabled}
                    value={toDate}
                    onChange={handleToDateChange}
                    tooltipMessage="Select to date"
                  />
                  <br />
                  <div className="flex items-center md:w-1/2 w-full">
                    <p className="font-bold" >Upload Gif</p>
                  </div>
                  <DialogContent>
                    <p className="text-sm leading-relaxed text-gray-900 text-left">
                      Supported Documents (GIF):{" "}
                      <span style={{ color: "red" }}>*</span>
                      <InfoOutlined
                        style={{ fontSize: "19px" }}
                        titleAccess="File size should be within the range of a maximum of 10MB"
                      />
                    </p>
                    <br/>
                    <p className="text-sm leading-relaxed text-gray-900 text-left">Recommended File dimensions:720*90</p>
                    <br />
                    <p className="text-sm leading-relaxed text-gray-900 text-left">
                      Please upload a GIF file for customer notification
                    </p>
                    <br />
                    <div>
                      <div
                        {...getRootProps({
                          className:
                            "border-dashed border-2 border-gray-300 p-6 text-center cursor-pointer",
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className="text-sm text-gray-600">
                          Drag & drop a GIF here, or click to select one
                        </p>
                        {/* 720*90 for full width */}
                      </div>
                      {file ? (
                        <p style={{ fontSize: "14px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "normal" }}>
                          Selected File: {file.name}
                        </p>

                      ) : (
                        <p className="text-sm leading-relaxed text-gray-900 text-left">
                          No file selected
                        </p>
                      )}
                    </div>
                    {errorMessage && (
                      <p className="text-red-500" style={{ fontSize: "14px" }}>
                        {errorMessage}
                      </p>
                    )}
                    {gifPreview && (
                      <div className="mt-4 relative">
                        <Typography style={{ fontSize: "14px", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "normal" }}>GIF Preview:</Typography>
                        <img
                          src={gifPreview}
                          alt="GIF Preview"
                          className="mt-2"
                          style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
                        />
                        <IconButton
                          onClick={handleRemoveFile}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </div>
                    )}
                    <div className="flex-1 flex items-left justify-left p-8">
                      <Typography className="py-2" style={{ fontSize: "14px" }}>
                        Note: File size should be within the range of a maximum of 10MB
                      </Typography>
                    </div>
                  </DialogContent>

                  <div className="flex-1 flex items-center justify-center p-8">
                    <DialogActions>{addModelAction}</DialogActions>
                  </div>
                </section>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
