import React, { useEffect, useMemo, useState } from 'react'
import CustomHeading from '../../components/CustomHeading'
import CustomMaterialTable from '../../components/CustomMaterialTable'
import { useSelector } from 'react-redux';
import { BASE_URL, GROUP, SUBSCRIPTION, SUBSCRIPTION_AUDIT } from '../../utills/ApplicationRouting';
import axios from 'axios';
import { ModeComment } from '@mui/icons-material';
import moment from 'moment';
import CustomAutoComplete from '../../components/CustomAutoComplete';
import { GROUP_NAME_REQUIRED_MESSAGE, HOME_ADDRESS_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import CustomAutoCompleteHistory from '../CustomAUtoCompleteHistory';
import CustomButton from '../../components/CustomButton';
import { Controller, useForm } from "react-hook-form";
import { Card, CardContent } from '@mui/material';
import CustomLoading from "../../components/CustomLoading";
import CustomMaterialWithoutRoutes from '../../components/CustomMaterialWithoutRoutes';


function SubscriberHistory() {
  const { token,userGroupDetails } = useSelector((state) => state.hybridhoa);
   const [adminGroupId,setAdminGroupId]=useState("")
  const [homeDetails,setHomeDetails]=useState('');
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [homeOwnerDetailsId,setHomeOwnerDetailsId]= useState('');
  
const[groupDetails,setGroupDetails]=useState([])
  const getAllGroupDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP}/all`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setGroupDetails(data);

        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllGroupDetails();
  }, []);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, watch,
  } = useForm();
console.log("errors",errors);

  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = groupDetails.find((home) => home.groupId === selectedHomeId);
      console.log(selectedHome,"TestCaseScenarioOfHome");
      const homeOwner = selectedHome.groupName;
      console.log(homeOwner,"TestCase");

      if (homeOwner) {
        const  groupIdAdmin =`${selectedHome.groupId}`
        setAdminGroupId(groupIdAdmin);
        setValue("selectedOption", groupIdAdmin);
         console.log("change Id",groupIdAdmin);
  } else {
        setValue("selectedOption", null);
      }
    } else {
      setHomeDetails('');
      setValue("selectedOption", null);
    }
  };
  
  useEffect(() => {
    const selectedHome = watch('selectedOption');
  
  
      if (!selectedHome || selectedHome === "") {
        setHomeDetails('');
        // setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingHome = groupDetails.find((home) => home.groupId === selectedHome);
  
        if (matchingHome) {
          const groupAdminId= `${matchingHome.groupId}`
          setAdminGroupId(groupAdminId);
          console.log("testCase of group Admin Id",groupAdminId);
          // const { firstName, lastName } = matchingHome.homeOwnerDetails;
  // setHomeDetails(`${firstName} ${lastName}`);
          // setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setHomeDetails('');
          // setIsBylawFieldEmpty(true);
        }
      }
    
  }, [watch('selectedOption'), homeDetails]);
  const renderedAddresses = groupDetails
  .map((home, index) => ({
    address: home.groupName, 
    value: home.groupId,   
    key: index,
  }));
   
    const [isLoading,setIsLoading]=useState(false)
    const [subscription,setSubscription]=useState([])
    // const [groupIds,setGroupIds]=useState(null)
    // const groupId=userGroupDetails.groupId
    
    const onSubmit = async (data) => {
      console.log("testCase");
      try {
        setIsLoading(true);
        const storedToken = token;
        
      
        const response = await axios.get(`${BASE_URL}${SUBSCRIPTION_AUDIT}/${adminGroupId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setSubscription(data);
          setIsLoading(false);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
    // useEffect(() => {
    //     getSubscriptionDetails();
    // }, [adminGroupId]);
    const subscriberHistoryTable = useMemo((rowData) =>[
        {
            accessorKey:"serialNo",
            header:"#",
            Cell: ({ row }) => row.index + 1,

        },
        {
            accessorKey:"groupName",
            header:"Group Name"
        },
        {
          accessorKey:"name",
          header:"System Admin Name"
      },
        {
            accessorKey:"createdDateAndTime",
            header:"Audit Date and Time",
            accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm A"),

        },
        {
            accessorKey:"action",
            header:"Actions"
        },
    ])
  return (
    <div>
        <CustomHeading title="Subscribers History" />
        
        {isLoading && <CustomLoading />}
        <Card>
        <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="grid md:grid-cols-2 xl:grid-cols-2 gap-4">
            
        <CustomAutoCompleteHistory
              fieldName="selectedOption"
              requiredErrorMessage = {GROUP_NAME_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedAddresses}
              fieldLabel="Select Group Name"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
              onChange={handleHomeChange}
               />
             </div>
               <div class="text-left  mt-1">
<CustomButton  fieldType="submit" buttonName="Search" />
</div>
               
        </form>
        </CardContent>
        </Card>
       
        <CustomMaterialWithoutRoutes 
        columns= {subscriberHistoryTable}
        data = {subscription}
        
         />
    </div>
  )
}

export default SubscriberHistory