
import React from "react";
import { CustomStyle } from "./CustomStyle";
import CustomHeading from "./CustomHeading";
import { Button, Card, CardContent } from "@mui/material";


const CustomViewPage = React.forwardRef(({ data, children,exportedBy }, ref) => {
  const customViewPageRef = ref || React.createRef();

  const renderComponent = (item) => {
    switch (item.topic) {
      case "Attachments":
        return (
          <div className={CustomStyle.borderedDiv}>
            <h3 className={CustomStyle.viewPageText}>
              <b>{item.topic} :</b>
             
            </h3>
          </div>
        );
      default:
        return (
          <div className={CustomStyle.borderedDiv}>
            <h3 className={CustomStyle.viewPageText}>
              <b>{item.topic} :</b> {item.description} {item.icon}
            </h3>
          </div>
        );
    }
  };

  // Add topics with styles
  const handleExport = () => {
    const element = customViewPageRef.current;

    if (!element) {
      console.error("Unable to find element");
      return;
    }


    // Configure the styles
    const styles = {
      fontSize: 10,
      blockColor: "#f2f2f2", // Light block color
      title: {
        fontSize: 10,
        fontWeight: "bold",
        color: "#FF6666",
      },
      topic: {
        fontWeight: "bold",
        color: "#333333",
        fontSize: 10,
      },
      description: {
        color: "#333333",
        fontSize: 20 // Light font color
      },
    };

    // Add topics with styles
    const addTopic = (topic1, description1, topic2, description2, y) => {
      // Skip adding topic and description if the topic is "Attachments"
      if (topic1.toLowerCase() === "attachments") {
        return y; // No change in Y position
      }
    
      // Set font size to 10 for topics and descriptions
      pdf.setFontSize(12);
    
      // Topics and descriptions in bold and centered
      pdf.setFont(styles.topic.fontWeight, 'bold');
      pdf.setTextColor(styles.topic.color); // Red color for topics
      pdf.text(`${topic1}: ${description1}`, 15, y + 10 / 2);
    
      if (topic2) {
        // If there is a second topic, display it
        pdf.setTextColor(styles.description.color); // Blue color for descriptions
        pdf.text(
          `${topic2}: ${description2}`,
          pdf.internal.pageSize.width / 2,
          y + 10 / 2
        );
      }
    
      pdf.setFont('normal'); // Reset font style
    
      // Reduce the gap between rows
      return y + 10 + 1;
    };
    
 
    // Center content
    pdf.text(data.pageTitle, pdf.internal.pageSize.width / 2, 15, {
      align: "center",
      fontSize: styles.title.fontSize,
      fontWeight: styles.title.fontWeight,
    });

    // Loop through content and add to PDF
    let currentY = 25; // Starting Y position
    for (let i = 0; i < data.content.length; i += 2) {
      const row1 = data.content[i];
      const row2 = data.content[i + 1]; // Allow row2 to be undefined

      currentY = addTopic(
        row1.topic,
        row1.description,
        row2?.topic,
        row2?.description,
        currentY
      );
    }

    // Save or open the PDF as needed
    pdf.save("custom-view-page.pdf");
  };
  

  return (
    <div ref={customViewPageRef}>
      <div className="flex flex-col md:flex-row">
        <CustomHeading title={data.pageTitle} />


        <div className="md:full md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">

          {data.buttons.map((i, index) => (
            <Button variant="contained" key={index} onClick={i.onClick}>
              <span className={CustomStyle.containedButtonStyle}>
                {i.buttonTitle}
              </span>
            </Button>
          ))}
          {exportedBy &&(
           <Button variant="contained" onClick={handleExport}>
           <span className={CustomStyle.containedButtonStyle}>
            Export
            </span>
          </Button>
          )}
        </div>
      </div>
      <Card>
        <CardContent>
          <section className={CustomStyle.viewPageGrid}>
            {data.content.map((item, index) => (
              <div key={index}>{renderComponent(item)}</div>
            ))}
          </section>
          {children}
        </CardContent>
      </Card>
      
    </div>
  );
});

export default CustomViewPage;
