// export const BASE_URL="http://localhost:3030/api/v1";

// export const BASE_URL = "https://apilivespprod.hybridhoaonline.com/hhoa-system-portal/api/v1";

// export const BASE_URL="http://10.64.26.139:3030/api/v1";
// export const BASE_URL_IMAGE = "http://10.64.26.139:3030/api/v1/file";
// export const BASE_URL = "https://apisd.hybridhoaonline.com/hhoa-system-portal/api/v1";

// export const BASE_URL_IMAGE = "https://apilivespprod.hybridhoaonline.com/hhoa-system-portal/api/v1/file";
// export const BASE_URL_IMAGE = "https://apisd.hybridhoaonline.com/hhoa-system-portal/api/v1/file";

export const BASE_URL = "https://systemapi.hybridhoa.com/hhoa-system-portal/api/v1";
export const BASE_URL_IMAGE = "https://systemapi.hybridhoa.com/hhoa-system-portal/api/v1/file";



export const LOGIN="/auth/adminlogin";

export const PROFILE ="/profile";

export const DASHBOARD ="/dashboard";
export const CHANGE_PASSWORD = "/changepassword";
export const FORGOT_CHANGE="/forgetpassword/verify";
export const FORGOT_PASSWORD="/forgetpassword";
export const ADD_SYSTEAM_ADMIN= "/admin/addsystemadmin";
export const FORGOT="/forgot";
export const GET_ALL_SYSTEM_ADMIN = "/admin/systemadmin/all";

export const GET_SYSTEM_ADMIN = "/admin/systemadmin/all?role=ADMIN";

export const DELETE_SYSTEM_ADMIN = "/admin/systemadmin";

export const ACTIVE_SYSTEM_ADMIN = "/admin/systemadmin/activate";

export const DEACTIVE_SYSTEM_ADMIN = "/admin/systemadmin/deactivate";

export const GET_SYSTEM_ADMIN_BY_ID = "/admin/systemadmin";

export const UPDATE_SYSTEM_ADMIN = "/admin/updatesystemadmin";

export const GET_ALL_GROUP_ADMIN = "/groupadmin/all";

export const ADD_GEOUP_ADMIN = "";

export const UPDATE_GROUP_ADMIN = "";

export const DELETE_GROUP_ADMIN = "";

export const GET_ALL_GROUP_ADMIN_BY_ID = "";

export const ACTIVE_GROUP_ADMIN = "/groupadmin/activate";
export const DEACTIVE_GROUP = "/group/scheduleddeactivate";
export const DEACTIVE_GROUP_ADMIN = "/groupadmin/deactivate";
export const ACTIVE_GROUP = "/group/activate";
export const GROUP_ADMIN = "/groupadmin";
export const FORGOT_PASSWORD_EMAIL="/forgetpassword/email/";

export const PROMOTE_AS_PRIMARY = "/groupadmin/promote";

export const GROUP = "/group";

export const GET_ALL_GROUP_DETAILS = "";

export const SYSTEM_ADMIN_RESET_PASSWORD = "admin/systemadmin/resetpassword";

export const ADMIN_RESET_PASSWORD = "/groupadmin/resetpassword";

export const SUBSCRIPTION="/subscription";

export const GET_SUBSCIPTION = "/subscription";

export const PAYMENT_DETAILS ="/paymentdetails";

export const SUBSCRIPTION_AUDIT="/group/audit";

export const BILLING_DETAILS = "/paymentdetails/invoice";

export const GET_GROUP ="/group/all"

export const AUDIT ="/audit"

export const RESEND_EMAIL ="/group/resend"

export const SEND_OTP="/groupadmin/sendotp"

export const VERIFY_OTP="/groupadmin/verifyotp"

export const DASHBOARD_PRESIST="/presist"

export const ADD_GIF="/customer/upload"

export const GIF_LIST="/customer"
