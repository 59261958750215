import React, { useEffect, useState, useRef } from "react";
import { Autocomplete } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { TextField, Tooltip, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const CustomAutoComplete = ({
  fieldName,
  requiredErrorMessage,
  fieldPattern,
  patternErrorMessage,
  fieldId,
  fieldLabel,
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  options,
  getOptionLabel,
  validator,
  tooltipMessage
}) => {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  const { setValue, control, watch } = useForm({
    defaultValues: {
      [fieldName]: value || "",
    },
  });

  const [values, setValues] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);

  // Ref for Autocomplete input focus
  const inputRef = useRef(null);

  useEffect(() => {
    // Set the initial value in the form control when props.value changes
    setValue(fieldName, value || "");
    setIsEmpty(value === undefined); // Update isEmpty based on whether value is empty or not
  }, [value, setValue, fieldName]);

  useEffect(() => {
    console.log("value", isEmpty);
  }, [isEmpty]);

  return (
    <div className="flex items-center w-full md:w-1/2">
    <div className="flex-1 ">
    <Controller
      name={fieldName}
      control={fieldControl}
      defaultValue={defaultValue}
      rules={{
        required: requiredErrorMessage,
      }}
      render={({ field, fieldState }) => (
        <>
          {options && (
            <Autocomplete
              {...field}
              options={options}
              getOptionLabel={(option) => option.label || ""}
              value={
                options.find((option) => option.label === field.value)
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setValue(fieldName, newValue.label || "", {
                    shouldDirty: true,
                  });
                  setValues(fieldName, newValue.label || "");
                  setIsEmpty(false); // Update isEmpty when a value is selected
                  console.log("working");
                }

                if (onChange) {
                  onChange(event, newValue);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  label={
                    <CustomInputLabel
                      label={fieldLabel}
                      requiredIcon={fieldRequiredIcon}
                    />
                  }
                  margin="dense"
                  variant="standard"
                  error={fieldState.invalid && isEmpty} // Display error only if the field is empty
                  helperText={
                    fieldState.invalid && isEmpty
                      ? fieldState.error?.message
                      : ""
                  }
                />
              )}
              clearOnBlur={false} // Add this line to prevent clearing on blur
            />
          )}
        </>
      )}
    />
    </div>
      {tooltipMessage && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomAutoComplete;
