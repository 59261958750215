import React from 'react'
import { CustomStyle } from './CustomStyle'

const CustomHeading = (props) => {
  return (
    <div class="md:w-1/2 md:pr-4 pt-3">
        <h1 className={CustomStyle.mainHeading}>{props.title}</h1>
        
      </div>
  )
}

export default CustomHeading