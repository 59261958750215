import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Button, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CustomAccordion({ defaultExpanded = false, accordionActions = false, heading, children }) {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />} // Hide icon on small screens, show on md and larger
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography><span className='font-bold text-lg'>{heading}</span></Typography>
      </AccordionSummary>
      <AccordionDetails>
      <div className='ml-10'>
        {children}
        </div>
      </AccordionDetails>
      {accordionActions && (
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      )}
    </Accordion>
  );
}

export default CustomAccordion;
