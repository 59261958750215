import React, { useMemo, useState } from 'react';
import CustomHeading from '../../components/CustomHeading';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import { TAX_PERCENTAGE_REQUIED_MESSAGE, TAX_PERCENTAGE_VALID_MESSAGE, ZIPCODE_INVALID_MESSAGE, ZIPCODE_MAX_LENGTH_MESSAGE, ZIPCODE_MIN_LENGTH_MESSAGE, ZIPCODE_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { ONLY_DIGITS, TWO_DIGITS_TWO_DECIMAL } from '../../utills/ApplicationRegex';
import { useNavigate } from 'react-router-dom';
import { EditNoteOutlined } from '@mui/icons-material';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import { useForm } from 'react-hook-form';
import CustomInputField from '../../components/CustomInputField';
import CustomModel from '../../components/CustomModel';

function ZipCode() {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);


    const handleAddClickOpen = () => {
        setOpen(true);
      };
      const handleAddClose = () => {
        if (false) {
          setOpen(false);
        }
      };
      const handleIconClose = () => {
        setOpen(false);
      };
    
      const handleEditClickOpen = () => {
        setOpenEdit(true);
      };
      const handleEditClose = () => {
        if (false) {
          setOpenEdit(false);
        }
      };
      const handleEditIconClose = () => {
        setOpenEdit(false);
      };


      const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
      } = useForm();
    
      const onSubmit = (data) => {
        
      };
      const onSubmitEdit = (data) => {};
        
    const data = [
        {
            serialNo:1,
            zipCode :631502,
            taxPercentage : '10'
        },
        {
            serialNo:1,
            zipCode :631502,
            taxPercentage : '10'
        },
        {
            serialNo:1,
            zipCode :631502,
            taxPercentage : '10'
        },
    ]

    const zipCodeTable = useMemo((rowData) =>[
        {
            accessorKey:"serialNo",
            header:"Id",

        },
        {
            accessorKey:"zipCode",
            header:"ZipCode",
            
        },
        {
            accessorKey:"taxPercentage",
            header:"Tax Percentange",
            
        }
    ])

    const rowActions = [
      
        {
          label: "Edit",
          icon: <EditNoteOutlined color="primary" />,
          click: handleEditClickOpen,
        },
        
      ];


      const tableActionButtons = (
        <>
          <CustomButton
            fieldType="button"
            buttonName="Add"
            click={handleAddClickOpen}
          />
        </>
      );


      const addActionButton = (
        <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} />
      );
      const addModel = (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >
            <DialogContent dividers>
              <Typography gutterBottom>
              <CustomInputField
                fieldName="zipCode"
                requiredErrorMessage={ZIPCODE_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldId="zipCode"
                fieldType="text"
                fieldLabel="Zip Code"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
               
              />


<CustomInputField
                fieldName="taxPercentage"
                requiredErrorMessage={TAX_PERCENTAGE_REQUIED_MESSAGE}
                fieldPattern={TWO_DIGITS_TWO_DECIMAL}
                patternErrorMessage={TAX_PERCENTAGE_VALID_MESSAGE}            
                fieldId="taxPercentage"
                fieldType="text"
                fieldLabel="Tax Percentage"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
               
              />
             
              </Typography>
            </DialogContent>
            <DialogActions>{addActionButton}</DialogActions>
          </form>
        </>
      );
    
      const editActionButton = (
        <CustomButton
          fieldType="submit"
          buttonName="Update"
          click={handleEditClose}
        />
      );
      const editModel = (
        <>
          <form
            onSubmit={handleSubmit(onSubmitEdit)}
            className="space-y-4 md:space-y-6 "
          >
            <DialogContent dividers>
              <Typography gutterBottom>
              <CustomInputField
                fieldName="zipCode"
                requiredErrorMessage={ZIPCODE_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldId="zipCode"
                fieldType="text"
                fieldLabel="Zip Code"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
               
              />


<CustomInputField
                fieldName="taxPercentage"
                requiredErrorMessage={TAX_PERCENTAGE_REQUIED_MESSAGE}
                fieldPattern={TWO_DIGITS_TWO_DECIMAL}
                patternErrorMessage={TAX_PERCENTAGE_VALID_MESSAGE}            
                fieldId="taxPercentage"
                fieldType="text"
                fieldLabel="Tax Percentage"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
               
              />
              
              </Typography>
            </DialogContent>
            <DialogActions>{editActionButton}</DialogActions>
          </form>
        </>
      );

  return (
    <div>
        <CustomHeading title="ZipCode" />
        <CustomMaterialTable 
        columns = {zipCodeTable}
        data ={data}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
             
        />

<CustomModel
        title="Add Taxpercentage"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      />

      <CustomModel
        title="Edit Taxpercentange"
        submit={onSubmitEdit}
        content={editModel}
        action={editActionButton}
        openStatus={openEdit}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
      />
    </div>
  )
}

export default ZipCode