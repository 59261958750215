import React from 'react'
import ForgotPassword from '../../components/forgotpassword/ForgotPassword'

function Forgot() {
  return (
    <div>
      <ForgotPassword/>
    </div>
  )
}

export default Forgot
