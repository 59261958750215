import React from "react";
import { CustomStyle } from "./CustomStyle";
import { Button } from "@mui/material";


function CustomButton(props) {
  return (
    <Button
    variant="contained"
      type={props.fieldType}
      onClick={props.click}
      autoFocus
      // disabled={!props.isFormValid} !isDirty || !isFieldValid
      // disabled={!props.isDirty || !props.isFieldValid }
    >
    <span className={CustomStyle.containedButtonStyle}> {props.buttonName} </span>  
    </Button>
  );
}

export default CustomButton;
