import {
  InfoOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomButton from "../../components/CustomButton";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { GIF_LIST, BASE_URL, BASE_URL_IMAGE } from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import CustomDateEditPicker from "../../components/CustomDateEditPicker"
import { FROM_DATE_REQUIRED_MESSAGE, TO_DATE_REQUIRED_MESSAGE } from "../../utills/ApplicationConstants";

const SidebarMenu = () => {
  const [currentSection, setCurrentSection] = useState('Update Gif');
  const fileTypes = ["GIF"];
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const [gifPreview, setGifPreview] = useState(null);
  const [gifPreviewOld, setGifPreviewOld] = useState(null);
  const [gifPreviewOldone, setGifPreviewOldone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const navigate = useNavigate();
  const { customerNotificationId } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);

  const { handleSubmit, control, formState: { errors },setError } = useForm();

  const sections = {
    updateGif: 'Update Gif',
  };

  const handleSectionClick = (section) => {
    setCurrentSection(section);
  };

  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.size > 10 * 1024 * 1024) {
      setErrorMessage("File size exceeds the maximum limit of 10MB.");
      setFile(null);
      setGifPreview(null);
      return;
    }
    if (file && file.type === "image/gif") { // Ensure it's a GIF file
      const previewUrl = URL.createObjectURL(file ); // Create a preview URL for the GIF
      const img = new Image();
      img.src = previewUrl;

      img.onload = () => {
        const { width, height } = img;
        console.log("Width:", width);
        console.log("Height:", height);

        // Validate the width and height
        if (width < 700 || height < 80) {
          setErrorMessage("File dimensions should be above or equal to recommended dimensions.");
          setFile(null);
          setGifPreviewOldone("")
          setGifPreview(null);
          return;
        } 
        
      }
    } 
    setErrorMessage("");
    setFile(file);
    setGifPreview(URL.createObjectURL(file)); // Set new GIF preview
    setGifPreviewOld(null); // Clear old preview if new file is selected
    setIsFileChanged(true); // Indicate that the file has been changed
  };

  const handleRemoveFile = () => {
    setFile(null);
    setGifPreview(null);
    setIsFileChanged(false); // Reset file change status
  };

  const getNotificationDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${GIF_LIST}/${customerNotificationId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setNotificationData(data);

        setFromDate(data.startDate);
        setToDate(data.endDate)
        if (data.imageUrl) {
          setGifPreviewOldone(data.imageUrl);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetailsById();
  }, []);

  const getFile = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${gifPreviewOldone}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data;
      const fileURL = URL.createObjectURL(blob);
      setGifPreviewOld(fileURL);
      setIsLoading(false); // Set old GIF preview from the fetched image
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (gifPreviewOldone) {
      getFile();
    }
  }, [gifPreviewOldone]);
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(3, 'year').endOf('day');
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);

  const handleFromDateChange = (selectedFromDate) => {
   
    setToDate("");
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
   
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();

    console.log("gifPreviewOldone",gifPreviewOldone);
    
    if (!fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!file && !gifPreviewOld) {
      setErrorMessage("Please upload a file before submitting.");
      setIsLoading(false);
      return;
    }

 
    if (file) {
      formData.append("file", file);
    }

   if (!file && gifPreviewOld) {
    try {
      const response = await fetch(gifPreviewOld);
      const blob = await response.blob();
      const splitedgifPreviewOldone = gifPreviewOldone.substring(12)
      formData.append("file", blob,splitedgifPreviewOldone); 
    } catch (error) {
      console.error("Error fetching the old GIF:", error);
      setIsLoading(false);
      return;
    }
  }
    formData.append("startDate",  dayjs(fromDate).format("YYYY-MM-DD"));
    formData.append("endDate",  dayjs(toDate).format("YYYY-MM-DD"));
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    request({ url: `${GIF_LIST}/${customerNotificationId}`, method: "put", data: formData, headers })
      .then((res) => {
        if (res.status === 200) {
          setFile(null);
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/notifications`);
          }, 4000);
         
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
      });
  };

  const handleClick = () => {
    navigate(-1);
  };

  const addModelAction = (
    <CustomButton fieldType="submit" buttonName="Update" />
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChange,
    accept: { 'image/gif': [] },
    maxFiles: 1,
  });

  return (
    <div>
        <CustomToastContainer />
      <div className="flex flex-col md:flex-row">
      
        <CustomHeading title="Edit Notification" />

        {isLoading && <CustomLoading />}
        <div className="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
          <Button variant="contained" onClick={handleClick}>
            <span className="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>

      <div>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row" style={{ border: '2px solid rgba(204, 204, 204, 0.5)' }}>

          <div className="flex-1 flex items-left justify-left p-8">
            <div className="text-center w-full md:w-3/4">

              <form onSubmit={handleSubmit(onSubmit)} className="mt-[-20px]">
                <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                  <CustomDateEditPicker
                    fieldName="fromDate"
                    label="From Date"
                    fieldRequiredIcon="*"
                    control={control}
                    // requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => handleFromDateChange(date)}
                    value={fromDate}
                    tooltipMessage="Select from date"
                  />

                  <CustomDateEditPicker
                    fieldName="toDate"
                    label="To Date"
                    fieldRequiredIcon="*"
                    control={control}
                    // requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                    minDate={toDateMinDate}
                    maxDate={toDateMaxDate}
                    disabled={toDateDisabled}
                    value={toDate}
                    onChange={handleToDateChange}
                    tooltipMessage="Select to date"
                  />
                  <br />
                  <div className="flex items-center md:w-1/2 w-full">
                    <p className="font-bold" >Upload Gif</p>
                  </div>
                  <DialogContent>
                    <p className="text-sm leading-relaxed text-gray-900 text-left">
                      Supported Documents (GIF):{" "}
                      <span style={{ color: "red" }}>*</span>
                      <InfoOutlined
                        style={{ fontSize: "19px" }}
                        titleAccess="File size should be within the range of a maximum of 10MB"
                      />
                    </p>
                    <br/>
                     <p className="text-sm leading-relaxed text-gray-900 text-left">Recommended File dimensions:720*90</p>
                    <br />
                    <p className="text-sm leading-relaxed text-gray-900 text-left">
                      Please upload GIF file for customer notification
                    </p>
                    <br />
                    <div>
                      <div
                        {...getRootProps({
                          className:
                            "border-dashed border-2 border-gray-300 p-6 text-center cursor-pointer",
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className="text-sm text-gray-600">
                          Drag & drop a GIF here, or click to select one
                        </p>
                         {/* 720*90 for full width */}
                      </div>
                      {file ? (
                        <p style={{ fontSize: "14px" }}>Selected File: {file.name}</p>
                      ) : gifPreviewOldone ? (
                        <p style={{ fontSize: "14px" }}>  Selected File: {gifPreviewOldone ? gifPreviewOldone.substring(12) : ""}</p>
                      ) : (
                        <p className="text-sm leading-relaxed text-gray-900 text-center">
                          No file selected
                        </p>
                      )}
                      {gifPreview && (
                        <div className="mt-4">
                          <img src={gifPreview} alt="New GIF Preview" style={{ maxWidth: '100%' }} />
                          <IconButton
                            onClick={handleRemoveFile}
                            style={{ position: "absolute", top: 0, right: 0, backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </div>
                      )}
                      {gifPreviewOldone && !file && !gifPreview && (
                        <div className="mt-4">
                          <img src={gifPreviewOld} alt="Old GIF Preview" style={{ maxWidth: '100%' }} />
                          <IconButton
                            onClick={handleRemoveFile}
                            style={{ position: "absolute", top: 0, right: 0, backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </div>
                      )}
                      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                    </div>
                  </DialogContent>
                  <div className="flex-1 flex items-center justify-center p-8">
                    <DialogActions>{addModelAction}</DialogActions>
                  </div>
                </section>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
