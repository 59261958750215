import React, { useState } from 'react';

const ReadMore = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (text.length <= maxLength) {
        return <span style={{ wordBreak: 'break-word' }}>{text}</span>;
    }

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <span style={{ wordBreak: 'break-word' }}>
            {isExpanded ? text : `${text.substring(0, maxLength)}...`}
            <button onClick={toggleIsExpanded} style={{ marginLeft: '5px', color: 'blue', background: 'none', border: 'none', cursor: 'pointer' }}>
                {isExpanded ? "Less" : "Read more"}
            </button>
        </span>
    );
};

export default ReadMore;
