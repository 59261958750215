import React, { useEffect, useState } from "react";
import CustomInputField from "../../components/CustomInputField";
import { Controller, useForm } from "react-hook-form";
import CustomHeading from "../../components/CustomHeading";
import CustomButton from "../../components/CustomButton";
import moment from "moment";
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select, Typography, IconButton, DialogContent, DialogActions
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomDatePicker from "./../../components/CustomDatePicker";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomRadioButton from "./../../components/CustomRadioButton";
import {
  BASE_URL,
  GET_SUBSCIPTION, SEND_OTP,
  // GET_SUBSCIPTION,
  GROUP, VERIFY_OTP,
  GROUP_ADMIN, DEACTIVE_GROUP_ADMIN, ACTIVE_GROUP_ADMIN, PROMOTE_AS_PRIMARY, RESEND_EMAIL
} from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CustomCheckBox from "../../components/CustomCheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomDateEditPicker from "../../components/CustomDateEditPicker";
import CustomPhoneInput from "../../components/CustomPhoneInput";

import { ADDRESS_INVALID_MESSAGE, ADDRESS_REQUIRED_MESSAGE, COUNTRY_INVALID_MESSAGE, EMAIL_INVALID_MESSAGE, ENTITY_NUMBER_INVALID_MESSAGE, ENTITY_NUMBER_REQUIRED_MESSAGE, FORMATION_DATE_REQUIRED_MESSAGE, FREE_TRIAL_INVALID_MESSAGE, FREE_TRIAL_REQUIRED_MESSAGE, GROUP_NAME_INVALID_MESSAGE, GROUP_NAME_REQUIRED_MESSAGE, HOUSE_COUNT_INVALID_MESSAGE, HOUSE_COUNT_REQUIERD_MESSAGE, MAX_LENGTH_FIFTEEN, MAX_LENGTH_FIFTY, MAX_LENGTH_FIVE_HUNDRED, MAX_LENGTH_FOUR, MAX_LENGTH_ONE_TWENTY_MESSAGE, MAX_LENGTH_THIRTY_MESSAGE, MAX_LENGTH_TWO_FIFTY, MAX_LENGTH_TWO_MESSAGE, MIN_LENGTH_ONE, MIN_LENGTH_TEN, MIN_LENGTH_VALIDATE_MESSAGE, PERCENTANGE_INVALID_MESSAGE, PHONE_REQUIRED_MESSAGE, SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE, ZIPCODE_INVALID_MESSAGE, ZIPCODE_MAX_LENGTH_MESSAGE, ZIPCODE_MIN_LENGTH_MESSAGE, ZIPCODE_REQUIRED_MESSAGE, MIN_LENGTH_THREE_VALIDATE_MESSAGE, MAX_LENGTH_TWENTY_MESSAGE, FIRST_NAME_REQUIRED_MESSAGE, LAST_NAME_REQUIRED_MESSAGE, EMAIL_REQUIRED_MESSAGE, FIRST_NAME_INVALID_MESSAGE, LAST_NAME_INVALID_MESSAGE } from "../../utills/ApplicationConstants";
import { ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN, EMAIL_REGEX_PATTERN, NINETY_PERCENTANGE, ONE_TO_NINETY_NINE, ONLY_ALPHABETIC, ONLY_ALPHANUMBERIC, ONLY_DIGITS, ONLY_DIGITS_HYPEN, ONLY_FOUR_NUMBERS, ALPHANUMBERIC_WITH_SPECTIAL_CHARACTER, ONLY_DIGITS_WITH_HYPEN, NINETY_PERCENTANGE_PATTEN, ONLY_DIGITS_ZIPCODE, GROUP_ADMIN_EMAIL_REGEX_PATTERN, ALPHANUMBERIC_PUNCHUCATE, DIGITS_ONE_TO_FOUR_DECIMAL, EMAIL_REGEX_PATTERNS } from "../../utills/ApplicationRegex";

import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InfoOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import CustomModel from "../../components/CustomModel"
import CustomInputFieldDisabled  from "../../components/CustomInputFieldDisabled";

function UpdateSubscriber() {
  const [adminData, setAdminData] = useState('')
  const [dueType, setDueType] = useState("");
  const [groupName, setGroupName] = useState("");
  const [country, setCountry] = useState("");
  const navigate = useNavigate();
  const { groupId, groupAdminDetailsId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formationDate, setFormationDate] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [planTerm, setPlanTerm] = useState("");
  const [planType, setPlanType] = useState("");
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [virtualManager, setVirtualManager] = useState(false);
  const [decPhnNumber, setDecPhnNumber] = useState(false);
  const [domainReg, setDomainReg] = useState(false);
  const [arc, setARC] = useState(false);
  const [pricing, setPricing] = useState("");
  const [specialDiscountDisabled, setSpecialDiscountDisabled] = useState(true);
  const [discount, setDiscount] = useState(10);
  const [houseCount, setHouseCount] = useState("");
  const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState("");
  const [planTypeFrPrice, setPlanTypeFrPrice] = useState("");
  const [isFieldEnabled, setIsFieldEnabled] = useState(false);
  const [specialDiscount, setSpecialDiscount] = useState('');
  const [trialPeriod, setTrialPeriod] = useState('');
  const [specialDiscountError, setSpecialDiscountError] = useState('');
  const [ein, setEin] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [publishKey, setPublishKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [accountId, setAccountId] = useState('');
  const [purchaseMail, setPurchaseMail] = useState('');
  const [salesMail, setSalesMail] = useState('');
  const [suppliersMail, setSuppliersMail] = useState('');
  const [expensesMail, setExpensesMail] = useState('');
  const [discTypeShow, setDiscTypeShow] = useState(false);
  const [refTotal, setRefTotal] = useState("");
  const [remarks, setRemarks] = useState("");
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );
  const [discountType, setDiscountType] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const [otpOpen, setOtpOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(0);
  const [showResend, setShowResend] = useState(false);

  const [additionalPrices, setAdditionalPrices] = useState({
    virtualManager: 0,
    dedicatedPhone: 0,
    domainRegistration: 0,
    webHosting: 0,
    virtualarc: 0,
  });

  //basic
  const [basic, setBasic] = useState(true);
  const [domain, setDomain] = useState(false);
  const [dedicated, setDedicated] = useState(false);
  const [arcs, setArcs] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [webHosting, setWebHosting] = useState(false);
  //extended
  const [extended, setExtended] = useState(true);
  const [itServices, setItServices] = useState(true);
  const [accounting, setAccounting] = useState(true);
  const [domainExt, setDomainExt] = useState(false);
  const [dedicatedExt, setDedicatedExt] = useState(false);
  const [arcsExt, setArcsExt] = useState(false);
  const [virtualExt, setVirtualExt] = useState(false);
  const [webHostingExt, setWebHostingExt] = useState(false);

  //premium
  const [premium, setPremium] = useState(true)
  const [adminFields, setAdminFields] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [deactiveConfirmOpen, setDeactiveConfirmOpen] = useState(false);
  const [activeConfirmOpen, setActiveConfirmOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowActive, setSelectedRowActive] = useState(null);
  const [deactivePrimaryConfirmOpen, setDeactivePrimaryConfirmOpen] =
    useState(false);
  const [promoteOpen, setPromoteOpen] = useState(false);
  const [selectedRowPromote, setSelectedRowPromote] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedRowDelete, setSelectedRowDelete] = useState(null);
  const [deletePrimaryOpen, setDeletePrimaryOpen] = useState(false);
  const [resendOpen, setResendOpen] = useState(false);
  const [resendFailOpen, setResendFailOpen] = useState(false);
  const [selectedRowResend, setSelectedRowResend] = useState(null);

const [clearSecretKeyConfirmOpen, setClearSecretKeyConfirmOpen] = useState(false);
const [clearPublishKeyConfirmOpen, setClearPublishKeyConfirmOpen] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors }, clearErrors, setValue,
  } = useForm();

  // useEffect(() => {
  //   // Reset checkbox states when planType changes
  //   setBasic(true);
  //   setDomain(false);
  //   setDedicated(false);
  //   setArcs(false);
  //   setVirtual(false);
  //   setWebHosting(false);

  //   setExtended(true);
  //   setItServices(true);
  //   setAccounting(true);
  //   setDomainExt(false);
  //   setDedicatedExt(false);
  //   setArcsExt(false);
  //   setVirtualExt(false);
  //   setWebHostingExt(false);

  //   setPremium(true);
  // }, [planType]);

  useEffect(() => {
    // console.log(type)
  }, [type])
  useEffect(() => {
    let intervalId;
    if (otpOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowResend(true);
    }
    return () => clearInterval(intervalId);
  }, [otpOpen, timer]);
  const getAdditionalPrice = async (fieldName) => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${GET_SUBSCIPTION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        const priceObject = data.find((item) => item.type === planType);
        // console.log("priceObject", priceObject ? parseFloat(priceObject[fieldName]) : 0);
        return priceObject ? parseFloat(priceObject[fieldName]) : 0;
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return 0; // Return a default value in case of an error
    }
  };

  const fetchAdditionalPrice = async (fieldName, checkboxState) => {
    const additionalPrice = await getAdditionalPrice(fieldName);

    // Check if the checkbox is already checked
    const isCheckboxChecked = checkboxState; // Assuming checkboxState is a boolean

    // Add additional price only if checkbox is checked
    if (isCheckboxChecked) {
      setAdditionalPrices((prevPrices) => ({
        ...prevPrices,
        [fieldName]: additionalPrice,
      }));
    } else {
      // If checkbox is unchecked, set the additional price to 0
      setAdditionalPrices((prevPrices) => ({
        ...prevPrices,
        [fieldName]: 0,
      }));
    }

    // Use the additionalPrice as needed
    // console.log("additionalPrice", additionalPrice);
  };
  const clearSecretKey = () => {
    console.log("inside secret key");
    setSecretKey("");
    clearErrors();
  };
  const clearPublishKey = () => {
    console.log("inside publish key"); 
    setPublishKey('');
    clearErrors();

  };
  // <--------------------------------------------Clear secret key ----------------------------->
  
  const handleClearSecretKeyClick = (adminId) => {
    setClearSecretKeyConfirmOpen(true);
  };

  const handleClearSecretKeyConfirmClose = () => {
    setClearSecretKeyConfirmOpen(false);
  };
  const handleClearSecretKeyConfirmIconClose = () => {
    setClearSecretKeyConfirmOpen(false);
  };
  // const renderTooltip = (message) => (
  //   <Tooltip
  //     title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
  //     placement="right"
  //   >
  //     <IconButton>
  //       <InfoOutlined />
  //     </IconButton>
  //   </Tooltip>
  // );

  const ClearSecretKeyConfirmActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleClearSecretKeyConfirmClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleClearSecretKeyConfirmClose}
      />

    </>
  );

  const ClearSecretKeyConfirmModel = (
    <form
      onSubmit={clearSecretKey}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you sure to Clear the secret key
        </Typography>
      </DialogContent>
      <DialogActions>{ClearSecretKeyConfirmActions}</DialogActions>
    </form>
  );


  // <--------------------------------------------Clear secret key modal end ----------------------------->


   // <--------------------------------------------Clear publish key start ----------------------------->
  
   const handleClearPublishKeyClick = (adminId) => {
    setClearPublishKeyConfirmOpen(true);
  };

  const handleClearPublishKeyConfirmClose = () => {
    setClearPublishKeyConfirmOpen(false);
  };
  const handleClearPublishKeyConfirmIconClose = () => {
    setClearPublishKeyConfirmOpen(false);
  };
  // const renderTooltip = (message) => (
  //   <Tooltip
  //     title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
  //     placement="right"
  //   >
  //     <IconButton>
  //       <InfoOutlined />
  //     </IconButton>
  //   </Tooltip>
  // );

  const ClearPublishKeyConfirmActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleClearPublishKeyConfirmClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleClearPublishKeyConfirmClose}
      />

    </>
  );

  const ClearPublishKeyConfirmModel = (
    <form
      onSubmit={clearPublishKey}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you sure to Clear the Publish key
        </Typography>
      </DialogContent>
      <DialogActions>{ClearPublishKeyConfirmActions}</DialogActions>
    </form>
  );


  // <--------------------------------------------Clear Publish key modal end----------------------------->


  
  // <--------------------------------------------Deactivate modal Start ----------------------------->
  const onSubmitDeactiveConfirm = (adminId) => {
    setIsLoading(true)
    request({ url: `${DEACTIVE_GROUP_ADMIN}/${selectedRow}`, method: "get" })
      .then((res) => {
        // console.log(res);
        getGroupAdminDetailsById();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };
  const handleDeactiveClick = (adminId) => {
    setDeactiveConfirmOpen(true);
    setSelectedRow(adminId);
  };

  const handledeactiveConfirmClose = () => {
    setDeactiveConfirmOpen(false);
  };
  const handledeactiveConfirmIconClose = () => {
    setDeactiveConfirmOpen(false);
  };
  const renderTooltip = (message) => (
    <Tooltip
      title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
      placement="right"
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );

  const deactiveConfirmActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handledeactiveConfirmClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handledeactiveConfirmClose}
      />

    </>
  );

  const deactiveConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitDeactiveConfirm)}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you confirm to deactivate
        </Typography>
      </DialogContent>
      <DialogActions>{deactiveConfirmActions}</DialogActions>
    </form>
  );


  // <--------------------------------------------Deactivate modal end ----------------------------->

  // <--------------------------------------------Activate modal Start ----------------------------->

  const handleActivateClick = (adminId) => {
    setActiveConfirmOpen(true);
    setSelectedRowActive(adminId);

  };
  const handleActiveConfirmClose = () => {
    setActiveConfirmOpen(false);
  };

  const activeConfirmActions = (
    <>

      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleActiveConfirmClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleActiveConfirmClose}
      />
    </>
  );

  const onSubmitActiveConfirm = (adminId) => {
    setIsLoading(true)

    request({ url: `${ACTIVE_GROUP_ADMIN}/${selectedRowActive}`, method: "get" })
      .then((res) => {
        setIsLoading(false)
        // console.log(res);
        getGroupAdminDetailsById();
      })
      .catch((error) => {
        setIsLoading(false)

        console.log(error);
      });
  };
  const activeConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitActiveConfirm)}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you confirm to activate
        </Typography>
      </DialogContent>
      <DialogActions>{activeConfirmActions}</DialogActions>
    </form>
  );
  // <--------------------------------------------Activate modal end ----------------------------->
  // <-------------------------------------Deactivate warning modal Start ----------------------------->

  const handledeactivePrimaryConfirmClose = () => {
    setDeactivePrimaryConfirmOpen(false);
  };
  const deactivePrimaryConfirmActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handledeactivePrimaryConfirmClose}
      />
    </>
  );
  const deactivePrimaryConfirmModel = (
    <>
      <DialogContent dividers>
        <Typography>
          To deactivate the primary group admin, please assign another group admin as the primary admin.
        </Typography>
      </DialogContent>
      <DialogActions>{deactivePrimaryConfirmActions}</DialogActions>
    </>
  );
  const handlePrimaryDeactiveClick = (adminId) => {
    setDeactivePrimaryConfirmOpen(true);
    setSelectedRow(adminId);
    // console.log(selectedRow.firstName);
  };

  // <-------------------------------------Deactivate warning modal end ----------------------------->
  // <-------------------------------------Promote modal Start ----------------------------->

  const handlePrimaryOpen = (adminId, email) => {
    setPromoteOpen(true);
    setSelectedRowPromote(adminId);
  };
  const handlePromoteClose = () => {
    setPromoteOpen(false);
  };
  const handlePromoteIconClose = () => {
    setPromoteOpen(false);
  };

  const promoteActions = (
    <>

      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handlePromoteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handlePromoteClose}
      />
    </>
  );
  const onSubmitPromote = (adminId) => {

    const postData = {
      email: adminEmail,
    };
    // console.log(data);
    setIsLoading(true);
    request({
      url: `${SEND_OTP}/${selectedRowPromote}`,
      method: "post",
      data: postData,
    })
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          setOtpOpen(true);
          setTimer(30);
          setShowResend(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };


  const promoteConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitPromote)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Otp will send to {adminEmail}.
        </Typography>
      </DialogContent>
      <DialogActions>{promoteActions}</DialogActions>
    </form>
  );

  // <-------------------------------------Promote modal End ----------------------------->


  const onSubmitOtp = (adminId) => {
    if (otp.trim() === '' || otp === null) {
      setError("otp", {
        type: "manual",
        message: "Please enter OTP",
      });
      return;
    }
    const postData = {
      otp: otp,
    };
    // console.log(data);
    setIsLoading(true);
    request({
      url: `${VERIFY_OTP}/${selectedRowPromote}`,
      method: "post",
      data: postData,
    })
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          console.log("Success for promote");
          const promotePostData = {
            groupId: groupId,
          };
          request({
            url: `${PROMOTE_AS_PRIMARY}/${selectedRowPromote}`,
            method: "post",
            data: promotePostData,
          })
            .then((res) => {
              // console.log(res);
              if (res.status == 200) {
                console.log("Success for promote");
                setOtpOpen(false);

                setIsLoading(false);
                getGroupAdminDetailsById();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(error.response);
              if (error.response) {
                setIsLoading(false);
                console.log("error.response.status" + error.response.status);
              }
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  // const onSubmitOtp = (adminId) => {
  //   if (otp.trim() == '' || otp == null) {
  //     setError("otp", {
  //       type: "manual",
  //       message: "Please enter OTP",
  //     });
  //     return;
  //   }
  //   const postData = {
  //     groupId: groupId,
  //     otp:otp,
  //   };
  //   // console.log(data);
  //   setIsLoading(true);
  //   request({
  //     url: `${PROMOTE_AS_PRIMARY}/${selectedRowPromote}`,
  //     method: "post",
  //     data: postData,
  //   })
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.status == 200) {
  //         console.log("Success for promote");
  //         setOtpOpen(false);
  //         setIsLoading(false);
  //         getGroupAdminDetailsById();
  //       }
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error.response);
  //       if (error.response) {
  //         setIsLoading(false);
  //         console.log("error.response.status" + error.response.status);
  //       }
  //     });
  // };


  const handleOtpOpen = () => {
    setOtpOpen(true);
  };

  const handleOtpClose = () => {
    setOtpOpen(false);
  };


  const otpActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleOtpClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Verify"
      />
    </>
  );



  const handleResend = () => {
    setTimer(30);
    setShowResend(false);

    const resendPostData = {
      email: adminEmail,
    };
    request({
      url: `${SEND_OTP}/${selectedRowPromote}`,
      method: "post",
      data: resendPostData,
    })
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  const otpConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitOtp)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
        </Typography>
        <CustomInputField
          fieldName="otp"
          fieldId="otp"
          fieldType="text"
          fieldLabel=" Enter OTP to Promote Secondary Group Admin."
          fieldControl={control}
          fieldError={errors}
          fieldRequiredIcon="*"
          onInput={(event) => { setOtp(event.target.value) }}
        />
        <Typography>
          {showResend ? (
            <button type="button" onClick={handleResend}>Resend</button>
          ) : (
            `Resend OTP in ${timer} seconds`
          )}
        </Typography>
      </DialogContent>
      <DialogActions>{otpActions}</DialogActions>
    </form>
  );

  // <-------------------------------------Delete modal Start ----------------------------->



  const handleDeleteOpen = (adminId) => {
    setDeleteOpen(true);
    setSelectedRowDelete(adminId);

  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);

  };
  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />

    </>
  );
  const handleRemoveClick = (index) => {
    setAdminFields((prevAdminFields) => {
      const updatedAdminFields = prevAdminFields.filter((_, i) => i !== index);
      clearErrors(`firstName-${index}`);
      clearErrors(`lastName-${index}`);
      clearErrors(`email-${index}`);
      clearErrors(`contact-${index}`);
      return updatedAdminFields;
    });
  };


  const onSubmitDelete = (adminId) => {
    setIsLoading(true);
    // return;
    request({ url: `${GROUP_ADMIN}/${selectedRowDelete}`, method: "delete" })
      .then((res) => {
        // console.log(res);
        setIsLoading(false);

        getAllAdminDetails();

        handleRemoveClick();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          Are you sure you want to delete
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  // <-------------------------------------Delete modal End ----------------------------->
  // <-------------------------------------Primary Warning modal Start ----------------------------->

  const handlePrimaryDeleteOpen = (selectedRow) => {
    setDeletePrimaryOpen(true);
    setSelectedRow(selectedRow);
    // console.log(selectedRow);
    // setSelectedRowFirstName(selectedRow.firstName);
    // setSelectedRowLastName(selectedRow.lastName);
  };

  const handleDeletePrimaryClose = () => {
    setDeletePrimaryOpen(false);
  };
  const okCancelPrimaryButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleDeletePrimaryClose}
      />
    </>
  );
  const deletePrimaryModel = (
    <>
      <DialogContent dividers>
        <Typography>
          To delete the primary group admin, please assign another group admin as the primary admin.
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelPrimaryButtonActions}</DialogActions>
    </>
  );

  // <-------------------------------------Resend modal Start ----------------------------->

  const handleResendOpen = (adminId) => {
    // console.log("selectedRow",selectedRow);
    setResendOpen(true)
    setSelectedRowResend(adminId);

  };

  const handleResendClose = () => {
    setResendOpen(false);
  };
  const handleResendIconClose = () => {
    setResendOpen(false);
  };

  const ResendActions = (
    <>

      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleResendClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleResendClose}
      />
    </>
  );

  const onResendSubmit = async (adminId) => {
    setIsLoading(true)
    request({ url: `${RESEND_EMAIL}/${selectedRowResend}`, method: "get" })
      .then((res) => {
        // console.log(res);
        setIsLoading(false)
        getAllAdminDetails();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };
  const resendModel = (
    <form
      onSubmit={handleSubmit(onResendSubmit)}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          Are you sure want to Resend Email ?
        </Typography>
      </DialogContent>
      <DialogActions>{ResendActions}</DialogActions>
    </form>
  );



  // <-------------------------------------Resend modal End ----------------------------->
  // <-------------------------------------Resend  Warning modal Start ----------------------------->



  const handleResendFailOpen = (adminId) => {

    setResendFailOpen(true)
  };
  const handleResendFailClose = () => {
    setResendFailOpen(false);
  };
  const handleResendFailIconClose = () => {
    setResendFailOpen(false);
  };
  const ResendFailActions = (
    <>

      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleResendFailClose}
      />

    </>
  );
  const resendFailModel = (
    <>
      <DialogContent dividers>
        <Typography>
          Your profile has already verified
        </Typography>
      </DialogContent>
      <DialogActions>{ResendFailActions}</DialogActions>
    </>


  );

  // <-------------------------------------Resend  Warning modal End ----------------------------->

  useEffect(() => {

    const fetchAdditionalPrices = async () => {
      await fetchAdditionalPrice("virtualManager", virtualExt);
      await fetchAdditionalPrice("dedicatedPhone", dedicatedExt);
      await fetchAdditionalPrice("domainRegistration", domainExt);
      await fetchAdditionalPrice("webHosting", webHostingExt);
      await fetchAdditionalPrice("virtualarc", arcsExt);

    };

    fetchAdditionalPrices();
  }, [planType, virtualExt, dedicatedExt, domainExt, webHostingExt, arcsExt])

  // useEffect(() => {
  //   const fetchAdditionalPrice = async () => {
  //     const additionalPrice = await getAdditionalPrice("virtualManager");

  //     // Check if the checkbox is already checked
  //     const isCheckboxChecked = virtual; // Update with the correct state variable

  //     // Add additional price only if checkbox is checked
  //     if (isCheckboxChecked) {
  //       setAdditionalPrices((prevPrices) => ({
  //         ...prevPrices,
  //         virtualManager: additionalPrice,
  //       }));
  //     }

  //     // Use the additionalPrice as needed
  //     console.log("additionalPrice", additionalPrice);
  //   };

  //   fetchAdditionalPrice();
  // }, [planType]);

  const handleCheckboxChange = async (fieldName) => {
    const price = await getAdditionalPrice(fieldName);

    // console.log('Updating state for', fieldName, 'with price', price);

    setAdditionalPrices((prevPrices) => ({
      ...prevPrices,
      [fieldName]: !prevPrices[fieldName] ? price : 0,
    }));
  };


  const handleBasicChange = () => {
    setBasic((prevChecked) => !prevChecked);
  }

  const handleVirtualChange = () => {
    setVirtual((prevChecked) => !prevChecked);
  }

  const handleDedicatedChange = () => {
    setDedicated((prevChecked) => !prevChecked);
  }

  const handleArcChange = () => {
    setArcs((prevChecked) => !prevChecked);
  }

  const handleDomainChange = () => {
    setDomain((prevChecked) => !prevChecked);
  }

  const handleWebHosting = () => {
    setWebHosting((prevChecked) => !prevChecked);
  }

  const handleExtendedChange = () => {
    setExtended((prevChecked) => !prevChecked);
  }

  const handleItservices = () => {
    setItServices((prevChecked) => !prevChecked);
  }

  const handleAccountingChange = () => {
    setAccounting((prevChecked) => !prevChecked);
  }

  const handleVirtualChangeExt = () => {
    setVirtualExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  }

  const handleDedicatedChangeExt = () => {
    setDedicatedExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  }

  const handleArcChangeExt = () => {
    setArcsExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  }

  const handleDomainChangeExt = () => {
    setDomainExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  }

  const handleWebHostingExt = () => {
    setWebHostingExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  }

  const handlePremiumChange = () => {
    setPremium((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  }

  const handleClick = () => {
    navigate("/subscribers");
  };

  const formatEin = (value) => {
    // Remove any existing hyphens
    const cleanedValue = value.replace(/-/g, '');

    // Add hyphens for every three numbers
    const formattedValue = cleanedValue.replace(/(\d{3})(?=\d)/g, '$1-');

    return formattedValue;
  };

  console.log(errors);
  

  const getGroupAdminDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token

      // console.log(storedToken);
      // {isLoading && <CustomLoading />}
      const response = await axios.get(`${BASE_URL}${GROUP}/${groupId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      // console.log(response.data);

      if (response.status === 200) {
        setIsLoading(false)
        const data = response.data;
        setAdminData(data);
        setEin(data.groupDetails?.ein ?? "")
        setCountry(data.groupDetails?.country ?? "")
        setIsLoading(false);
        setGroupName(data.group.groupName);
        setFormationDate(data.groupDetails.formationDate);
        setAddress(data.groupDetails?.address);
        setZipcode(data.groupDetails.zipcode)
        setValue("phoneNumber", data.groupDetails.contact)
        setHouseCount(data.groupSubscriptionDetails?.houseCount ?? "")
        setPlanTerm(data.groupSubscriptionDetails?.planTerm ?? "")
        const SubscriptionStart = data?.groupSubscriptionDetails?.startDate ?? "";
const dayjsDate = dayjs(SubscriptionStart);

const formattedDate = dayjsDate.isValid() ? dayjsDate : null; // Return Day.js object or null
setSubscriptionStartDate(formattedDate);

const endDate = data?.groupSubscriptionDetails?.endDate ?? "";
const dayjsDateTwo = dayjs(endDate);

const formattedDateTwo = dayjsDateTwo.isValid() ? dayjsDateTwo : null; // Return Day.js object or null
setSubscriptionEndDate(formattedDateTwo);
        
        setPlanType(data.groupSubscriptionDetails?.planType ?? "")
        setDiscount(data.groupSubscriptionDetails?.discount ?? "")
        setSpecialDiscount(data.groupSubscriptionDetails?.specialDiscount ?? "")
        setType(data.groupSubscriptionDetails?.trialPeriod ?? "")
        setDiscountType(data.groupSubscriptionDetails?.discountType ?? "");
        setPublishKey(data.groupPaymentConfig?.publishKey ?? "")
        setSecretKey(data.groupPaymentConfig?.secretKey ?? "")
        setAccountId(data.groupPaymentConfig?.accountId ?? "")
        setPurchaseMail(data.groupAccountsConfig?.purchaseMail ?? "")
        setSalesMail(data.groupAccountsConfig?.salesMail ?? "")
        setSuppliersMail(data.groupAccountsConfig?.supplierMail ?? "")
        setExpensesMail(data.groupAccountsConfig?.expenseMail ?? "")
        setVirtualExt(data.groupSubscriptionDetails?.virtualManager ?? "")
        setDedicatedExt(data.groupSubscriptionDetails?.dedicatedPhone ?? "")
        setArcsExt(data.groupSubscriptionDetails?.virtualArc ?? "")
        setDomainExt(data.groupSubscriptionDetails?.domainRegistration ?? "")
        setWebHostingExt(data.groupSubscriptionDetails?.webHosting ?? "")
        setRemarks(data.groupSubscriptionDetails?.remarks ?? "")

        // console.log(data.groupAdminData, "before if ");


        if (data.groupAdminData) {
          // console.log(data.groupAdminData, "after if ");
          const adminData = data.groupAdminData.map(admin => ({
            firstName: admin.firstName,
            lastName: admin.lastName,
            email: admin.email,
            isActive: admin.isActive,
            isPrimary: admin.isPrimary,
            emailVerified: admin.emailVerified,
            adminId: admin.adminId,
            contact: admin.contact,

          }));
          setAdminFields(adminData);
          setShowInputs(true);
          const primaryAdmin = adminData.find(admin => admin.isPrimary);
          if (primaryAdmin) {
            setAdminEmail(primaryAdmin.email);
          }
        } else {
          setShowInputs(false);
        }
      } else {
        setIsLoading(false)

        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      setIsLoading(false)

      // Handle error
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getGroupAdminDetailsById();
  }, [])

 

  const handleAddClick = () => {
    setAdminFields([...adminFields, { adminId: '', firstName: '', lastName: '', email: '', contact: '', isActive: false, isPrimary: false, emailVerified: false }]);
    setShowInputs(true);
  };

  const handleAdminFieldChange = (index, field, value) => {
    const updatedAdminFields = [...adminFields];
    updatedAdminFields[index][field] = value;
    setAdminFields(updatedAdminFields);
  };







  const onSubmit = (data) => {
    let hasError = false;
    if (!groupName) {
      setError("groupName", {
        type: "manual",
        message: GROUP_NAME_REQUIRED_MESSAGE,
      });
      return;
    }
    if (groupName.trim().length < 2) {
      setError("groupName", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });

      return;
    }
    if (groupName.trim().length > 250) {
      setError("groupName", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });

      return;
    }
    if (!ein) {
      setError("entityNumber", {
        type: "manual",
        message: ENTITY_NUMBER_REQUIRED_MESSAGE,
      });
      return;
    }
    if (ein.trim().length < 3) {
      setError("entityNumber", {
        type: "manual",
        message: MIN_LENGTH_THREE_VALIDATE_MESSAGE,
      });

      return;
    }
    if (ein.trim().length > 20) {
      setError("entityNumber", {
        type: "manual",
        message: MAX_LENGTH_TWENTY_MESSAGE,
      });

      return;
    }
    if (!address) {
      setError("address", {
        type: "manual",
        message: ADDRESS_REQUIRED_MESSAGE,
      });
      return;
    }
    if (address.trim().length < 2) {
      setError("address", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });

      return;
    }
    if (address.trim().length > 250) {
      setError("address", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });

      return;
    }
    if (country) {
      if (country.trim().length < 2) {
        setError("country", {
          type: "manual",
          message: MIN_LENGTH_VALIDATE_MESSAGE,
        });

        return;
      }
    }

    if (country) {
      if (country.trim().length > 50) {
        setError("address", {
          type: "manual",
          message: MAX_LENGTH_FIFTY,
        });

        return;
      }
    }
    // Validate adminFields
    adminFields.forEach((admin, index) => {
      if (!admin.firstName) {
        setError(`firstName-${index}`, {
          type: "manual",
          message: "First Name is required",
        });
        hasError = true;
      }
      if (!admin.lastName) {
        setError(`lastName-${index}`, {
          type: "manual",
          message: "Last Name is required",
        });
        hasError = true;
      }
      if (!admin.email) {
        setError(`email-${index}`, {
          type: "manual",
          message: "Email is required",
        });
        hasError = true;
      }
      if (!admin.contact) {
        setError(`contact-${index}`, {
          type: "manual",
          message: "Contact is required",
        });
        hasError = true;
      }
    });
    
    if (hasError) {
      return;
    }
    if (!zipcode) {
      setError("zipcode", {
        type: "manual",
        message: ZIPCODE_REQUIRED_MESSAGE,
      });
      return;
    }
    if (zipcode.trim().length < 5) {
      setError("zipcode", {
        type: "manual",
        message: ZIPCODE_MIN_LENGTH_MESSAGE,
      });

      return;
    }
    if (zipcode.trim().length > 5) {
      setError("zipcode", {
        type: "manual",
        message: ZIPCODE_MIN_LENGTH_MESSAGE,
      });

      return;
    }
    if (!houseCount) {
      setError("houseCount", {
        type: "manual",
        message: HOUSE_COUNT_REQUIERD_MESSAGE,
      });
      return;
    }

    if (houseCount.trim().length < 1) {
      setError("houseCount", {
        type: "manual",
        message: MIN_LENGTH_ONE,
      });

      return;
    }
    if (houseCount.trim().length > 4) {
      setError("houseCount", {
        type: "manual",
        message: MAX_LENGTH_FOUR,
      });

      return;
    }
    if (!formationDate) {
      setError("formationDate", {
        type: "manual",
        message: FORMATION_DATE_REQUIRED_MESSAGE,
      });
      return;
    }
    if (!planType) {
      setError("planType", {
        type: "manual",
        message: SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE,
      });
      return;
    }
    if (!type) {
      setError("freeTrial", {
        type: "manual",
        message: FREE_TRIAL_REQUIRED_MESSAGE,
      });
      return;
    }
    if (discountType === "DOLLAR" || discountType === "PERCENTAGE") {
      if (specialDiscount === "") {
        setError("specialDiscount", {
          type: "manual",
          message: "Please provide Special Discount",
        });
        return;
      }
    }
    console.log(publishKey);
    
    if (publishKey) {
      if (publishKey.trim().length < 2) {
        setError("publishkey", {
          type: "manual",
          message: MIN_LENGTH_VALIDATE_MESSAGE,
        });

        return;
      }
    }
    if (secretKey) {
      console.log("inside secret key");
      
      if (secretKey.trim().length < 2) {
        setError("secretKey", {
          type: "manual",
          message: MIN_LENGTH_VALIDATE_MESSAGE,
        });

        return;
      }
    }
    if (accountId) {
      if (accountId.trim().length < 2) {
        setError("accountId", {
          type: "manual",
          message: MIN_LENGTH_VALIDATE_MESSAGE,
        });

        return;
      }
    }
    if (accountId) {
      if (accountId.trim().length > 30) {
        setError("accountId", {
          type: "manual",
          message: MAX_LENGTH_THIRTY_MESSAGE,
        });

        return;
      }
    }
    if (discountType === "DOLLAR" || discountType === "PERCENTAGE") {
      if (specialDiscount === "") {
        setError("specialDiscount", {
          type: "manual",
          message: "Please provide Special Discount",
        });
        return;
      }
    }
    if (specialDiscountError != "") {
      return;
    }
    if (specialDiscount != "") {
      // console.log("inside special");
      if (!remarks) {
        // console.log("inside special 222" );

        setError("remarks", {
          type: "manual",
          message: "Remarks is required",
        });
        return;
      }
    }


    setIsLoading(true)

    const postData = {
      groupName: groupName,
      ein: ein,
      formationDate: dayjs(formationDate).format("YYYY-MM-DD"),
      address: address,
      country: country,
      zipCode: zipcode,
      houseCount: houseCount,
      discount: discount,
      contact: data.phoneNumber,
      firstName: data.groupAdminFirstName,
      lastName: data.groupAdminLastName,
      email: data.groupAdminEmail,
      freeTrial: type,
      planTerm: planTerm,
      planType: planType,

      // additionalPricePerUnit: additionalPrices,
      price: parseFloat(totalSubscriptionAmount),
      // pricePayable: parseFloat(totalSubscriptionAmount),
      discountType: discountType,
      specialDiscount: specialDiscount,
      startDate: subscriptionStartDate,
      endDate: subscriptionEndDate,
      publishKey: publishKey,
      secretKey: secretKey,
      accountId: accountId,
      purchaseMail: purchaseMail,
      salesMail: salesMail,
      expensesMail: expensesMail,
      suppliersMail: suppliersMail,
      basic: basic,
      virtualManager: virtual || virtualExt,
      domainRegistration: domain || domainExt,
      dedicatedPhone: dedicated || dedicatedExt,
      webHosting: webHosting || webHostingExt,
      virtualArc: arcs || arcsExt,
      itServices: itServices,
      accounting: accounting,
      premium: premium,
      extended: extended,
      remarks: remarks,
      admins: adminFields,
    };
    // console.log(postData);
    // if (discountType === 'dollar' && parseFloat(specialDiscount) > parseFloat(totalSubscriptionAmount)){
    //   setError("specialDiscount", {
    //     type: "manual",
    //     // message: CURRENT_AND_NEW_PASSWORD_CHECKING,
    //   });
    //   return;
    // }
    // return;
    // setIsLoading(true);
    // { isLoading && <CustomLoading /> }
    request({ url: `${GROUP}/${groupId}`, method: "put", data: postData })
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          // console.log("Success");
          setTimeout(() => {
            navigate("/subscribers");
          }, 4000);
          setIsLoading(false);
          // { isLoading && <CustomLoading /> }
        } else if (res.status != 200) {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }

      });
  };

  const handleDaysInputChange = (event) => {
    const inputDays = event.target.value;
    setType(inputDays);


  };


  // useEffect(() => {
  //   // Update subscription end date based on entered days
  //   if (planTerm === "1 YEAR" && type !== 0 && type !== "") {
  //     console.log("workinggg");
  //     if (/^\d+$/.test(type)) {
  //       const newEndDate = dayjs()
  //         .add(1, "year")
  //         .add(parseInt(type, 10), "days")
  //         .subtract(1, "day");
  //       setSubscriptionEndDate(newEndDate);
  //     }
  //   } else {
  //     const defaultEndDate = dayjs().add(1, "year").subtract(1, "day");
  //     setSubscriptionEndDate(defaultEndDate);
  //   }
  // }, [type, planTerm]);
  // useEffect(() => {
  //   // Update subscription end date based on entered days
  //   if (planTerm === "1 YEAR" && type !== 0 && type !== "") {

  //     if (/^\d+$/.test(type)) {
  //       const newStartDate = dayjs()
  //         .add(parseInt(type, 10), "days");
  //       setSubscriptionStartDate(newStartDate);
  //     }
  //   } else {
  //     setSubscriptionStartDate(dayjs());
  //   }
  // }, [type, planType]);




  const handleHouseCountChange = (e) => {
    setHouseCount(e.target.value);
    setSpecialDiscount("");
    setSpecialDiscountError("");
    calculateTotalSubscription();

  };
  const handlePlanTypeChange = (e, field) => {
    setPlanType(e.target.value); // Update state with the selected plan type
    field.onChange(e.target.value); // Trigger the Controller's onChange method
    setSpecialDiscount("");
    setArcsExt(false);
    setARC(false);
    setWebHostingExt(false)
    setDomainExt(false)
    setDedicatedExt(false)
    setVirtualExt(false)
    calculateTotalSubscription()
    // Reset special discount if needed
  };
  const calculateDiscount = (price) => {
    const discountAmount = price * 0.1;
    const discountedPrice = price - discountAmount;
    return discountedPrice;
  };




  const calculateTotalSubscription = () => {
    if (houseCount !== "" && discount !== "") {
      console.log(houseCount, "house count");
      console.log(discount, "discount");
      console.log(additionalPrices);
      const totalAdditionalPrice = Object.values(additionalPrices).reduce(
        (acc, price) => acc + price,
        0
      );

      console.log(totalAdditionalPrice, "total additional price");
      // console.log("totalAdditionalPrice", totalAdditionalPrice)
      const basePrice = parseFloat(planTypeFrPrice);
      console.log(basePrice, "base price");
      const total = (parseInt(houseCount) * parseFloat(discount));
      console.log(total, "total");
      // Apply special discount if applicable
      const specialDiscountAmount = specialDiscount || 0;
      console.log(specialDiscountAmount, "Special discount amount");
      console.log(specialDiscount, "special discount");


      if (discountType == "PERCENTAGE") {
        const specialDiscountPercentage = (parseFloat(total) / 100) * specialDiscount;
        console.log(specialDiscountPercentage, "specail discount percentage");


        if (totalAdditionalPrice) {
          console.log("inside if ");
          console.log(total, "-", specialDiscountPercentage);

          const discountedTotal = total - specialDiscountPercentage;
          let totalValueAfterAddon = discountedTotal;

          if (virtualExt) {
            console.log("virtual", additionalPrices.virtualManager);
            totalValueAfterAddon += additionalPrices.virtualManager;
          }
          if (dedicatedExt) {
            console.log("dedicated extended", additionalPrices.dedicatedPhone);
            totalValueAfterAddon += additionalPrices.dedicatedPhone;
          }
          if (webHostingExt) {
            console.log("web hosting", additionalPrices.webHosting);
            totalValueAfterAddon += additionalPrices.webHosting;
          }
          if (arcsExt) {
            console.log("arc", additionalPrices.virtualarc);
            totalValueAfterAddon += additionalPrices.virtualarc;
          }
          // const totalValueAfterAddon = discountedTotal + totalAdditionalPrice
          console.log(discountedTotal, "discount total");
          console.log(totalValueAfterAddon, "total value after addon features");
          setTotalSubscriptionAmount(totalValueAfterAddon.toFixed(2));
          setRefTotal(total.toFixed(2));
          setDiscTypeShow(true);
        } else {
          console.log("inside else");
          // setTotalSubscriptionAmount("")
          console.log(total, "total");
          const discountedTotal = total - specialDiscountPercentage;
          console.log(total, "-", specialDiscountPercentage);
          console.log(discountedTotal, "discount total");
          // console.log(totalValueAfterAddon, "total value after addon features");
          setTotalSubscriptionAmount(discountedTotal.toFixed(2));
          setRefTotal(total.toFixed(2));
          setDiscTypeShow(true);
        }
      } else {

        console.log(specialDiscount, "special discount");
        console.log(specialDiscountAmount, "special discount amount");

        if (totalAdditionalPrice) {
          console.log("inside if ");
          console.log(total, "-", specialDiscountAmount);

          const discountedTotal = total - specialDiscountAmount;
          let totalValueAfterAddon = discountedTotal;
          // if(virtualExt){
          //     totalValueAfterAddon+=totalAdditionalPrice
          // }
          if (virtualExt) {
            console.log("virtual", additionalPrices.virtualManager);
            totalValueAfterAddon += additionalPrices.virtualManager;
          }
          if (dedicatedExt) {
            console.log("dedicated extended", additionalPrices.dedicatedPhone);
            totalValueAfterAddon += additionalPrices.dedicatedPhone;
          }
          if (webHostingExt) {
            console.log("web hosting", additionalPrices.webHosting);
            totalValueAfterAddon += additionalPrices.webHosting;
          }
          if (arcsExt) {
            console.log("arc", additionalPrices.virtualarc);
            totalValueAfterAddon += additionalPrices.virtualarc;
          }
          // const totalValueAfterAddon = discountedTotal + totalAdditionalPrice
          console.log(discountedTotal, "discount total");
          console.log(totalValueAfterAddon, "total value after addon features");
          setTotalSubscriptionAmount(totalValueAfterAddon.toFixed(2));
          setRefTotal(total.toFixed(2));
          setDiscTypeShow(true);
        } else {
          console.log("inside else");

          console.log(total, "total");
          const discountedTotal = total - specialDiscountAmount;
          console.log(total, "-", specialDiscountAmount);
          console.log(discountedTotal, "discount total");
          // console.log(totalValueAfterAddon, "total value after addon features");
          setTotalSubscriptionAmount(discountedTotal.toFixed(2));
          setRefTotal(total.toFixed(2));
          setDiscTypeShow(true);
        }
      }



    } else {
      const total = 0;
      setTotalSubscriptionAmount(total);
    }
  };

  // const calculateTotalSubscription = () => {
  //   if (houseCount !== "" && discount !== "") {
  //     console.log(houseCount,"house count");
  //     console.log(discount,"discount");
  //    console.log(additionalPrices);
  //     const totalAdditionalPrice = Object.values(additionalPrices).reduce(
  //       (acc, price) => acc + price,
  //       0
  //     );

  //     console.log(totalAdditionalPrice,"total additional price");
  //     // console.log("totalAdditionalPrice", totalAdditionalPrice)
  //     const basePrice = parseFloat(planTypeFrPrice);
  //     console.log(basePrice,"base price");
  //     const total = (parseInt(houseCount) * parseFloat(discount)) + totalAdditionalPrice;
  //     console.log(total,"total");
  //     // Apply special discount if applicable
  //     const specialDiscountAmount = parseFloat(specialDiscount) || 0;
  //     console.log(specialDiscount,"special discount");
  //     console.log(specialDiscountAmount,"special discount amount");
  //     const discountedTotal = total - specialDiscountAmount;
  //     console.log(discountedTotal,"discount total");
  //     setTotalSubscriptionAmount(discountedTotal.toFixed(2));
  //     setRefTotal(total.toFixed(2));
  //     setDiscTypeShow(true);
  //   } else {
  //     const total = 0;
  //     setTotalSubscriptionAmount(total);
  //   }
  // };

  useEffect(() => {
    calculateTotalSubscription();
  }, [additionalPrices, houseCount, discount, planTypeFrPrice, totalSubscriptionAmount, specialDiscount]);

  const getAllSubscriptionDetails = async (fieldName) => {
    try {
      const storedToken = token;

      // console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_SUBSCIPTION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      // console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const price = data.find((item) => item.type === planType)?.price;
        // console.log("price", price)
        if (price) {
          setPlanTypeFrPrice(price);
          const discountedPrice = calculateDiscount(price);
          setDiscountedPrice(discountedPrice)
          setDiscount(discountedPrice);
          calculateTotalSubscription();
        }
        const priceObject = data.find((item) => item.type === planType);
        return priceObject ? parseFloat(priceObject[fieldName]) : 0;
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllSubscriptionDetails();
  }, [planType]);

  // useEffect(() => {
  //   calculateTotalSubscription();
  // }, [houseCount, discount]);

  const handleSpecialDiscountChange = (e) => {
    const newSpecialDiscount = e.target.value;
    setSpecialDiscount(newSpecialDiscount);

    if (discountType === "DOLLAR") {
      if (newSpecialDiscount === "") {
        calculateTotalSubscription();
        setSpecialDiscountError("");
      } else {
        const specialDiscountAmount = parseFloat(newSpecialDiscount);
        if (specialDiscountAmount > refTotal) {
          setSpecialDiscountError(`The special discount amount should be between 0 and ${refTotal}`);
        } else if (!/^\d+(\.\d{1,2})?$/.test(newSpecialDiscount)) {
          setSpecialDiscountError(PERCENTANGE_INVALID_MESSAGE);
        } else {
          // Subtract the special discount amount from the total subscription amount
          const updatedTotalSubscriptionAmount = refTotal - specialDiscountAmount;
          setTotalSubscriptionAmount(updatedTotalSubscriptionAmount.toFixed(2));
          setSpecialDiscountError("");
        }
      }
    } else {
      setSpecialDiscountError("");
      setTotalSubscriptionAmount(refTotal);
    }
  };


  const handleSpecialPercDiscountChange = (e) => {
    const newSpecialDiscount = e.target.value;
    console.log(e.target.value, "new special discount");
    setSpecialDiscount(e.target.value);

    if (discountType === "PERCENTAGE") {
      if (newSpecialDiscount === "") {
        calculateTotalSubscription();
        setSpecialDiscountError("");
      } else {
        const specialDiscountPercentage = parseFloat(newSpecialDiscount);

        if (specialDiscountPercentage < 0 || specialDiscountPercentage > 90) {
          setSpecialDiscountError("Special discount percentage must be between 1 and 90");
          setTotalSubscriptionAmount(totalSubscriptionAmount);
        } else if (!NINETY_PERCENTANGE_PATTEN.test(specialDiscountPercentage)) {
          setSpecialDiscountError(
            PERCENTANGE_INVALID_MESSAGE
          );

          setTotalSubscriptionAmount(totalSubscriptionAmount);
        } else {

          const discountAmount = (specialDiscountPercentage / 100) * refTotal;
          console.log("first", discountAmount.toFixed(2))
          const updatedTotalSubscriptionAmount = refTotal - discountAmount;
          setTotalSubscriptionAmount(updatedTotalSubscriptionAmount.toFixed(2));
          setSpecialDiscountError("");
        }
      }
    } else {
      setTotalSubscriptionAmount(refTotal)
    }

  }

  useEffect(() => {
    // console.log("totalSubscription", totalSubscriptionAmount)
    // console.log("price", planTypeFrPrice)

  }, [totalSubscriptionAmount, planTypeFrPrice, specialDiscount])

  const handleRadioChange = (value) => {
    setSpecialDiscount('');
    setDiscountType(value);
    setIsFieldEnabled(true);
    setSpecialDiscountError("");
  };

  useEffect(() => {
    // Reset special discount and error state based on the new discount type
    if (discountType === "DOLLAR") {
      setSpecialDiscountError("");
      setIsFieldEnabled(true);
      setTotalSubscriptionAmount(refTotal); // Reset total subscription amount to refTotal
    } else if (discountType === "PERCENTAGE") {
      setSpecialDiscountError("");
      // setSpecialDiscount("");
      setIsFieldEnabled(true);
      calculateTotalSubscription(); // Recalculate total subscription amount for percentage discount
    }
  }, [discountType, refTotal]);

  const handleDueChange = (event) => {
    setDueType(event.target.value);
    // console.log(event.target.value + "+++++++++++++++");
  };
  const minDate = dayjs().subtract(100, "year").startOf("day");
  const maxDate = dayjs().endOf("day");

  const formationDateChange = (selectedFromDate) => {
    setFormationDate(selectedFromDate);
  };

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Update Subscriber" />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <CustomHeading title="HOA Details" />
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                // requiredErrorMessage={GROUP_NAME_REQUIRED_MESSAGE}
                fieldPattern={ALPHANUMBERIC_PUNCHUCATE}
                patternErrorMessage={GROUP_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldName="groupName"
                fieldId="groupName"
                fieldType="text"
                fieldLabel="Group Name(HOA Name)"
                fieldControl={control}
                fieldError={errors}
                multiline={true}
                tooltipMessage="Enter the name of your group"
                fieldRequiredIcon="*"
                value={groupName}
                onInput={(event) => { setGroupName(event.target.value) }}
              />
              <CustomInputField
                // requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                patternErrorMessage={ADDRESS_INVALID_MESSAGE}
                minLength={10}
                minLengthErrorMessage={MIN_LENGTH_TEN}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                multiline={true}
                fieldName="address"
                fieldId="address"
                fieldType="text"
                tooltipMessage="Enter your full address. Ensure the information is accurate for correspondence and verification purposes."
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={address}
                onInput={(event) => { setAddress(event.target.value) }}
              />

              <CustomInputField
                fieldName="country"
                fieldId="country"
                fieldType="text"
                fieldLabel="Country"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={COUNTRY_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={50}
                maxLengthErrorMessage={MAX_LENGTH_FIFTY}
                fieldControl={control}
                tooltipMessage="Enter the name of your county. This information is important for regional identification and processing purposes."
                fieldError={errors}
                multiline={true}
                value={country}
                onInput={(event) => { setCountry(event.target.value) }}
              />

              <CustomInputField
                // requiredErrorMessage={ZIPCODE_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS_ZIPCODE}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldName="zipcode"
                fieldId="zipcode"
                fieldType="text"
                fieldLabel="ZipCode"
                fieldControl={control}
                tooltipMessage="Enter your ZIP Code. This 5-digit code helps identify your specific geographic location for email delivery and services."
                fieldError={errors}
                fieldRequiredIcon="*"
                value={zipcode}
                onInput={(event) => { setZipcode(event.target.value) }}
              />


              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: PHONE_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Contact"
                      clearErrors={clearErrors}
                      country="US"
                      tooltipMessage="Enter your phone number."
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={fieldState.invalid ? fieldState.error?.message : ''}
                    />
                  </>
                )}
              />

              <CustomInputField
                // requiredErrorMessage={ENTITY_NUMBER_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS_WITH_HYPEN}
                patternErrorMessage={ENTITY_NUMBER_INVALID_MESSAGE}
                minLength={3}
                minLengthErrorMessage={MIN_LENGTH_THREE_VALIDATE_MESSAGE}
                maxLength={20}
                maxLengthErrorMessage={MAX_LENGTH_TWENTY_MESSAGE}
                multiline={true}
                fieldName="entityNumber"
                fieldId="entityNumber"
                fieldType="text"
                fieldLabel="Entity Identity Number"
                placeholder={"000-000-000"}
                fieldControl={control}
                tooltipMessage="Enter your Identity Entity Number (IEN). This unique identifier is used to securely manage your account. Please ensure it is entered correctly and keep it confidential."
                fieldError={errors}
                fieldRequiredIcon="*"
                value={ein}
                onInput={(event) => {
                  const inputValue = event.target.value;
                  // const formattedValue = formatEin(inputValue);
                  const formattedValue = inputValue;
                  setEin(formattedValue);
                }}
              />
              <CustomDateEditPicker
                fieldName="formationDate"
                label="Formation Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={FORMATION_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                tooltipMessage="Select the date on which the group was officially formed. "
                maxDate={maxDate}
                value={formationDate}
                onChange={formationDateChange}
              />



            </section>
            <CustomHeading title="Group Admin Details" />
            <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <div>
                <button
                  type="button"
                  style={{ backgroundColor: '#1975D1', color: '#fff' }}
                  onClick={handleAddClick}
                  className="py-2 px-4 rounded"
                >
                  Add group admin
                </button> &nbsp;


              </div>
              {showInputs && adminFields.map((admin, index) => (
                <React.Fragment key={index}>
                  <CustomInputField
                    fieldPattern={ALPHANUMBERIC_PUNCHUCATE}
                    patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                    // minLength={2}
                    // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    // maxLength={250}
                    multiline={true}
                    // maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                    fieldName={`firstName-${index}`}
                    fieldId={`firstName-${index}`}
                    fieldType="text"
                    fieldLabel="First Name"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    tooltipMessage="Enter the first name of the group administrator. This will be used for identification and contact purposes."
                    value={admin.firstName}
                    onInput={(event) => handleAdminFieldChange(index, 'firstName', event.target.value)}
                  />

                  <CustomInputField
                    fieldPattern={ALPHANUMBERIC_PUNCHUCATE}
                    patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                    // minLength={2}
                    // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    // maxLength={250}
                    multiline={true}
                    // maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                    fieldName={`lastName-${index}`}
                    fieldId={`lastName-${index}`}
                    fieldType="text"
                    fieldLabel="Last Name"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    tooltipMessage="Enter the last name of the group administrator. This will be used for identification and contact purposes."
                    value={admin.lastName}
                    onInput={(event) => handleAdminFieldChange(index, 'lastName', event.target.value)}
                  />

                  <CustomInputField
                    fieldPattern={EMAIL_REGEX_PATTERNS}
                    patternErrorMessage={EMAIL_INVALID_MESSAGE}
                    minLength={2}
                    minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    maxLength={250}
                    multiline={true}
                    maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                    fieldName={`email-${index}`}
                    fieldId={`email-${index}`}
                    fieldType="text"
                    fieldLabel="Email"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    tooltipMessage="Enter the email of the group administrator. This will be used for identification and contact purposes."
                    value={admin.email}
                    onInput={(event) => handleAdminFieldChange(index, 'email', event.target.value)}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: PHONE_REQUIRED_MESSAGE,
                      validate: {
                        isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <CustomPhoneInput
                          fieldControl={control}
                          label="Contact"
                          clearErrors={clearErrors}
                          country="US"
                          tooltipMessage="Enter the contact of the group administrator. This will be used for identification and contact purposes."
                          fieldName={`contact-${index}`}
                          fieldId={`contact-${index}`}
                          value={admin.contact}
                          // onChange={(value) => field.onChange(value)}
                          // onChange={(event) => handleAdminFieldChange(index, 'contact', event.target.value)}
                          onChange={(value) => handleAdminFieldChange(index, 'contact', value)}
                          error={fieldState.invalid ? fieldState.error : null}
                          helperText={fieldState.invalid ? fieldState.error?.message : ''}
                        />
                      </>
                    )}
                  />

                  <div className="flex space-x-4">
                    {admin.isActive && !admin.isPrimary && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => handleDeactiveClick(admin.adminId)}
                      >
                        Deactivate
                      </button>
                    ) : (
                      null
                    )}
                    {admin.isActive && admin.isPrimary && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => handlePrimaryDeactiveClick(admin.adminId)}
                      >
                        Deactivate
                      </button>
                    ) : (
                      null
                    )}
                    {!admin.isActive && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => {
                          handleActivateClick(admin.adminId)
                        }}
                      >
                        Activate
                      </button>
                    ) : (
                      null
                    )}

                    {!admin.isPrimary && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        // onClick={handleRemoveClick} 
                        onClick={() => {
                          handleDeleteOpen(admin.adminId)
                        }}
                      >
                        Delete
                      </button>
                    ) : (
                      null
                    )}
                    {admin.isPrimary && admin.isActive && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        // onClick={handleRemoveClick} 
                        onClick={() => {
                          handlePrimaryDeleteOpen(admin.adminId)
                        }}
                      >
                        Delete
                      </button>
                    ) : (
                      null
                    )}
                    {!admin.emailVerified && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => {
                          handleResendOpen(admin.adminId)
                        }}
                      >
                        Resend
                      </button>
                    ) : (
                      null
                    )}
                    {admin.emailVerified && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => {
                          handleResendFailOpen(admin.adminId)
                        }}
                      >
                        Resend
                      </button>
                    ) : (
                      null
                    )}
                    {!admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => {
                          handleRemoveClick(index)

                        }}
                      >
                        Remove
                      </button>
                    ) : (
                      null
                    )}
                  </div>
                </React.Fragment>
              ))}
            </section>


            <br />
            {/* {!admin.isPrimary && admin.adminId ? (
                      <button
                        className="py-2 px-4 rounded"
                        style={{ backgroundColor: '#1975D1', color: '#fff' }}
                        type="button"
                        onClick={() => {
                          handlePrimaryOpen(admin.adminId, admin.email)
                        }}
                      >
                        Promote as Primary
                      </button>
                    ) : (
                      null
                    )} */}
            {/* <Controller
              name="phoneNumber"
              control={control}
              // rules={{ required: CONTACT_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    error={fieldState.invalid}
                    country={"us"}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </>
              )}
            /> */}
            <br />
            {/* <CustomHeading title="Group Admin Details" />
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
              <CustomInputField
                //requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                //fieldPattern={ONLY_ALPHABETIC}
                //patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                //minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                //maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="lastName"
                //requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                //fieldPattern={ONLY_ALPHABETIC}
                //patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={2}
                //minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                //maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="email"
                //requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                //fieldPattern={EMAIL_REGEX_PATTERN}
                //patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <Controller
                name="phoneNumber"
                control={control}
                // rules={{ required: CONTACT_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <>
                    <PhoneInput
                      error={fieldState.invalid}
                      country={"us"}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </>
                )}
              />
            </section> */}

            <CustomHeading title="Subscription Details" />
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                // requiredErrorMessage={HOUSE_COUNT_REQUIERD_MESSAGE}
                fieldPattern={DIGITS_ONE_TO_FOUR_DECIMAL}
                patternErrorMessage={HOUSE_COUNT_INVALID_MESSAGE}
                // minLength={1}
                // minLengthErrorMessage={MIN_LENGTH_ONE}
                // maxLength={4}
                // maxLengthErrorMessage={MAX_LENGTH_FOUR}
                fieldName="houseCount"
                fieldId="houseCount"
                fieldType="text"
                fieldLabel="House Count"
                fieldControl={control}
                fieldError={errors}
                tooltipMessage="Enter the total number of houses. This information is used for planning and subscription purposes."
                fieldRequiredIcon="*"
                onInput={handleHouseCountChange}
                value={houseCount}
              // onInput={(event)=>{setHouseCount(event.target.value)}}
              />

              {/* <Controller
                name="freeTrial"
                control={control}
                // rules={{ required: FREE_TRIAL_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please Select Free Trial
                      <span style={{ color: "#D32F2F" }}>*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={(e) => {
                        setType(e.target.value);
                        field.onChange(e.target.value);
                        const currentDate = new Date();
                        setSubscriptionStartDate(currentDate);

                        let newEndDate = new Date(currentDate);

                        if (e.target.value === "0") {
                          // For immediate subscription, no additional days to add
                        } else if (e.target.value === "30") {
                          newEndDate.setDate(currentDate.getDate() + 30);
                        } else if (e.target.value === "60") {
                          newEndDate.setDate(currentDate.getDate() + 60);
                        } else if (e.target.value === "90") {
                          newEndDate.setDate(currentDate.getDate() + 90);
                        }

                        if (planTerm === "1 YEAR") {
                          newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                        }
                        setSubscriptionEndDate(newEndDate);
                      }}
                      // onChange={handleTypeChange}
                      // onChange={(e) => {
                      //   setType(e.target.value);
                      //   field.onChange(e.target.value);
                      //   const currentDate = new Date();
                      //   let newStartDate = new Date(currentDate);

                      //   if (e.target.value === "immediate") {
                      //     newStartDate.setDate(currentDate.getDate());
                      //     setSubscriptionStartDate(newStartDate);
                      //   } else if (e.target.value === "30 days") {
                      //     newStartDate.setDate(currentDate.getDate() + 30);
                      //     setSubscriptionStartDate(newStartDate);
                      //   } else if (e.target.value === "60 days") {
                      //     newStartDate.setDate(currentDate.getDate() + 60);
                      //     setSubscriptionStartDate(newStartDate);
                      //   } else if (e.target.value === "90 days") {
                      //     newStartDate.setDate(currentDate.getDate() + 90);
                      //     setSubscriptionStartDate(newStartDate);
                      //   }

                      //   let newEndDate = new Date(newStartDate);
                      //   if (planTerm === "1 year") {
                      //     newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                      //   }
                      //   setSubscriptionEndDate(newEndDate);
                      // }}
                      label="Please Select Free Trial"
                    >
                      <MenuItem value="0">Immediate</MenuItem>
                      <MenuItem value="30">30 days</MenuItem>
                      <MenuItem value="60">60 days</MenuItem>
                      <MenuItem value="90">90 days</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              /> */}

              <CustomInputField
                fieldPattern={ONE_TO_NINETY_NINE}
                patternErrorMessage={FREE_TRIAL_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_ONE}
                maxLength={2}
                maxLengthErrorMessage={MAX_LENGTH_TWO_MESSAGE}
                fieldName="freeTrial"
                fieldId="freeTrial"
                fieldType="text"
                fieldLabel="Free Trial (in days)"
                fieldControl={control}
                tooltipMessage="Enter your days to start your free trial."
                fieldError={errors}
                fieldRequiredIcon="*"
                onInput={handleDaysInputChange}
                value={type}
                disabled
              />
              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="subscriptionPlanTerm"
                  control={control}
                  // rules={{ required: SUBSCRIPTION_PLAN_TERM_REQUIRED_MESSAGE }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={fieldState.invalid}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Please Select Subscription Plan Term
                        <span style={{ color: "#D32F2F" }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={planTerm}
                        onChange={(e) => {
                          setPlanTerm(e.target.value);
                          field.onChange(e.target.value);
                        }}
                        label=" Please Select Subscription Plan Term"
                      >
                        <MenuItem value="1 YEAR">1 Year</MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "#D32F2F" }}>
                        {fieldState.invalid ? fieldState.error?.message : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                /> <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>{"Select the term duration for your subscription plan. Choose the option that best fits your needs and budget."}</Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="subscriptionPlanType"
                  control={control}
                  // rules={{ required: SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={fieldState.invalid}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Please Select Subscription Plan Type
                        <span style={{ color: "#D32F2F" }}> * </span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={planType}
                        onChange={(e) => handlePlanTypeChange(e, field)}
                        label=" Please Select Subscription Plan Type"
                      >
                        {/* <MenuItem value="free">Free</MenuItem> */}
                        <MenuItem value="BASIC">Basic</MenuItem>
                        <MenuItem value="EXTENDED">Extended</MenuItem>
                        <MenuItem value="PREMIUM">Premium</MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "#D32F2F" }}>
                        {fieldState.invalid ? fieldState.error?.message : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>{"Select the type of subscription plan you prefer. Choose from options such as Basic, Extended, or Premium, based on your desired features and budget."} </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              {planType === "BASIC" ? (
                <>
                  <section>
                    <CustomHeading title="Features included in this plan" />
                    <CustomCheckBox
                      fieldName="basic"
                      control={control}
                      labelName="Basic"
                      checked={basic}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("basic");
                        handleBasicChange()
                      }}
                    />
                  </section>
                  {/* <section>
                    <CustomHeading title="Add-on features" />
                    <CustomCheckBox
                      fieldName="virtualManager"
                      control={control}
                      labelName="Virtual Manager"
                      checked={virtual}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("virtualManager");
                        handleVirtualChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="dedicatedPhone"
                      control={control}
                      labelName="Dedicated Phone Number"
                      checked={dedicated}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("dedicatedPhone").then(() => {
                          calculateTotalSubscription();
                        });
                        handleDedicatedChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="domainRegistration"
                      control={control}
                      labelName="Domain Registration"
                      checked={domain}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("domainRegistration");
                        handleDomainChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="webHosting"
                      control={control}
                      labelName="Web Hosting"
                      checked={webHosting}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("webHosting");
                        handleWebHosting()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="virtualarc"
                      control={control}
                      labelName="ARC"
                      checked={arcs}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("virtualarc");
                        handleArcChange()
                      }}
                    />
                  </section> */}
                </>
              ) : null}

              {planType === "EXTENDED" ? (
                <>
                  <section>
                    <CustomHeading title="Features included in this plan" />
                    <CustomCheckBox
                      fieldName="extended"
                      control={control}
                      labelName="Extended"
                      checked={extended}
                      disabled
                      onChange={
                        () => {
                          handleCheckboxChange("extended");
                          handleExtendedChange()
                        }}
                    />
                    <CustomCheckBox
                      fieldName="itServices"
                      control={control}
                      labelName="IT Services"
                      checked={itServices}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("itServices");
                        handleItservices()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="accounting"
                      control={control}
                      labelName="Accounting"
                      checked={accounting}
                      onChange={() => {
                        handleCheckboxChange("accounting");
                        handleAccountingChange()
                      }}
                      disabled
                    />
                  </section>
                  <section>
                    <CustomHeading title="Add-on features" />
                    <CustomCheckBox
                      fieldName="virtualManager"
                      control={control}
                      checked={virtualExt}
                      labelName="Virtual Manager"
                      onChange={() => {
                        console.log("Checkbox changed!");
                        handleCheckboxChange("virtualManager");
                        handleVirtualChangeExt()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="dedicatedPhone"
                      control={control}
                      labelName="Dedicated Phone Number"
                      checked={dedicatedExt}
                      onChange={() => {
                        handleCheckboxChange("dedicatedPhone");
                        handleDedicatedChangeExt()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="domainRegistration"
                      control={control}
                      labelName="Domain Registration"
                      checked={domainExt}
                      onChange={() => {
                        handleCheckboxChange("domainRegistration");
                        handleDomainChangeExt()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="webHosting"
                      control={control}
                      labelName="Web Hosting"
                      checked={webHostingExt}
                      onChange={() => {
                        handleCheckboxChange("webHosting");
                        handleWebHostingExt()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="virtualarc"
                      control={control}
                      labelName="ARC"
                      checked={arcsExt}
                      onChange={() => {
                        handleCheckboxChange("virtualarc");
                        handleArcChangeExt()
                      }}
                    />
                  </section>
                </>
              ) : null}

              {planType === "PREMIUM" ? (
                <>
                  <section>
                    <CustomHeading title="Features included in this plan" />
                    <CustomCheckBox
                      fieldName="premium"
                      control={control}
                      labelName="Premium"
                      checked={premium}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("premium");
                        handlePremiumChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="itServices"
                      control={control}
                      labelName="IT Services"
                      checked={itServices}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("itServices");
                        handleItservices()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="accounting"
                      control={control}
                      labelName="Accounting"
                      checked={accounting}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("accounting");
                        handleAccountingChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="virtualManager"
                      control={control}
                      labelName="Virtual Manager"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("virtualManager");
                        handleVirtualChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="dedicatedPhone"
                      control={control}
                      labelName="Dedicated Phone number"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("dedicatedPhone");
                        handleDedicatedChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="domainRegistration"
                      control={control}
                      labelName="Domain Registration / Year"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("domainRegistration");
                        handleDomainChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="webHosting"
                      control={control}
                      labelName="Web Hosting"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("webHosting");
                        handleWebHosting()
                      }}
                    />
                  </section>
                  <section>
                    <CustomHeading title="Add-on features" />
                    <CustomCheckBox
                      fieldName="virtualarc"
                      control={control}
                      labelName="ARC"
                      checked={arcsExt}
                      onChange={() => {
                        handleCheckboxChange("virtualarc");
                        handleArcChangeExt()
                      }}
                    />
                  </section>
                </>
              ) : null}

              <CustomInputField
                fieldName="pricing"
                disabled={true}
                fieldId="pricing"
                fieldType="text"
                fieldLabel="pricing per unit"
                fieldControl={control}
                fieldError={errors}
                tooltipMessage="This is the cost associated with each individual unit."
                value={planTypeFrPrice}
              />

              <CustomInputField
                fieldName="discount"
                disabled={true}
                fieldId="discount"
                fieldType="text"
                fieldLabel="Discount"
                fieldControl={control}
                fieldError={errors}
                tooltipMessage="This discount will be automatically deducted from the total price."
                value={discountedPrice}
              />


              <section>
                {discountType === "DOLLAR" ? (
                  <>
                    <CustomInputField
                      fieldName="specialDiscount"
                      fieldId="specialDiscount"
                      fieldType="text"
                      fieldLabel="Special Discount"
                      fieldControl={control}
                      fieldError={errors}
                      tooltipMessage="Enter a special discount percentage to be applied. This discount will also apply over total price."
                      disabled={!isFieldEnabled}
                      onInput={handleSpecialDiscountChange}
                      value={specialDiscount}
                    />
                  </>
                ) : (
                  <CustomInputField
                    fieldName="specialDiscount"
                    fieldId="specialDiscount"
                    fieldType="text"
                    fieldLabel="Special Discount"
                    fieldControl={control}
                    fieldError={errors}
                    disabled={!isFieldEnabled}
                    tooltipMessage="Enter a special discount percentage to be applied. This discount will also apply over total price."
                    value={specialDiscount}
                    onInput={handleSpecialPercDiscountChange}
                  />
                )}
                {specialDiscountError && (
                  <p class="text-red-600 text-xs">{specialDiscountError}</p>
                )}

                <CustomRadioButton
                  fieldName="specialDiscountType"
                  fieldControl={control}
                  fieldError={errors}
                  valueOne="PERCENTAGE"
                  labelOne="Percentage"
                  valueTwo="DOLLAR"
                  labelTwo="Dollar"
                  onChange={handleRadioChange}
                  disabled={!discTypeShow}
                  showAsterisk={false}
                  value={discountType}
                  tooltipMessage={"Choose the type of special discount: either in dollars or as a percentage. This determines how the discount will be calculated and applied."}

                />
              </section>

              <CustomInputField
                fieldName="totalSubscriptionAmount"
                disabled={true}
                fieldId="totalSubscriptionAmount"
                fieldType="text"
                fieldLabel="Total Subscription Amount"
                fieldControl={control}
                tooltipMessage="The total subscription amount will be calculated automatically based on selected subscription options."
                fieldError={errors}
                value={totalSubscriptionAmount}
              />
              {/* <CustomInputField
                fieldName="additionalPrice"
                disabled={true}
                fieldId="additionalPrice"
                fieldType="text"
                fieldLabel="Additional Price / unit(Applicable only above 235)"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="totalCost"
                disabled={true}
                fieldId="totalCost"
                fieldType="text"
                fieldLabel="Total Cost / House"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              /> */}
              {specialDiscount ? (
                <>
                  <CustomInputField
                    fieldName="remarks"
                    fieldId="remarks"
                    fieldType="text"
                    tooltipMessage="Add any additional comments or notes here. This field is optional and can be used to provide further context or instructions."
                    fieldLabel="Remarks"
                    fieldControl={control} // Assuming control is defined elsewhere
                    fieldError={errors} // Assuming errors is defined elsewhere
                    fieldRequiredIcon="*"
                    // disabled={!isFieldEnabled}
                    onInput={(event) => { setRemarks(event.target.value) }}
                    value={remarks}
                  />
                  <br />
                </>
              ) : null}

              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Subscription Start Date"
                      value={subscriptionStartDate}
                      sx={{ width: "100%" }}
                      // onChange={(date) => setSubscriptionStartDate(date)}
                      slotProps={{
                        textField: {
                          variant: "standard",
                        },
                      }}
                      disabled
                    />
                  </LocalizationProvider>
                </div>
                <div>{renderTooltip("The subscription start date is automatically set based on the activation of your subscription plan.")}</div>
              </div>


              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Subscription End Date"
                      value={subscriptionEndDate}
                      sx={{ width: "100%" }}
                      // onChange={(date) => setSubscriptionEndDate(date)}
                      slotProps={{
                        textField: {
                          variant: "standard",
                        },
                      }}
                      disabled
                    />
                  </LocalizationProvider>
                </div>
                <div>{renderTooltip("The subscription end date is automatically set based on the activation of your subscription plan.")}</div>
              </div>


              <CustomInputFieldDisabled
                fieldName="publishkey"
                fieldId="publishkey"
                fieldType="text"
                tooltipMessage="The publishable key from Stripe is a secure token provided by the Stripe payment processing platform. It is used to authenticate and authorize payment transactions. Please ensure this key is kept confidential and securely managed."
                fieldLabel="Publish key"
                // minLength={2}
                // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={120}
                maxLengthErrorMessage={MAX_LENGTH_ONE_TWENTY_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                value={publishKey}
                disabled={publishKey}
                onInput={(event) => { setPublishKey(event.target.value) }}
                clearField={handleClearPublishKeyClick}
              />

              <CustomInputFieldDisabled
                fieldName="secretKey"
                fieldId="secretKey"
                fieldType="text"
                tooltipMessage="The secret key from Stripe is a confidential token used for server-side communication with the Stripe API. Treat this key with the utmost confidentiality and do not expose it in client-side code or public repositories."
                fieldLabel="Secret key"
                // minLength={2}
                // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={120}
                maxLengthErrorMessage={MAX_LENGTH_ONE_TWENTY_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                value={secretKey}
                disabled={secretKey}
                onInput={(event) => { setSecretKey(event.target.value) }}
                clearField={handleClearSecretKeyClick}
              />

              <CustomInputField
                fieldName="accountId"
                fieldId="accountId"
                fieldType="text"
                tooltipMessage="The Account ID from Stripe is a unique identifier assigned to your Stripe account. It is used for authentication and identification purposes within the Stripe platform. Keep this ID confidential to maintain account security and integrity."
                fieldLabel="Account Id"
                fieldControl={control}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={30}
                maxLengthErrorMessage={MAX_LENGTH_THIRTY_MESSAGE}
                fieldError={errors}
                value={accountId}
                onInput={(event) => { setAccountId(event.target.value) }}
              />
              <CustomInputField
                fieldName="purchasesMail"
                fieldId="purchasesMail"
                fieldType="text"
                fieldLabel="Purchase Email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                tooltipMessage="tooltip 1"
                fieldControl={control}
                fieldError={errors}
                value={purchaseMail}
                onInput={(event) => { setPurchaseMail(event.target.value) }}
              />
              <CustomInputField
                fieldName="salesMail"
                fieldId="salesMail"
                fieldType="text"
                tooltipMessage="Enter the email address designated for sales-related communication. This email will be used for inquiries, quotations, and other sales-related correspondence."
                fieldLabel="Sales Email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                value={salesMail}
                onInput={(event) => { setSalesMail(event.target.value) }}
              />
              <CustomInputField
                fieldName="expensesMail"
                tooltipMessage="Enter the email address designated for expenses-related communication. This email will be used for submitting receipts, expense reports, and other expenses-related correspondence."
                fieldId="expensesMail"
                fieldType="text"
                fieldLabel="Expenses Email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                value={expensesMail}
                onInput={(event) => { setExpensesMail(event.target.value) }}
              />
              <CustomInputField
                fieldName="suppliersMail"
                fieldId="suppliersMail"
                fieldType="text"
                tooltipMessage="Enter the email address designated for communication with suppliers. This email will be used for inquiries, orders, and other supplier-related correspondence."
                fieldLabel="Suppliers Email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                value={suppliersMail}
                onInput={(event) => { setSuppliersMail(event.target.value) }}
              />


            </section>


            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>

        {/* <--------Clear secret key ----> */}
        <CustomModel
        title=" Secret Key"
        submit={clearSecretKey}
        content={ClearSecretKeyConfirmModel}
        action={ClearSecretKeyConfirmActions}
        openStatus={clearSecretKeyConfirmOpen}
        closeStatus={handleClearSecretKeyConfirmIconClose}
        iconCloseStatus={handleClearSecretKeyConfirmIconClose}
        // reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}

      />
  {/* <--------Clear Publish key ----> */}
        <CustomModel
        title=" Publish Key"
        submit={clearPublishKey}
        content={ClearPublishKeyConfirmModel}
        action={ClearPublishKeyConfirmActions}
        openStatus={clearPublishKeyConfirmOpen}
        closeStatus={handleClearPublishKeyConfirmIconClose}
        iconCloseStatus={handleClearPublishKeyConfirmIconClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}

      />

        {/* <--------deactivate modal ----> */}

        <CustomModel
          title="Group Admin Deactivation"
          submit={onSubmitDeactiveConfirm}
          content={deactiveConfirmModel}
          action={deactiveConfirmActions}
          openStatus={deactiveConfirmOpen}
          closeStatus={handledeactiveConfirmClose}
          iconCloseStatus={handledeactiveConfirmIconClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />
        {/* <--------Activate Modal ----> */}

        <CustomModel
          title="Group Admin Activataion"
          submit={onSubmitActiveConfirm}
          content={activeConfirmModel}
          action={activeConfirmActions}
          openStatus={activeConfirmOpen}
          closeStatus={handleActiveConfirmClose}
          iconCloseStatus={handleActiveConfirmClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />
        {/* <--------Deactivate warning ----> */}

        <CustomModel
          title="Group Admin Deactivation"
          content={deactivePrimaryConfirmModel}
          action={deactivePrimaryConfirmActions}
          openStatus={deactivePrimaryConfirmOpen}
          closeStatus={handledeactivePrimaryConfirmClose}
          iconCloseStatus={handledeactivePrimaryConfirmClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />
        {/* <--------Promote modal ----> */}

        <CustomModel
          title="Promote as primary"
          submit={onSubmitPromote}
          content={promoteConfirmModel}
          action={promoteActions}
          openStatus={promoteOpen}
          closeStatus={handlePromoteClose}
          iconCloseStatus={handlePromoteIconClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />

        <CustomModel
          title="Sent OTP"
          submit={onSubmitOtp} // Define this function to handle OTP submission
          content={otpConfirmModel}
          action={otpActions}
          openStatus={otpOpen}
          closeStatus={handleOtpClose}
          iconCloseStatus={handleOtpClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />
        {/* <--------Delete modal ----> */}

        <CustomModel
          title="Delete Group Admin"
          submit={onSubmitDelete}
          content={deleteModel}
          action={okCancelButtonActions}
          openStatus={deleteOpen}
          closeStatus={handleDeleteClose}
          iconCloseStatus={handleDeleteClose}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          reset={reset}
          isLoading={isLoading}
        />
        {/* <--------Delete warning ----> */}

        <CustomModel
          title="Delete Group Admin"
          content={deletePrimaryModel}
          action={okCancelPrimaryButtonActions}
          openStatus={deletePrimaryOpen}
          closeStatus={handleDeletePrimaryClose}
          iconCloseStatus={handleDeletePrimaryClose}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          reset={reset}
          isLoading={isLoading}
        />
        {/* <--------Resend Modal ----> */}

        <CustomModel
          title="Resend Email"
          submit={onResendSubmit}
          content={resendModel}
          action={ResendActions}
          openStatus={resendOpen}
          closeStatus={handleResendClose}
          iconCloseStatus={handleResendIconClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />
        {/* <--------Resend warning ----> */}
        <CustomModel
          title="Resend Email"

          content={resendFailModel}
          action={ResendFailActions}
          openStatus={resendFailOpen}
          closeStatus={handleResendFailClose}
          iconCloseStatus={handleResendFailIconClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
          isLoading={isLoading}
        />
      </Card>
    </div>
  );
}

export default UpdateSubscriber;
