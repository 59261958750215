import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

function CustomCheckBox(props) {
  return (
    <div>
            <Controller
        name={props.fieldName}
        control={props.control}
        disabled={props.disabled}
        // defaultValue={props.defaultValue}
        // rules={{ required: 'This field is required' }}
        render={({ field, fieldState }) => (
          <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={props.checked} // Ensure the checkbox state is controlled
              onChange={props.onChange}
            />
          }
            label={<Typography style={{fontSize:16,fontFamily:`'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`}}>{props.labelName}</Typography>}
           
           
          />
        )}
      />
    </div>
  )
}

export default CustomCheckBox