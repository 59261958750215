import React from 'react'
import ForgetChangePassword from '../../components/forgotpassword/ForgotChangepassword'

function ForgotChange() {
  return (
    <div>
      <ForgetChangePassword/>
    </div>
  )
}

export default ForgotChange
