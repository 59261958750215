import React, { useEffect, useRef, useState } from 'react';
import CustomHeading from './CustomHeading';
import { Button, Typography } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CustomStyle } from './CustomStyle';

function CustomViewHeading({ title, onClick, children }) {
  const [rendered, setRendered] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const waitForRender = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust timeout as needed
      setRendered(true);
    };
    waitForRender();
  }, []);

  const exportToPdf = async () => {
    if (!rendered) return; // Exit if not fully rendered
  
    const input = cardRef.current;
    const margin = 10; // Adjust margin size as needed
    const canvas = await html2canvas(input, {
      scale: 2, // Adjust scale for better quality
      width: input.offsetWidth + margin * 2, // Add margin on each side
      height: input.offsetHeight + margin * 2 // Add margin on top and bottom
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const contentWidth = pdfWidth - margin * 2; // Adjust content width
    const contentHeight = pdfHeight - margin * 2; // Adjust content height
    const ratio = contentWidth / canvas.width;
    const newHeight = canvas.height * ratio;
    pdf.addImage(imgData, 'PNG', margin, margin, contentWidth, newHeight);
    const pdfUrl = pdf.output('bloburl');
    window.open(pdfUrl);
  };
  

  return (
    <div ref={cardRef} className="pdf-container">
      <div className="flex flex-col md:flex-row">
      <Typography>
  <span className='font-bold text-lg' style={{ maxWidth: 'calc(100% - 100px)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{title}</span>
</Typography>
        <div className="md:full md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
          <Button variant="contained" onClick={onClick}>
            <span className={CustomStyle.containedButtonStyle}>Back</span>
          </Button>
          <Button variant="contained" onClick={exportToPdf}>
            <span className={CustomStyle.containedButtonStyle}>Export to PDF</span>
          </Button>
        </div>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export default CustomViewHeading;
