import {
  AccountBalanceOutlined,
  CreditCardOutlined,
  EditNoteOutlined,
  InfoOutlined,
  ListAltOutlined,
  PersonAddDisabledOutlined,
  PersonAddOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomStyle } from "../../components/CustomStyle";
import {
  ACTIVE_GROUP,
  BASE_URL,
  DEACTIVE_GROUP,
  GROUP,
  PAYMENT_DETAILS,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  DEACTIVATE_REQUIRED_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REMARKS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import CustomInputField from "../../components/CustomInputField";
import dayjs from "dayjs";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";

function DeactivateSubscriber() {
  const [groupDetails, setGroupDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { token } = useSelector((state) => state.hybridhoa);
  const [selectedRow, setSelectedRow] = useState("");

  const [endDate, setEndDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [deactivateDate, setDeactivateDate] = useState("");
  const { groupId } = useParams();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const renderTooltip = (message) => (
    <Tooltip
      title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
      placement="right"
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );

  // const getAllGroupDetails = async () => {
  //   try {
  //     setIsLoading(true);
  //     const storedToken = token;

  //     console.log(storedToken);

  //     const response = await axios.get(`${BASE_URL}${GROUP}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response.data);

  //     if (response.status === 200) {
  //       const data = response.data;

  //       console.log(data);
  //       setGroupDetails(data);

  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getAllGroupDetails();
  // }, []);

  const generateOptions = (date = null) => {
    const options = [];
    const currentDate = new Date();
    const endDates = new Date(endDate);
    console.log("endDate", endDates);

    if (endDates && currentDate <= endDates) {
      if (date != null) {
        options.push(
          <MenuItem key={date} value={date} disabled>
            {date}
          </MenuItem>
        );
      }

      while (currentDate <= endDates) {
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );

        options.push(
          <MenuItem
            key={lastDayOfMonth.toISOString()}
            value={lastDayOfMonth.toISOString().split('T')[0]} // Only keep the date part
          >
            {lastDayOfMonth.toLocaleDateString()}
          </MenuItem>
        );

        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    } else {
      console.log("Invalid endDate or currentDate exceeds endDate");
    }
    return options;
  };

  const getGroupDataById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP}/${groupId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const remarkData = data.groupSubscriptionDetails.remarks;
        setRemarks(remarkData);
        console.log(data);
        const ends = data.groupSubscriptionDetails.endDate;
        const deactivateDateData =
          data.groupSubscriptionDetails.scheduledDeactivatedDate;
        console.log(data.groupSubscriptionDetails.scheduledDeactivatedDate);
        const formattedDeactivatedDate = deactivateDateData ? deactivateDateData.split('T')[0] : '';
        
        setDeactivateDate(
          formattedDeactivatedDate
        );
        setEndDate(ends ? dayjs(ends).format("MM-DD-YYYY") : "");
        console.log(dayjs(ends).format("MM-DD-YYYY"));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getGroupDataById();
  }, [groupId]);

  const onSubmit = async () => {
    try {
      console.log(deactivateDate);
      setIsLoading(true);
      const storedToken = token;

      if (!deactivateDate) {
        setError("deactivateDate", {
          type: "manual",
          message: "Please Select Deactivate Date",
        });
      setIsLoading(false);

        return;
      }

      if (remarks.trim()==="") {
        setError("remarks", {
          type: "manual",
          message: REMARKS_REQUIRED_MESSAGE,
        });
      setIsLoading(false);

        return;
      }

      // Convert deactivateDate to the correct format before sending to the backend
      // const adjustedDeactivateDate = dayjs(deactivateDate).add(1, 'day').format('YYYY-MM-DD');

      const payload = {
        remarks: remarks.trim(),
        deactivateDate: deactivateDate,
      };

      const response = await axios.post(
        `${BASE_URL}${DEACTIVE_GROUP}/${groupId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        setIsLoading(false);
        const deactivatedData = response.data;
        getGroupDataById();

        console.log("Data updated successfully");
        toast.success(response.data);

        reset();
        setTimeout(() => {
          navigate(-1);
        }, 5000);
      } else {
        setIsLoading(false);
        throw new Error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Failed to deactivate subscriber");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <div className="flex flex-col md:flex-row">
        <CustomHeading title="Deactivate Subscriber" />
        <div className="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>

      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <Controller
                    name="deactivateDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Deactivate Date
                          <span style={{ color: "red", gap: "2px" }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={deactivateDate}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setDeactivateDate(e.target.value);
                            field.onChange(e.target.value);
                          }}
                          label="Please Select Deactivate Date"
                        >
                          {generateOptions(deactivateDate)}
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div>{renderTooltip("Select group deactivate date")}</div>
              </div>

              <CustomInputField
                fieldName="remarks"
                fieldId="remarks"
                fieldType="text"
                fieldLabel="Remarks"
                maxLength={5000}
                maxLengthErrorMessage={"Please provide maximum of 5000 characters only"}
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                onInput={(event) => {
                  setRemarks(event.target.value);
                }}
                value={remarks}
                numberOfRows={1}
                tooltipMessage={"Give a remark for deactivate the group"}
              />
            </section>
            <div className="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Deactivate" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default DeactivateSubscriber;
