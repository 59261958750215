import { DatePicker, LocalizationProvider ,MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

function CustomDatePicker(props) {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );
  const formattedValue = props.value ? dayjs(props.value) : null;
  return (
    <div className="flex items-center md:w-1/2 w-full">
    <Controller
      control={props.control}
      name={props.fieldName}
      rules={{
        required: props.requiredErrorMessage,
      }}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            {...field}
            views={["year", "month", "day"]}
            minDate={props.minDate}
            maxDate={props.maxDate}
            disabled={props.disabled}
            value={props.value}
            ref={props.ref}
            onChange={(date) => {
              field.onChange(date);
              console.log("Selected Date:", date);
              props.onChange && props.onChange(date);
            }}
            slotProps={{
              textField: {
                variant: "standard",
                label: (
                  <CustomInputLabel
                    label={props.label}
                    requiredIcon={props.fieldRequiredIcon}
                  />
                ),

                error: fieldState.invalid,
                helperText: fieldState.invalid ? fieldState.error?.message : "",
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
     {props.tooltipMessage && (
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>{props.tooltipMessage}</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
  );
}

export default CustomDatePicker;
