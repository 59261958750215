import React, { useEffect, useState } from "react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import './../../index.css'
import CardLayout from "./../../components/CardLayout";
import CustomHeading from "./../../components/CustomHeading";
import CustomLoading from "./../../components/CustomLoading";
import { InsertDriveFileOutlined, HandymanOutlined } from "@mui/icons-material";
import { BASE_URL, DASHBOARD } from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});
  const { token } = useSelector(state => state.hybridhoa);

 
    const getSystemPortalDasboardData = async () => {
      try {
        const storedToken = token;

        // Fetch dashboard data
        const response1 = await axios.get(`${BASE_URL}${DASHBOARD}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response1.status === 200) {
          setDatas(response1.data);
        } else {
          throw new Error("Failed to fetch dashboard data");
        }
      

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };


    useEffect(() => {
      getSystemPortalDasboardData();
  }, [token]);

  const cardDatas=([
    {
      count: datas.subscribers,
      name: "Total Subscribers",
      icon: <InsertDriveFileOutlined />,
      color: ["#fee2e2"],//red
    },
    {
      count: datas.activeSubscribers,
      name: "Active subscribers",
      icon: <HandymanOutlined />,
      color: ["#bbddff"],//blue
    },
    {
      count: datas.inActiveSubscribers,
      name: "Inactive subscribers",
      icon: <DashboardOutlinedIcon />,
      color: ["#c2f6c2"],//green
    },
    
  ]);


  if (isLoading) {
    return <div>
      <CustomLoading/>
    </div>; // Render loading indicator while fetching data
}
  return (
    <>
      <CustomHeading title="Dashboard" />
      <br />

      <CardLayout cardData={cardDatas}   />
      {/* <div class="fixed bottom-0 right-0 m-4">
        <a href="https://hybridhoa.com" target="blank">
          <img src={logo} width={100} height={100} />
        </a>
      </div> */}
    </>
  );
};

export default Dashboard;
