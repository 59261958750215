import React, { useEffect } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import CustomHeader from "./CustomHeader";

const routeNameMap = {
  '/': { name: 'Home', parent: null },
  'profile': { name: 'Profile', parent: '/' },
  'addsubscriber': { name: 'Add Subscriber', parent: 'subscribers' },
  'subscribers': { name: 'Subscribers', parent: '/' },
  'subscriberhistory': { name: 'Subscriber History', parent: '/' },
  'billinghistory': { name: 'Billing History', parent: 'subscribers' },
  'groupAdminList': { name: 'Group Admin List', parent: 'subscribers' },
  'systemAdminList': { name: 'System Admin List', parent: '/' },
  'deactivateSubscriber': { name: 'Deactivate Subscriber', parent: 'subscribers' },
  'activateSubscriber': { name: 'Activate Subscriber', parent: 'subscribers' },
  'updateSubscriber': { name: 'Update Subscriber', parent: 'subscribers' },
  'paymentgateway': { name: 'Payment Gateway', parent: 'subscribers' },
  'changepassword': { name: 'Change Password', parent: 'profile' },
  'auditlist': { name: 'Audit List', parent: '/' },
  'zipcode': { name: 'Zip Code', parent: '' },
  'subscriber': { name: 'View Subscriber', parent: '' },
  'forgot': { name: 'Forgot Password', parent: '' },
  'forgetpassword': { name: 'Reset Password', parent: '' },
};

function CustomLayout({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const generateBreadcrumbs = () => {
    const pathname = location.pathname || ''; // Ensure pathname is defined
    const pathnames = pathname.split('/').filter((x) => x);
    const breadcrumbs = [];

    let currentPath = '';
    pathnames.forEach((pathname, index) => {
      const cleanPathname = pathname.split(':')[0]; // Remove dynamic segments
      currentPath += `/${pathname}`;
      const routeConfig = routeNameMap[cleanPathname];
      if (routeConfig) {
        breadcrumbs.push({
          path: currentPath,
          name: routeConfig.name,
        });
      }
    });

    // Add parent paths for each breadcrumb
    let completeBreadcrumbs = [];
    breadcrumbs.forEach((breadcrumb, index) => {
      let parentPath = routeNameMap[breadcrumb.path.slice(1)]?.parent;
      const pathTrail = [breadcrumb];
      while (parentPath) {
        const routeConfig = routeNameMap[parentPath];
        pathTrail.unshift({
          path: `/${parentPath}`,
          name: routeConfig.name,
        });
        parentPath = routeConfig.parent;
      }
      completeBreadcrumbs = completeBreadcrumbs.concat(pathTrail);
    });

    // Remove duplicate breadcrumbs
    const uniqueBreadcrumbs = Array.from(new Set(completeBreadcrumbs.map(JSON.stringify))).map(JSON.parse);

    return (
      <Breadcrumbs aria-label="breadcrumb" separator=">">
        {uniqueBreadcrumbs.map((breadcrumb, index) => {
          const last = index === uniqueBreadcrumbs.length - 1;
          return last ? (
            <Typography color="primary" key={breadcrumb.path}>
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link component={RouterLink} to={breadcrumb.path} key={breadcrumb.path} style={{ color: 'black' }}>
              {breadcrumb.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
  };

  return (
    <>
      <CustomHeader />
      <div className="p-4 sm:ml-64">
        <div className="p-4 mt-14">
          {generateBreadcrumbs()}
          {children}
        </div>
      </div>
    </>
  );
}

export default CustomLayout;
