import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Tooltip, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

function CustomRadioButton(props) {

  return (
    <div className="flex items-center md:w-1/2 w-full">
    <Controller
      name={props.fieldName}
      control={props.fieldControl}
      rules={{ required: props.requiredErrorMessage }}
      render={({ field, fieldState }) => (
        <FormControl fullWidth error={fieldState.invalid}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {<div><Typography style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>{props.labelName}{props.showAsterisk && <span className='text-red-500'>*</span>}</Typography></div>}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            {...field}
            value={props.value} // Set the initial value based on the prop
            onChange={(e) => {
              field.onChange(e);
              const selectedValue = e.target.value;
              props.onChange && props.onChange(selectedValue);
            }}
          >
            <FormControlLabel
              value={props.valueOne}
              control={<Radio />}
              label={<Typography style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>{props.labelOne}</Typography>}
            />
            <FormControlLabel
              value={props.valueTwo}
              control={<Radio />}
              label={<Typography style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>{props.labelTwo}</Typography>}
            />
          </RadioGroup>
          <FormHelperText style={{ color: "#D32F2F" }}>
            {fieldState.invalid ? fieldState.error?.message : ""}
          </FormHelperText>
        </FormControl>
      )}
    />
     {props.tooltipMessage && (
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>{props.tooltipMessage}</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
  );
}

export default CustomRadioButton;
