import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthToken } from "../redux/Hybridhoa";
import { Link, useNavigate } from "react-router-dom";
import { ExitToAppOutlined, PersonOutline } from "@mui/icons-material";
import logo from "../assets/img/logo.png";
import SideMenu from "./sidenav/SideMenu";

const CustomHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { userDetails } = useSelector((state) => state.hybridhoa);
  const sidebarRef = useRef();

  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfile = () => {
    setIsDropdownOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleBackdropClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 640) { // Small screen condition
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside of dropdown
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    // Clear token from local storage

    localStorage.removeItem("token");

    // Dispatch an action to clear token in Redux state
    dispatch(clearAuthToken());
    setIsDropdownOpen(false);

    navigate("/");
    // Redirect to the login page (you can use react-router-dom useHistory for this)
    // history.push("/login");
  };
  const yourText = userDetails.firstName;

  return (
    <>
      <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-100  drop-shadow-sm h-14">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start rtl:justify-end">
              <button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              >
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="https://hybirdhoa.com/" class="flex ms-2 md:me-24">
                <img src={logo} class="h-11 me-3" alt="HybridHOA Logo" />
              </a>
            </div>

            <div className="flex items-center">
              <div className="relative" ref={dropdownRef}>
                <button
                  type="button"
                  className="flex text-sm bg-gray-800 rounded-full   relative"
                  aria-expanded={isDropdownOpen}
                  onClick={toggleDropdown}
                >
                  <span className="sr-only">Open user menu</span>
                  <div className="w-10 h-10 -translate-x-14 -translate-y-6 bg-primary rounded-full absolute flex items-center justify-center">
                    <span className="text-white font-bold text-lg">
                      {yourText.slice(0, 1).toUpperCase()}
                    </span>
                  </div>
                </button>

                {isDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 rounded-md bg-white divide-y divide-gray-100 shadow-lg "
                    id="dropdown-user"
                  >
                    <ul className="py-1" role="none">
                      <li>
                        <Link
                          to="/profile"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={handleProfile}
                        >
                          <PersonOutline color="primary" /> Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={handleLogout}
                        >
                          <ExitToAppOutlined color="primary" /> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-100 sm:translate-x-0 shadow-lg`}
        aria-label="Sidebar"
        ref={sidebarRef}
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white text-red-500">
          <SideMenu onMenuItemClick={handleMenuItemClick} />
        </div>
      </aside>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={handleBackdropClick}
        />
      )}
    </>
  );
};

export default CustomHeader;
