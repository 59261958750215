import {
  AccountBalanceOutlined,
  CreditCardOutlined,
  EditNoteOutlined,
  ListAltOutlined,
  PersonAddDisabledOutlined,
  PersonAddOutlined,
} from "@mui/icons-material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CustomStyle } from "../../components/CustomStyle";
import {
  ACTIVE_GROUP,
  BASE_URL,
  DEACTIVE_GROUP,
  GROUP,
  PAYMENT_DETAILS,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  DEACTIVATE_REQUIRED_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REMARKS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import CustomInputField from "../../components/CustomInputField";
import dayjs from "dayjs";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";

function SubscriberList() {
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [groupDetails, setGroupDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [adminId, setAdminId] = useState("");
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );

  const [selectedRow, setSelectedRow] = useState("");

  const [endDate, setEndDate] = React.useState("");

  const navigate = useNavigate();
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);

  const handleViewClick = (selectedRow) => {
    navigate(`/groupAdminList/${selectedRow.original.group.groupId}`);
    console.log(selectedRow.original.group.groupId);
  };
  const handleViewSubscriberClick = (selectedRow) => {
    navigate(`/subscriber/${selectedRow.row.original.group.groupId}`);
    console.log(selectedRow.row.original.group.groupId);
  };
  const handleEditClick = (selectedRow) => {
    navigate(`/updateSubscriber/${selectedRow.row.original.group.groupId}`);
    console.log(selectedRow.row.original.group.groupId);
  };
  const handleDeactiveClick = (selectedRow) => {
    navigate(`/deactivateSubscriber/${selectedRow.original.group.groupId}`);
    console.log(selectedRow.original.group.groupId);
  };
  const handlePaymentDetailClick = (selectedRow) => {
    navigate(`/paymentgateway/${selectedRow.original.group.groupId}`);
    console.log(selectedRow.original.group.groupId);
  };

  const handleAddClickOpen = () => {
    navigate(`/addsubscriber`);
  };

  // const handleDeactiveClick = (selectedRow) => {
  //   // navigate("/deactivateSubscriber");
  //   console.log(selectedRow.group.groupId);
  //   setSelectedRow(selectedRow.group.groupId);
  //   setIsDeactivateModalOpen(true);
  //   getGroupDataById();
  // };

  const handleDeactivateModalClose = () => {
    setIsDeactivateModalOpen(false); // Close the deactivate modal
  };

  const handleActiveClick = (selectedRow) => {
    // navigate("/activateSubscriber");
    console.log(selectedRow.group.groupId);
    setSelectedRow(selectedRow.group.groupId);
    setIsActivateModalOpen(true);
    getAllGroupDetails();
  };

  const handleActivateModalClose = () => {
    setIsActivateModalOpen(false); // Close the deactivate modal
  };

  const handleBillingHistory = (selectedRow) => {
    navigate(`/billinghistory/${selectedRow.original.group.groupId}`);
  };
  // const handleBillingHistory = (selectedRow) =>{
  //   navigate(`/billinghistory/${selectedRow.original.group.groupId}`)
  // };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const getAllGroupDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setGroupDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllGroupDetails();
  }, []);

  const subscriberListTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },

    {
      accessorKey: "groupName",
      header: "Group Name",

      // accessorFn: (row) => `${row.group.groupName}`,
      accessorFn: (row) => {
        const groupName = row.group.groupName?row.group.groupName:"";
        if (groupName && groupName.length > 28) {
          return groupName.slice(0, 28) + "...";
        }
        return groupName;
      },
      sortDescFirst: true,
    },

    {
      accessorKey: "address",
      header: "Address",

      // accessorFn: (row) => `${row.groupDetails.address}`,
      accessorFn: (row) => {
        const address = row.groupDetails.address?row.groupDetails.address:"";
        if (address && address.length > 28) {
          return address.slice(0, 28) + "...";
        }
        return address;
      },
    },
    {
      accessorKey: "firstName",
      header: "First Name",

      // accessorFn: (row) =>
        // `${row.groupAdminDetails ? row.groupAdminDetails.firstName : ""} `,
      accessorFn: (row) => {
        const firstName = row.groupAdminDetails?row.groupAdminDetails.firstName:"";
        if (firstName && firstName.length > 28) {
          return firstName.slice(0, 28) + "...";
        }
        return firstName;
      },
    },
    
    {
      accessorKey: "lastName",
      header: "Last Name",

      // accessorFn: (row) =>
      //   `${row.groupAdminDetails ? row.groupAdminDetails.lastName : ""} `,
      accessorFn: (row) => {
        const lastName = row.groupAdminDetails?row.groupAdminDetails.lastName:"";
        if (lastName && lastName.length > 28) {
          return lastName.slice(0, 28) + "...";
        }
        return lastName;
      },
    },
    {
      accessorKey: "contact",
      header: "Contact",

      accessorFn: (row) =>
        `${row.groupDetails ? row.groupDetails.contact : ""} `,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 20,
      accessorFn: (row) =>
        `${row.groupAdminLogin ? row.groupAdminLogin.email : ""} `,
      // accessorFn: (row) => {
      //   const email = row.groupAdminDetails.email?row.groupAdminDetails.email:"";
      //   if (email && email.length > 28) {
      //     return email.slice(0, 28) + "...";
      //   }
      //   return email;
      // },
    },
    {
      accessorKey: "ein",
      header: "Entity Identity Number",

      accessorFn: (row) => `${row.groupDetails.ein}`,
    },
    {
      accessorKey: "active",
      size: 20,
      accessorFn: (row) => `${row.group.active ? "Active" : "Inactive"}`,
      header: "Status",
    },
  ]);

  const rowActions = (row) => {
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAltOutlined color="primary" />,
      //   click: () => handleViewSubscriberClick(row),
      // },
      // {
      //   label: "View Admins",
      //   icon: <ListAltOutlined color="primary" />,
      //   click: () => handleViewClick(row),
      // },

      {
        label: "Payment Details",
        icon: <AccountBalanceOutlined color="primary" />,
        click: () => handlePaymentDetailClick(row),
      },
      {
        label: "Billing History",
        icon: <CreditCardOutlined color="primary" />,
        click: () => handleBillingHistory(row),
      },
    ];
    if (!row.original.group.active) {
      actions.push({
        label: "Activate",
        icon: <PersonAddOutlined color="primary" />,
        click: () => handleActiveClick(row.original),
      });
    }
    if (row.original.group.active) {
      actions.push({
        label: "Deactivate",
        icon: <PersonAddDisabledOutlined color="primary" />,
        click: () => handleDeactiveClick(row),
      });
    }

    return actions;
  };

  // const generateOptions = () => {
  //   const options = [];
  //   const currentDate = new Date();
  //   const endDateDate = new Date("2024-03-04"); // Provided end date
  //   console.log("endDateDate",endDateDate);
  //   while (currentDate <= endDateDate) {
  //     const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  //     options.push(
  //       <MenuItem key={lastDayOfMonth.toISOString()} value={lastDayOfMonth.toISOString()}>
  //         {lastDayOfMonth.toLocaleDateString()}
  //       </MenuItem>
  //     );
  //     currentDate.setMonth(currentDate.getMonth() + 1);
  //   }
  //   return options;
  // };
  const generateOptions = () => {
    const options = [];
    const currentDate = new Date();
    const endDates = new Date(endDate); // provided end date
    console.log("endDate", endDates);
    // Check if endDate is valid and currentDate is less than or equal to endDate
    if (endDates && currentDate <= endDates) {
      while (currentDate <= endDates) {
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        options.push(
          <MenuItem
            key={lastDayOfMonth.toISOString()}
            value={lastDayOfMonth.toISOString()}
          >
            {lastDayOfMonth.toLocaleDateString()}
          </MenuItem>
        );
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      // Add one more month
    } else {
      console.log("Invalid endDate or currentDate exceeds endDate");
    }
    return options;
  };

  const getGroupDataById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP}/${selectedRow}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        const ends = data.groupSubscriptionDetails.endDate;
        setEndDate(ends);
        console.log(dayjs(ends).format("YYYY-MM-DD"));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getGroupDataById();
  }, [selectedRow]);

  const [remarks, setRemarks] = React.useState("");
  const [deactivateDate, setDeactivateDate] = React.useState("");
  const { groupId } = useParams();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const payload = {
        remarks: data.remarks,
        deactivateDate: data.deactivateDate,
      };

      const response = await axios.post(
        `${BASE_URL}${DEACTIVE_GROUP}/${selectedRow}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        setIsLoading(false);
        const deactivatedData = response.data;

        setRemarks(deactivatedData.remarks);
        setDeactivateDate(deactivatedData.deactivateDate);

        setGroupDetails((prevState) => {
          return prevState.map((group) => {
            if (group.groupId === selectedRow) {
              return {
                ...group,
                remarks: deactivatedData.remarks,
                deactivateDate: deactivatedData.deactivateDate,
              };
            }
            return group;
          });
        });

        console.log("Data updated successfully");
        toast.success(response.data);
        reset();
        // Assuming CustomToastContainer is a component to display a success message
        // Render it appropriately in your UI
        // <CustomToastContainer position="center" message="Subscriber deactivated successfully" />
        setIsDeactivateModalOpen(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Failed to deactivate subscriber");
      setIsLoading(false);
      // Optionally, you can display an error message to the user
    }
  };

  const deactivateModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      <div>
        <CustomToastContainer />

        <DialogContent dividers>
          <div style={{ marginBottom: "22px" }}>
            <Controller
              name="deactivateDate"
              control={control}
              rules={{ required: "Please Select Deactivate Date" }}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Please Select Deactivate Date
                    <span style={{ color: "red", gap: "2px" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label="Please Select Deactivate Date"
                  >
                    {generateOptions()}
                  </Select>
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <CustomInputField
              fieldName="remarks"
              requiredErrorMessage={REMARKS_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              fieldId="remarks"
              fieldType="text"
              fieldLabel="Remarks"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={1}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <CustomButton fieldType="submit" buttonName="Update" />
        </DialogActions>
      </div>
    </form>
  );

  const onActivateSubmit = async (data) => {
    try {
      setIsLoading(true);
      const storedToken = token;
      console.log("storedToken", storedToken);
      const response = await axios.get(
        `${BASE_URL}${ACTIVE_GROUP}/${selectedRow}`, // Use the selectedRowGroupId to construct the URL

        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        const activatedData = response.data;
        getAllGroupDetails();
        setIsLoading(false);
        // Update the remarks and deactivateDate state variables
        // setRemarks(deactivatedData.remarks);
        // setDeactivateDate(deactivatedData.deactivateDate);

        // Update the groupDetails state with the updated data

        // Handle success response
        toast.success(response.data);
        // Close the deactivate modal after successful update

        setIsActivateModalOpen(false); // Close the modal here
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to update data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error if the request fails
      console.error("Error updating data:", error);
      toast.error("Failed to activate subscriber");
      setIsLoading(false);

      // Optionally, you can display an error message to the user
    }
  };

  const activateModel = (
    <form
      onSubmit={handleSubmit(onActivateSubmit)}
      className="space-y-4 md:space-y-6"
    >
      <div>
        <DialogContent dividers>
          <Typography>
            Are you sure want to activate your subscription?
          </Typography>
        </DialogContent>
        {/* <DialogActions>{activateButtons}</DialogActions> */}
        <DialogActions>
          <a className="flex justify-end  space-x-2">
            <CustomButton
              fieldType="button"
              buttonName="Cancel"
              click={handleActivateModalClose} // Assuming you have a function to handle cancel action
            />
            <CustomButton fieldType="submit" buttonName="Activate" />
          </a>
        </DialogActions>
      </div>
    </form>
  );
  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Subscribers" />
      <CustomToastContainer />

      <CustomMaterialTable
        columns={subscriberListTable}
        data={groupDetails}
        rowActions={rowActions}
        isLoading={isLoading}
        onRowClick={handleEditClick}
        actionButtons={tableActionButtons}
      />
      <CustomModel
        title="Deactivate"
        openStatus={isDeactivateModalOpen}
        closeStatus={handleDeactivateModalClose}
        iconCloseStatus={handleDeactivateModalClose}
        content={deactivateModel}
        reset={reset}
      />
      <CustomModel
        title="Activate"
        openStatus={isActivateModalOpen}
        closeStatus={handleActivateModalClose}
        iconCloseStatus={handleActivateModalClose}
        content={activateModel}
        reset={reset}
      />
    </div>
  );
}

export default SubscriberList;
