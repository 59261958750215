import React from "react";
import SideMenuItem from "./SideMenuItem";
import SideMenuDropdown from "./SideMenuDropdown";
import {
  AccountBoxOutlined,
  DashboardCustomizeOutlined,
  ExposureOutlined,
  FiberPinOutlined,
  FolderSharedOutlined,
  FormatListBulletedOutlined,
  GroupAddOutlined,
  PeopleAltOutlined,NotificationsActiveOutlined,
} from "@mui/icons-material";
import "./SideNavStyle.css";
import IdleTimeout from "../../screens/idletimeout/IdleTimeout";
import { useSelector } from "react-redux";

const SideMenu = ({ onMenuItemClick }) => {
  const { token, roles } = useSelector((state) => state.hybridhoa);
  const menuItemdata = [
    {
      navLink: "/",
      name: "Dashboard",
      icon: <DashboardCustomizeOutlined />,
    },

    {
      navLink: "/subscribers",
      name: "Subscribers List",
      icon: <FormatListBulletedOutlined />,
    },

    {
      navLink: "/auditlist",
      name: "Audit",
      icon: <ExposureOutlined />,
    },
    {
      navLink: "/subscriberhistory",
      name: "Subscribers History",
      icon: <FolderSharedOutlined />,
    },
    // {
    //   navLink: "/zipcode",
    //   name: "ZipCode",
    //   icon: <FiberPinOutlined />,
    // },

    {
      navLink: "/systemAdminList",
      name: "System Admin",
      icon: <AccountBoxOutlined />,
    },
    {
      navLink: "/notifications",
      name: "Notification",
      icon: <NotificationsActiveOutlined />,
    },
  ];

  return (
    <>
    <ul className="space-y-1 font-medium ">
      {menuItemdata.map((item) =>
        !item.isNested ? (
          <SideMenuItem
            key={item.navLink}
            navigationLink={item.navLink}
            itemName={item.name}
            icon={item.icon}
            onClick={onMenuItemClick}
          />
        ) : (
          <SideMenuDropdown
            key={item.navLink}
            childItem={item.childItem}
            id={item.id}
            parrentName={item.name}
            icon={item.icon}
            onClick={onMenuItemClick}
          />
        )
      )}
    </ul>
    </>
  );
};

export default SideMenu;
