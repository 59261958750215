import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./redux/Hybridhoa"; 
import LoginPage from './screens/login/LoginPage';
import CustomLayout from './components/CustomLayout';
import Dashboard from "./screens/dashboard/Dashboard";
import Profile from './screens/profile/Profile';
import AddSubscriber from "./screens/subscriber/AddSubscriber";
import ZipCode from "./screens/zipcode/ZipCode";
import SubscriberList from "./screens/subscriber/SubscriberList";
import SubscriberHistory from "./screens/subscriberhistory/SubscriberHistory";
import BillingHistory from "./screens/subscriber/BillingHistory";
import AuditList from "./screens/audit/AuditList";
import GroupAdmin from "./screens/groupadmin/GroupAdmin";
import SystemAdmin from "./screens/systemadmin/SystemAdmin";
import DeactivateSubscriber from "./screens/subscriber/DeactivateSubscriber";
import ActiveSubscriber from "./screens/subscriber/ActiveSubscriber";
import UpdateSubscriber from "./screens/subscriber/UpdateSubscriber";
import ChangePassword from "./screens/changepassword/ChangePassword";
import IdleTimeout from "./screens/idletimeout/IdleTimeout";
import ForgotChange from "./screens/forgotpage/ForgotChange";
import Forgot from "./screens/forgotpage/Forgot";
import ViewSubscriber from "./screens/subscriber/ViewSubscriber"

import PaymentGateway from "./screens/subscriber/PaymentGateway";
// Added By Hema shanker on 22/07/2024 start
import SystemAdminAdd from "./screens/systemadmin/SystemAdminAdd";
import SystemAdminUpdate from "./screens/systemadmin/SystemAdminUpdate";
import NotificationAdd from "./screens/notification/AddNotification"
import NotificationList from "./screens/notification/NotificationList"
import UpdateNotification from "./screens/notification/UpdateNotification"
// Added By Hema shanker on 22/07/2024 end
function App() {
  
  const { token } = useSelector((state) => state.hybridhoa);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (token == null && storedToken) {
      // Set the token from local storage to Redux state
      dispatch(setToken(storedToken));
    }

    // if (!token) {
    //   // If no token exists, redirect to login
    //   navigate("/");
    // }
    // else{
    //   navigate('/dashboard')
    // }
  }, [token, dispatch, navigate]);

  return (

    <>
    
      {token == null && (
        <Routes>
          <Route path="/" element={<LoginPage/>} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/forgetpassword/:tokenId" element={<ForgotChange/>}  />
        </Routes>
      )}

{token != null && ( 
  
  <>
   <IdleTimeout />
   <CustomLayout>
    <Routes>
      <Route path="/" element = {<Dashboard/>}/>
      <Route path="/profile" element = {<Profile/>} />
      <Route path="/addsubscriber" element = {<AddSubscriber />} />
      <Route path="/subscribers" element = {<SubscriberList />} />
      <Route path="/subscriberhistory" element = {<SubscriberHistory />} />
      <Route path="/billinghistory/:groupId" element = {<BillingHistory />} />
      <Route path="/groupAdminList/:groupId" element = {<GroupAdmin />} />
      <Route path="/systemAdminList" element = {<SystemAdmin />} />
      <Route path="/deactivateSubscriber/:groupId" element = {<DeactivateSubscriber />} />
      <Route path="/activateSubscriber" element = {<ActiveSubscriber />} />
      <Route path="/updateSubscriber/:groupId" element = {<UpdateSubscriber />} />
      <Route path="paymentgateway/:groupId" element = {<PaymentGateway />} />
      <Route path="/changepassword" element = {<ChangePassword />} />

      <Route path="/auditlist" element = {<AuditList />} />
      <Route path="/zipcode" element = {<ZipCode />} />

      <Route path="/subscriber/:groupId" element={<ViewSubscriber/>}/>
      {/* Added By Hema shanker on 22/07/2024 start */}
      <Route path="/systemAdminAdd" element = {<SystemAdminAdd />} />
      <Route path="/systemAdminUpdate/:adminId" element = {<SystemAdminUpdate />} />


      <Route path="/notificationUpdate/:customerNotificationId" element = {<UpdateNotification />} />
      <Route path="/notifications" element = {<NotificationList />} />
      <Route path="/notificationAdd" element = {<NotificationAdd />} />
      {/* Added By Hema shanker on 22/07/2024 end */}

    </Routes>
  </CustomLayout>
  </>

)}

     
    </>
  );
}

export default App;
