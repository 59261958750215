import React, { useState } from 'react'
import CustomHeading from '../../components/CustomHeading'
import CustomButton from '../../components/CustomButton'
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ADDRESS_INVALID_MESSAGE, ADDRESS_REQUIRED_MESSAGE, CONTACT_REQUIRED_MESSAGE, COUNTRY_INVALID_MESSAGE, EMAIL_INVALID_MESSAGE, ENTITY_NUMBER_INVALID_MESSAGE, ENTITY_NUMBER_REQUIRED_MESSAGE, FORMATION_DATE_REQUIRED_MESSAGE, GROUP_NAME_INVALID_MESSAGE, GROUP_NAME_REQUIRED_MESSAGE, HOUSE_COUNT_INVALID_MESSAGE, HOUSE_COUNT_REQUIERD_MESSAGE, MAX_LENGTH_FIFTY, MAX_LENGTH_FIVE_HUNDRED, MAX_LENGTH_FOUR, MAX_LENGTH_ONE_TWENTY_MESSAGE, MAX_LENGTH_THIRTY_MESSAGE, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_ONE, MIN_LENGTH_TEN, MIN_LENGTH_VALIDATE_MESSAGE, PHONE_REQUIRED_MESSAGE, SUBSCRIPTION_PLAN_TERM_REQUIRED_MESSAGE, SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE, SUBSCRIPTION_START_DATE_REQUIRED_MESSAGE, ZIPCODE_INVALID_MESSAGE, ZIPCODE_MAX_LENGTH_MESSAGE, ZIPCODE_MIN_LENGTH_MESSAGE, ZIPCODE_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { EMAIL_REGEX_PATTERN, ONLY_ALPHABETIC, ONLY_ALPHANUMBERIC, ONLY_DIGITS, ONLY_DIGITS_HYPEN, START_ALPHANUMERIC_ALL_SPECIAL_CHARACTERS } from '../../utills/ApplicationRegex';
import CustomInputField from './../../components/CustomInputField';
import CustomDatePicker from './../../components/CustomDatePicker';
import CustomRadioButton from './../../components/CustomRadioButton';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import dayjs from 'dayjs';
import { Card, CardContent, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import CustomPhoneInput from '../../components/CustomPhoneInput';
function ActiveSubscriber() {
    const [dueType, setDueType] = useState("");
    const [formationDate, setFormationDate] = useState("");
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(-1);
    };
   
    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },clearErrors,
    } = useForm();

    const handleToDateChange = (selectedEndDate) => {
      setFormationDate(selectedEndDate);
    };
  
    const onSubmit = (data) => {
      console.log(data.groupName);
      console.log(data.entityNumber);
      console.log(data.address);
      console.log(data.phoneNumber)
      console.log(dayjs(formationDate).format("YYYY-MM-DD"))

    };
  
    const handleDueChange = (event) => {
      setDueType(event.target.value);
      console.log(event.target.value + "+++++++++++++++");
    };
    const minDate = dayjs().subtract(100, "year").startOf("day");
    const maxDate = dayjs().endOf("day");
  return (
    <div>
          <div class="flex flex-col md:flex-row">
        <CustomHeading title="Active Subscriber" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
              <CustomInputField
                requiredErrorMessage={GROUP_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHANUMBERIC}
                patternErrorMessage={GROUP_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={500}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                fieldName="groupName"
                fieldId="groupName"
                fieldType="text"
                fieldLabel="Group Name(HOA Name)"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="groupRegNo"
                fieldId="groupRegNo"
                fieldType="text"
                fieldLabel="Group Registration Number"
                disabled={true}
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                defaultValue={"GR_202312124580"}
              />

              <CustomInputField
                requiredErrorMessage={ENTITY_NUMBER_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS_HYPEN}
                patternErrorMessage={ENTITY_NUMBER_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={500}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                fieldName="entityNumber"
                fieldId="entityNumber"
                fieldType="text"
                fieldLabel="Entity Identity Number"
                placeholder={"000-000-000"}
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <CustomDatePicker
                fieldName="formationDate"
                label="Formation Date"
                fieldRequiredIcon="*"
                control={control}
                requiredErrorMessage={FORMATION_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleToDateChange}
              />
              <CustomInputField
                requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                fieldPattern={START_ALPHANUMERIC_ALL_SPECIAL_CHARACTERS}
                patternErrorMessage={ADDRESS_INVALID_MESSAGE}
                minLength={10}
                minLengthErrorMessage={MIN_LENGTH_TEN}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldName="address"
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="country"
                fieldId="country"
                fieldType="text"
                fieldLabel="Country"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={COUNTRY_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={50}
                maxLengthErrorMessage={MAX_LENGTH_FIFTY}
                fieldControl={control}
                fieldError={errors}
              />

              <CustomInputField
                requiredErrorMessage={ZIPCODE_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldName="zipcode"
                fieldId="zipcode"
                fieldType="text"
                fieldLabel="ZipCode"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                requiredErrorMessage={HOUSE_COUNT_REQUIERD_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={HOUSE_COUNT_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_ONE}
                maxLength={4}
                maxLengthErrorMessage={MAX_LENGTH_FOUR}
                fieldName="houseCount"
                fieldId="houseCount"
                fieldType="text"
                fieldLabel="House Count"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <Controller
  name="phoneNumber"
  control={control}
  rules={{ required: PHONE_REQUIRED_MESSAGE,
    validate: {
      isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
    } }}
  render={({ field, fieldState }) => (
    <>
      <CustomPhoneInput
        fieldControl={control}
        label="Phone Number"
        clearErrors={clearErrors}
        country="US"
        value={field.value}
        onChange={(value) => {
          field.onChange(value);
        }}
        error={fieldState.invalid ? fieldState.error : null}
        helperText={fieldState.invalid ? fieldState.error?.message : ''}
      />

  

    </>
  )}
/>
            </section>

            <br />

            {/* <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: CONTACT_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    error={fieldState.invalid}
                    country={"us"}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </>
              )}
            /> */}
          
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
             

              <Controller
                name="subscriptionPlanTerm"
                control={control}
                rules={{ required: SUBSCRIPTION_PLAN_TERM_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please Select Subscription Plan Term
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleDueChange(e);
                      }}
                      label=" Please Select Subscription Plan Term"
                    >
                      <MenuItem value="yearly">Yearly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="subscriptionPlanType"
                control={control}
                rules={{ required: SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please Select Subscription Plan Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleDueChange(e);
                      }}
                      label=" Please Select Subscription Plan Type"
                    >
                      <MenuItem value="free">Free</MenuItem>
                      <MenuItem value="basic">Basic</MenuItem>
                      <MenuItem value="extended">Extended</MenuItem>
                      <MenuItem value="premium">Premium</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <CustomInputField
                fieldName="pricing"
                disabled={true}
                fieldId="pricing"
                fieldType="text"
                fieldLabel="Pricing"
                fieldControl={control}
                fieldError={errors}
              />

              <CustomInputField
                fieldName="totalSubscriptionAmount"
                disabled={true}
                fieldId="totalSubscriptionAmount"
                fieldType="text"
                fieldLabel="Total Subscription Amount"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <CustomInputField
                fieldName="additionalPrice"
                disabled={true}
                fieldId="additionalPrice"
                fieldType="text"
                fieldLabel="Additional Price / unit(Applicable only above 235)"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="totalCost"
                disabled={true}
                fieldId="totalCost"
                fieldType="text"
                fieldLabel="Total Cost / House"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <CustomRadioButton
                fieldName="specialDiscountType"
                fieldControl={control}
                fieldError={errors}
                labelName="Special Discount Type"
                valueOne="percentage "
                labelOne="Percentage"
                valueTwo="dollar"
                labelTwo="Dollar"
              />
              <CustomInputField
                fieldName="specialDiscount"
                disabled={true}
                fieldId="specialDiscount"
                fieldType="text"
                fieldLabel="Special Discount"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <CustomDatePicker
                fieldName="subscriptionStartDate"
                label="Subscription Start Date"
                requiredErrorMessage={SUBSCRIPTION_START_DATE_REQUIRED_MESSAGE}
                control={control}
                minDate={minDate}
              />
              <CustomDatePicker
                fieldName="subscriptionEndDate"
                label="Subscription End Date"
                disabled={true}
                control={control}
                minDate={minDate}
              />

<CustomInputField
                fieldName="publishkey"
                fieldId="publishkey"
                fieldType="text"
                fieldLabel="Publish key"
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={120}
                maxLengthErrorMessage={MAX_LENGTH_ONE_TWENTY_MESSAGE}
                fieldControl={control}
                fieldError={errors}
              />

<CustomInputField
                fieldName="secretkey"
                fieldId="secretkey"
                fieldType="text"
                fieldLabel="Secret key"
                fieldControl={control}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={120}
                maxLengthErrorMessage={MAX_LENGTH_ONE_TWENTY_MESSAGE}
                fieldError={errors}
              />

<CustomInputField
                fieldName="accountId"
               
                fieldId="accountId"
                fieldType="text"
                fieldLabel="Account Id"
                fieldControl={control}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={30}
                maxLengthErrorMessage={MAX_LENGTH_THIRTY_MESSAGE}
                fieldError={errors}
              />
              <CustomInputField
                fieldName="purchasesMail"
                fieldId="purchasesMail"
                fieldType="text"
                fieldLabel="Purchases Mail"
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={errors}
              />
              <CustomInputField
                fieldName="salesMail"
                fieldId="salesMail"
                fieldType="text"
                fieldLabel="Sales Mail"
                fieldControl={control}
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldError={errors}
              />
                <CustomInputField
                fieldName="expensesMail"
               fieldId="expensesMail"
                fieldType="text"
                fieldLabel="Expenses Mail"
                fieldControl={control}
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldError={errors}
              />
                <CustomInputField
                fieldName="suppliersMail"
                fieldId="suppliersMail"
                fieldType="text"
                fieldLabel="Suppliers Mail"
                fieldControl={control}
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldError={errors}
              />
            </section>

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Activate" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default ActiveSubscriber