import {
    Close,
    ContentCopy,
    Delete,
    DeleteForever,
    FileDownload,
    ListAlt,
    Telegram,
  } from "@mui/icons-material";
  import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField,
    Typography,
  } from "@mui/material";
  import { download, generateCsv, mkConfig } from "export-to-csv";
  import {
    MaterialReactTable,
    useMaterialReactTable,
  } from "material-react-table";
  import React, { useEffect, useMemo, useState } from "react";
  import { useNavigate } from "react-router-dom";

function CustomMaterialWithoutRoutes(props) {
    const navigate = useNavigate();
    const actions = props.rowActions;
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 5,
    });
  
    return (
      <MaterialReactTable
        columns={props.columns}
        data={props.data}
        enableColumnOrdering
         positionActionsColumn="last"
        enableSorting={false}
        onPaginationChange={setPagination}
        state={{
          isLoading: props.isLoading,
          pagination,
        }}
      
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "semi-bold",
            fontSize: 14,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: 14,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        enableColumnPinning
        renderRowActionMenuItems={({ row }) => {
          const renderActions = Array.isArray(actions)
            ? actions.map((i) => (
                <MenuItem key={i.label} onClick={() => i.click(row)}>
                  {i.icon}
                  <Typography sx={{ pl: 1 }}> {i.label}</Typography>
                </MenuItem>
              ))
            : actions(row).map((i) => (
                <MenuItem key={i.label} onClick={i.click}>
                  {i.icon}
                  <Typography sx={{ pl: 1 }}>{i.label}</Typography>
                </MenuItem>
              ));
  
              return renderActions.length > 0 ? renderActions : (
                <MenuItem disabled>
                  <Typography sx={{ pl: 1 }}>N/A</Typography>
                </MenuItem>
              );
        }}
        renderTopToolbarCustomActions={() => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              {props.actionButtons}
            </Box>
          </>
        )}
      />
    );
  };

export default CustomMaterialWithoutRoutes