// Author - Hema shanker on 22/07/2024
import {
    DeleteOutline,
    EditNoteOutlined,
    PersonAdd,
    PersonAddDisabledOutlined,
    VpnKeyOutlined,
  } from "@mui/icons-material";
  
  import React, { useEffect, useMemo, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import CustomHeading from "../../components/CustomHeading";
  import CustomMaterialTable from "../../components/CustomMaterialTable";
  import CustomButton from "../../components/CustomButton";
  import CustomModel from "../../components/CustomModel";
  import { Controller, useForm } from "react-hook-form";
  import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
  import {
    DialogActions,
    DialogContent,
    FormHelperText,
    Typography,
  } from "@mui/material";
  import {
    COMMON_MESSAGE,
    CONTACT_REQUIRED_MESSAGE,
    EMAIL_INVALID_MESSAGE,
    EMAIL_REQUIRED_MESSAGE,
    FIRST_NAME_INVALID_MESSAGE,
    FIRST_NAME_REQUIRED_MESSAGE,
    LAST_NAME_INVALID_MESSAGE,
    LAST_NAME_REQUIRED_MESSAGE,
    MAX_LENGTH_VALIDATE_MESSAGE,
    MIN_LENGTH_EIGHT,
    MIN_LENGTH_LASTNAME_MESSAGE,
    MIN_LENGTH_VALIDATE_MESSAGE,
    OTP_INVALID_MESSAGE,
    OTP_REQUIRED_MESSAGE,
    PHONE_REQUIRED_MESSAGE,
    RESET_PASSWORD_INVALID_MESSAGE,
  } from "../../utills/ApplicationConstants";
  import {
    EMAIL_REGEX_PATTERN,
    GROUP_ADMIN_EMAIL_REGEX_PATTERN,
    ONLY_ALPHABETIC,
    ONLY_DIGITS,
    PASSWORD_REGEX,
  } from "../../utills/ApplicationRegex";
  import CustomInputField from "../../components/CustomInputField";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/material.css";
  import {
    ACTIVE_SYSTEM_ADMIN,
    ADD_SYSTEAM_ADMIN,
    BASE_URL,
    DEACTIVE_SYSTEM_ADMIN,
    DELETE_SYSTEM_ADMIN,
    GET_ALL_SYSTEM_ADMIN,
    GET_SYSTEM_ADMIN_BY_ID,
    SYSTEM_ADMIN_RESET_PASSWORD,
    UPDATE_SYSTEM_ADMIN,
    GET_SYSTEM_ADMIN,
  } from "../../utills/ApplicationRouting";
  import axios from "axios";
  import { useSelector } from "react-redux";
  import { request } from "../../services/AxiosConfig";
  import CustomLoading from "../../components/CustomLoading";
  import CustomToastContainer from "../../components/CustomToastContainer";
  import CustomPhoneInput from "../../components/CustomPhoneInput";
  import { isValidPhoneNumber } from "react-phone-number-input";
  import {
    Card,
    CardContent,
    TextField,
    InputAdornment,
    Popover,
    Tooltip,
  } from "@mui/material";
  import { Info } from "@mui/icons-material";
  import CustomMaterialTableActions from "../../components/CustomMaterialTableActions";
  import CustomMaterialTableWithOutActions from "../../components/CustomMaterialWithoutActons";
  
  function SystemAdminAdd() {
    const navigate = useNavigate();
    const [addOpen, setAddOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [resetPasswordOpen, setResetPasswordOtp] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [activeConfirmOpen, setActiveConfirmOpen] = useState(false);
    const [deactiveConfirmOpen, setDeactiveConfirmOpen] = useState(false);
    const { token, userLoginDetails, roles } = useSelector(
      (state) => state.hybridhoa
    );
    const [admindetails, setAdminDetails] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRows, setSelectedRow] = useState(null);
    const [selectedRowFirstName, setSelectedRowFirstName] = useState(null);
    const [selectedRowLastName, setSelectedRowLastName] = useState(null);
    const [updateRes, setUpdateRes] = useState([]);
    const selectedRow = updateRes;
    const [firstNameData, setFirstNameData] = useState("");
    const [lastNameData, setLastNameData] = useState("");
    const [emailData, setEmailData] = useState("");
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [password, setPassword] = useState("");
  
    //  Add Start
  
    const handleAddClickOpen = () => {
      setAddOpen(true);
    };
  
    const handleAddClose = (status) => {
      if (status == 200) {
        setAddOpen(false);
      }
    };
    const handleAddIconClose = () => {
      setAddOpen(false);
    };
    const handleEditFirstName = (e) => {
      setFirstNameData(e.target.value);
    };
    const handleEditLastName = (e) => {
      setLastNameData(e.target.value);
    };
    const handleEditEmail = (e) => {
      setEmailData(e.target.value);
    };
    const handleViewClick = (selectedRow) => {
        navigate(-1);
      };
  
    const onSubmitAdd = (data) => {
      const postData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: "Admin@123",
  
        contact: data.phoneNumber,
      };
      console.log(data);
      setIsLoading(true);
      request({ url: ADD_SYSTEAM_ADMIN, method: "post", data: postData })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log("Success");
  
            setIsLoading(false);
            reset();
            handleAddClose(res.status);
            // getAllSystemAdminDetails();
            setTimeout(() => {
                navigate(-1);
              }, 3000); 
          } else if (res.status != 200) {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.response);
          if (error.response) {
            setIsLoading(false);
            console.log("error.response.status" + error.response.status);
          }
        });
    };
  
    // add code end
  
    const getSystemAdminDetailsById = async (id) => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${GET_SYSTEM_ADMIN_BY_ID}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          console.log(response.data);
          setUpdateRes(data.adminId);
          setFirstNameData(data.firstName);
          setLastNameData(data.lastName);
          setEmailData(data.email);
          setValue("phoneNumber", data.contact);
          setIsLoading(false);
        } else {
          setIsLoading(false);
  
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setIsLoading(false);
  
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
    // password Reset Start
  
    const handleResetPasswordOpen = (selectedRow) => {
      setResetPasswordOtp(true);
      console.log("selectedRow:", selectedRow);
      console.log("selectedRow.id:", selectedRow.original.adminId);
      setSelectedRowId(selectedRow.original.adminId);
      console.log(selectedRow.adminId);
      getSystemAdminDetailsById(selectedRow.original.adminId);
    };
  
    const handleResetPasswordClose = (status) => {
      if (status == 200) {
        setResetPasswordOtp(false);
      }
    };
    const handleResetPasswordIconClose = () => {
      setResetPasswordOtp(false);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
    const onSubmitReset = (data) => {
      if (password === "" || password == null) {
        setError("resetpw", {
          type: "manual",
          message: COMMON_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      const postData = {
        password: password,
      };
      console.log(data);
      setIsLoading(true);
      request({
        url: `${SYSTEM_ADMIN_RESET_PASSWORD}/${selectedRow}`,
        method: "post",
        data: postData,
      })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log("Success");
  
            setIsLoading(false);
            handleResetPasswordClose(res.status);
            getAllSystemAdminDetails();
            setPassword("");
          } else if (res.status != 200) {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.response);
          if (error.response) {
            setIsLoading(false);
            console.log("error.response.status" + error.response.status);
          }
        });
    };
  
    // Password Reset End
  
    // delete code start
  
    const handleDeleteOpen = (selectedRow) => {
      setDeleteOpen(true);
      setSelectedRow(selectedRow);
      setSelectedRowFirstName(selectedRow.firstName);
      setSelectedRowLastName(selectedRow.lastName);
    };
  
    const handleDeleteClose = () => {
      setDeleteOpen(false);
    };
  
    const onSubmitDelete = () => {
      const AdminId = selectedRows.adminId;
      console.log(selectedRows.adminId);
      // return;
      setIsLoading(true);
  
      request({ url: `${DELETE_SYSTEM_ADMIN}/${AdminId}`, method: "delete" })
        .then((res) => {
          console.log(res);
          getAllSystemAdminDetails();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };
  
    // delete code end
  
    // update code start
    const handleUpdateOpen = (selectedRow) => {
      setUpdateOpen(true);
      console.log("selectedRow:", selectedRow);
      console.log("selectedRow.id:", selectedRow.original.adminId);
      setSelectedRowId(selectedRow.original.adminId);
      console.log(selectedRow.adminId);
      getSystemAdminDetailsById(selectedRow.original.adminId);
    };
  
    const handleUpdateClose = (status) => {
      if (status == 200) {
        setUpdateOpen(false);
      }
    };
    const handleUpdateIconClose = () => {
      setUpdateOpen(false);
    };
  
    const onSubmitUpdate = (data) => {
      const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);
  
      if (!isValidPhoneNumberForCountry) {
        setIsLoading(false);
        return;
      }
  
      if (firstNameData === "" || firstNameData == null) {
        setError("updatefirstName", {
          type: "manual",
          message: FIRST_NAME_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      if (lastNameData === "" || lastNameData == null) {
        setError("updatelastName", {
          type: "manual",
          message: LAST_NAME_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      if (emailData === "" || emailData == null) {
        setError("updateemail", {
          type: "manual",
          message: EMAIL_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      const putData = {
        firstName: firstNameData.trim(),
        lastName: lastNameData.trim(),
        email: emailData.trim(),
        contact: data.phoneNumber,
      };
      setIsLoading(true);
  
      console.log(firstNameData);
      console.log(lastNameData);
      console.log(emailData);
  
      request({
        url: `${UPDATE_SYSTEM_ADMIN}/${selectedRow}`,
        method: "put",
        data: putData,
      })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setIsLoading(false);
            handleUpdateClose(res.status);
            getAllSystemAdminDetails();
          }
        })
        .catch((error) => {
          setIsLoading(false);
  
          console.log(error.response);
          if (error.response) {
            setIsLoading(false);
  
            console.log("error.response.status" + error.response.status);
          }
        });
    };
  
    // update code end
  
    // Active code start
    const handleActiveConfirmClose = () => {
      setActiveConfirmOpen(false);
    };
    const handleActiveConfirmIconClose = () => {
      setActiveConfirmOpen(false);
    };
  
    const onSubmitActiveConfirm = () => {
      const AdminId = selectedRows.adminId;
      console.log(selectedRows.adminId);
      // return;
      setIsLoading(true);
  
      request({ url: `${ACTIVE_SYSTEM_ADMIN}/${AdminId}`, method: "get" })
        .then((res) => {
          console.log(res);
          setIsLoading(false);
  
          getAllSystemAdminDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    };
    //  Active code end
  
    // deActive code start
    const handledeactiveConfirmClose = () => {
      setDeactiveConfirmOpen(false);
    };
    const handledeactiveConfirmIconClose = () => {
      setDeactiveConfirmOpen(false);
    };
    const handleDeactiveClick = (selectedRow) => {
      setDeactiveConfirmOpen(true);
      setSelectedRow(selectedRow);
      setSelectedRowFirstName(selectedRow.firstName);
      setSelectedRowLastName(selectedRow.lastName);
      console.log(selectedRow.firstName);
    };
  
    const onSubmitDeactiveConfirm = () => {
      const AdminId = selectedRows.adminId;
      console.log(selectedRows.adminId);
      // return;
      setIsLoading(true);
  
      request({ url: `${DEACTIVE_SYSTEM_ADMIN}/${AdminId}`, method: "get" })
        .then((res) => {
          console.log(res);
          setIsLoading(false);
  
          getAllSystemAdminDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    };
    //  deActive code end
  
    const handleBack = () => {
      navigate(-1);
    };
  
    const handleActivateClick = (selectedRow) => {
      setActiveConfirmOpen(true);
      setSelectedRow(selectedRow);
      setSelectedRowFirstName(selectedRow.firstName);
      setSelectedRowLastName(selectedRow.lastName);
    };
  
    const {
      handleSubmit,
      control,
      setValue,
      reset,
      setError,
      formState: { errors },
      clearErrors,
    } = useForm();
  
    const getAllSystemAdminDetails = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_SYSTEM_ADMIN}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          console.log(data);
          setAdminDetails(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
  
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
  
        console.error("Error fetching data:", error);
      }
    };
  
    const getSystemAdminDetails = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_SYSTEM_ADMIN}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          console.log(data);
          setAdminDetails(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
  
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
  
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      roles.includes("ROLE_SA") ? 
      getAllSystemAdminDetails(): getSystemAdminDetails();
    }, []);
  
    const systemAdminListTable = useMemo((rowData) => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 25,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "firstName",
        header: "First Name",
        size: 25,
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        size: 25,
      },
      {
        accessorKey: "contact",
        header: "Contact",
        size: 25,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 25,
        Cell: ({ cell, row }) => (
          <span>
            {cell.getValue()}
            {row.original.role[0] === "ROLE_SA" && (
              <WorkspacePremiumIcon style={{ verticalAlign: 'middle', marginRight: '5px',color: "#ebc405" }} />
            )}
            
          </span>
        ),
        
      },
      {
        accessorKey: "isActive",
        accessorFn: (row) => (row && row.isActive ? "Active" : "InActive"),
        header: "Status",
        size: 25,
      },
    ]);
  
    const rowActions = (row) => {
      const actions = [
        {
          label: "Update",
          icon: <EditNoteOutlined color="primary" />,
          click: () => handleUpdateOpen(row),
        },
        {
          label: "Delete",
          icon: <DeleteOutline color="error" />,
          click: () => handleDeleteOpen(row.original),
        },
        {
          label: "Reset Password",
          icon: <VpnKeyOutlined color="primary" />,
          click: () => handleResetPasswordOpen(row),
        },
      ];
      if (!row.original.isActive) {
        actions.push({
          label: "Activate",
          icon: <PersonAdd color="primary" />,
          click: () => handleActivateClick(row.original),
        });
      }
      if (row.original.isActive) {
        actions.push({
          label: "Deactivate",
          icon: <PersonAddDisabledOutlined color="primary" />,
          click: () => handleDeactiveClick(row.original),
        });
      }
  
      return actions;
    };
  
    const tableActionButtons = (
      <>
       {roles.includes("ROLE_SA") && (
        <CustomButton
          fieldType="submit"
          buttonName="Add System Admin"
          click={handleAddClickOpen}
        />
      )}
      </>
    );
  
    //  Add System Admin Code Start
  
    const addModelAction = (
      <>
        <CustomButton
          fieldType="submit"
          buttonName="Add"
          click={handleAddClose}
        ></CustomButton>
        {isLoading && <CustomLoading />}
      </>
    );
  
    const addModelContent = (
      <>
        <form
          onSubmit={handleSubmit(onSubmitAdd)}
          // className="space-y-4 md:space-y-6 "
        >
          <DialogContent dividers>
            <CustomInputField
              requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
              fieldPattern={ONLY_ALPHABETIC}
              patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={60}
              maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
              fieldName="firstName"
              fieldId="firstName"
              fieldType="text"
              fieldLabel="First Name"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
            />
  
            <CustomInputField
              fieldName="lastName"
              requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
              fieldPattern={ONLY_ALPHABETIC}
              patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
              minLength={1}
              minLengthErrorMessage={MIN_LENGTH_LASTNAME_MESSAGE}
              maxLength={60}
              maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
              fieldId="lastName"
              fieldType="text"
              fieldLabel="Last Name"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
            />
            <CustomInputField
              fieldName="email"
              requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
              fieldPattern={GROUP_ADMIN_EMAIL_REGEX_PATTERN}
              patternErrorMessage={EMAIL_INVALID_MESSAGE}
              fieldId="email"
              fieldType="text"
              fieldLabel="Email"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
            />
            <br />
            <br />
  
            {/* <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: CONTACT_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    error={fieldState.invalid}
                    country={"us"}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </>
              )}
            /> */}
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: PHONE_REQUIRED_MESSAGE,
                validate: {
                  isValidPhoneNumber: (value) =>
                    isValidPhoneNumber(value) || "Invalid phone number",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <CustomPhoneInput
                    fieldControl={control}
                    label="Phone Number"
                    clearErrors={clearErrors}
                    country="US"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    // onChange={handlePhoneChange}
                    error={fieldState.invalid ? fieldState.error : null}
                    helperText={
                      fieldState.invalid ? fieldState.error?.message : ""
                    }
                  />
                </>
              )}
            />
          </DialogContent>
          <DialogActions>{addModelAction}</DialogActions>
        </form>
      </>
    );
  
    // Add System Admin Code End
  
    // System Admin Delete Code Start
  
    const okCancelButtonActions = (
      <>
        <CustomButton
          fieldType="button"
          buttonName="Cancel"
          click={handleDeleteClose}
        />
        <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handleDeleteClose}
        />
      </>
    );
    const deleteModel = (
      <form
        onSubmit={handleSubmit(onSubmitDelete)}
        // className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography>
            Are you sure you want to delete {selectedRowFirstName}{" "}
            {selectedRowLastName}
          </Typography>
        </DialogContent>
        <DialogActions>{okCancelButtonActions}</DialogActions>
      </form>
    );
  
    // System Admin Delete Code End
  
    // Reset password Code start
  
    const resetPasswordModelAction = (
      <>
        <CustomButton
          fieldType="submit"
          buttonName="Reset"
          click={handleResetPasswordClose}
        />
      </>
    );
  
    const resetPasswordModelContent = (
      <>
        <form
          onSubmit={handleSubmit(onSubmitReset)}
          // className="space-y-4 md:space-y-6 "
        >
          <DialogContent dividers>
            <Controller
              name="resetpw"
              control={control}
              rules={{
                pattern: {
                  value: PASSWORD_REGEX,
                  message: RESET_PASSWORD_INVALID_MESSAGE,
                },
                minLength: {
                  value: 8,
                  message: MIN_LENGTH_EIGHT,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fieldId="resetpw"
                  type={"text"}
                  value={password}
                  onChange={handlePasswordChange}
                  variant="standard"
                  label="Reset Password"
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    endAdornment: (
                      // <InputAdornment position="end">
                      <Tooltip
                        className="ml-2 cursor-pointer"
                        title="Your Password must contain atleast 8 to 128 characters 
                  Include atleast one uppercase letter, one lowercase letter, one digit, and one special symbol"
                      >
                        {" "}
                        <Info />
                      </Tooltip>
                      // </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            {/* <CustomInputField
              requiredErrorMessage={COMMON_MESSAGE}
              fieldPattern={PASSWORD_REGEX}
              patternErrorMessage={RESET_PASSWORD_INVALID_MESSAGE}
              minLength={8}
              minLengthErrorMessage={MIN_LENGTH_EIGHT}
              fieldName="resetpw"
              fieldId="resetpw"
              fieldType="text"
              fieldLabel="Reset Password"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
            />
     <Tooltip title="Your Password must contain atleast 8 to 128 characters 
                  Include atleast one uppercase letter, one lowercase letter, one digit, and one special symbol">
                                <Info/>
                                </Tooltip> */}
          </DialogContent>
          <DialogActions>{resetPasswordModelAction}</DialogActions>
        </form>
      </>
    );
  
    // Reset Password Code End
  
    // Update System Admin Code Start
  
    const updateActions = (
      <>
        <CustomButton
          fieldType="submit"
          buttonName="Update"
          click={handleUpdateClose}
        />
        {isLoading && <CustomLoading />}
      </>
    );
    const updateModel = (
      <>
        <form
          onSubmit={handleSubmit(onSubmitUpdate)}
          // className="space-y-4 md:space-y-6 "
        >
          <DialogContent dividers>
            <CustomInputField
              fieldPattern={ONLY_ALPHABETIC}
              patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={60}
              maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
              fieldName="updatefirstName"
              fieldId="updatefirstName"
              fieldType="text"
              fieldLabel="First Name"
              fieldControl={control}
              fieldError={errors}
              value={firstNameData}
              fieldRequiredIcon="*"
              onInput={handleEditFirstName}
            />
  
            <CustomInputField
              fieldName="updatelastName"
              fieldPattern={ONLY_ALPHABETIC}
              patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
              minLength={1}
              minLengthErrorMessage={MIN_LENGTH_LASTNAME_MESSAGE}
              maxLength={60}
              maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
              fieldId="updatelastName"
              fieldType="text"
              fieldLabel="Last Name"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={lastNameData}
              onInput={handleEditLastName}
            />
            <CustomInputField
              fieldName="updateemail"
              fieldPattern={GROUP_ADMIN_EMAIL_REGEX_PATTERN}
              patternErrorMessage={EMAIL_INVALID_MESSAGE}
              fieldId="updateemail"
              fieldType="text"
              fieldLabel="Email"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={emailData}
              onInput={handleEditEmail}
            />
            <br />
            <br />
  
            {/* <Controller
              name="updatephoneNumber"
              control={control}
              rules={{ required: CONTACT_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    error={fieldState.invalid}
                    country={"us"}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </>
              )}
            /> */}
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: PHONE_REQUIRED_MESSAGE,
                validate: {
                  isValidPhoneNumber: (value) =>
                    isValidPhoneNumber(value) || "Invalid phone number",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <CustomPhoneInput
                    fieldControl={control}
                    label="Phone Number"
                    clearErrors={clearErrors}
                    country="US"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    // onChange={handlePhoneChange}
                    error={fieldState.invalid ? fieldState.error : null}
                    helperText={
                      fieldState.invalid ? fieldState.error?.message : ""
                    }
                  />
                </>
              )}
            />
          </DialogContent>
          <DialogActions>{updateActions}</DialogActions>
        </form>
      </>
    );
  
    // Update System Admin Code End
  
    // Active System Admin Code Start
  
    const activeConfirmActions = (
      <>
        <CustomButton
          fieldType="button"
          buttonName="Cancel"
          click={handleActiveConfirmClose}
        />
        <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handleActiveConfirmClose}
        />
      </>
    );
    const activeConfirmModel = (
      <form
        onSubmit={handleSubmit(onSubmitActiveConfirm)}
        // className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography>
            {" "}
            Are you confirm to activate {selectedRowFirstName}{" "}
            {selectedRowLastName}
          </Typography>
        </DialogContent>
        <DialogActions>{activeConfirmActions}</DialogActions>
      </form>
    );
  
    // Active System Admin Code End
  
    // Deactive System admin code start
  
    const deactiveConfirmActions = (
      <>
        <CustomButton
          fieldType="button"
          buttonName="Cancel"
          click={handledeactiveConfirmClose}
        />
        <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handledeactiveConfirmClose}
        />
      </>
    );
    const deactiveConfirmModel = (
      <form
        onSubmit={handleSubmit(onSubmitDeactiveConfirm)}
        // className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography>
            {" "}
            Are you sure you want to deactivate {selectedRowFirstName}{" "}
            {selectedRowLastName}{" "}
          </Typography>
        </DialogContent>
        <DialogActions>{deactiveConfirmActions}</DialogActions>
      </form>
    );
  
    // deactive System Admin code end
  
    return (
      <div>
       <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add System Admin" />
        <CustomToastContainer />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
      <form
        onSubmit={handleSubmit(onSubmitAdd)}
        className="space-y-4 md:space-y-6 "
      >
        <CardContent>
        {/* <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5"> */}
          <CustomInputField
            requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
            fieldName="firstName"
            fieldId="firstName"
            fieldType="text"
            fieldLabel="First Name"
            fieldControl={control}
            tooltipMessage=" Provide System Admin First name"
            fieldError={errors}
            fieldRequiredIcon="*"
          />
          <CustomInputField
            fieldName="lastName"
            requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
            minLength={1}
            minLengthErrorMessage={MIN_LENGTH_LASTNAME_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
            fieldId="lastName"
            fieldType="text"
            fieldLabel="Last Name"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            tooltipMessage=" Provide System Admin Last name"
          />
          <CustomInputField
            fieldName="email"
            requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
            fieldPattern={EMAIL_REGEX_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            tooltipMessage=" Provide System Admin Email"
          />
          <br />
          {/* <br /> */}

          {/* <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: CONTACT_REQUIRED_MESSAGE }}
            render={({ field, fieldState }) => (
              <>
                <PhoneInput
                  error={fieldState.invalid}
                  country={"us"}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
                <FormHelperText style={{ color: "#D32F2F" }}>
                  {fieldState.invalid ? fieldState.error?.message : ""}
                </FormHelperText>
              </>
            )}
          /> */}
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: PHONE_REQUIRED_MESSAGE,
              validate: {
                isValidPhoneNumber: (value) =>
                  isValidPhoneNumber(value) || "Invalid phone number",
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <CustomPhoneInput
                  fieldControl={control}
                  label="Phone Number"
                  clearErrors={clearErrors}
                  country="US"
                  value={field.value}
                    tooltipMessage=" Enter your current Phone number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                  onChange={(value) => field.onChange(value)}
                  // onChange={handlePhoneChange}
                  error={fieldState.invalid ? fieldState.error : null}
                  helperText={
                    fieldState.invalid ? fieldState.error?.message : ""
                  }
                />
              </>
            )}
          />
        
        {/* </section> */}
        <div class="text-center mt-10">
        <CustomButton
          fieldType="submit"
          buttonName="Add"
          click={handleAddClose}
        ></CustomButton>
        {isLoading && <CustomLoading />}
        </div>
        </CardContent>
      </form>
      </Card>
      </div>
    );
  }
  
  export default SystemAdminAdd;
  