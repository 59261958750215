export const EMAIL_REQUIRED_MESSAGE = "Email is required";

export const EMAIL_INVALID_MESSAGE = "Please enter a valid email address";

export const PASSWORD_REQUIRED_MESSAGE = "Password is required";

export const GROUP_NAME_REQUIRED_MESSAGE = "Group name is required";

export const STATUS_REQUIRED_MESSAGE = "Status is required";

export const GROUP_NAME_INVALID_MESSAGE = "Please provide valid group name";

export const ENTITY_NUMBER_REQUIRED_MESSAGE = "Entity identity number is required";

export const ENTITY_NUMBER_INVALID_MESSAGE = "Please provide valid entity number (i.e:000-000-000)";

export const FORMATION_DATE_REQUIRED_MESSAGE = "Formation date is required";

export const HOUSE_COUNT_REQUIERD_MESSAGE = "House count is required";

export const HOUSE_COUNT_INVALID_MESSAGE = "Please provide valid house count ( 1 to 9999)";

export const ADDRESS_REQUIRED_MESSAGE = "Address is required";

export const ADDRESS_INVALID_MESSAGE = "Please provide valid Address";

export const COUNTRY_INVALID_MESSAGE = "Please provide valid Country name";

export const FIRST_NAME_REQUIRED_MESSAGE = "First Name is required";

export const MIN_LENGTH_VALIDATE_MESSAGE = "Please provide a minimum of 2 characters";


export const MIN_LENGTH_THREE_VALIDATE_MESSAGE = "Please provide a minimum of 3 digits";

export const MIN_LENGTH_LASTNAME_MESSAGE = "Please provide a minimum of 1 characters";


export const MIN_LENGTH_TEN = "please provide minimum 10 characters";

export const MIN_LENGTH_ONE = "Please provide minimum 1 character";

export const MAX_LENGTH_VALIDATE_MESSAGE = "Please provide maximum of 60 characters only"

export const MAX_LENGTH_TWO_FIFTY = "Please provide maximum of 250 characters only";

export const MAX_LENGTH_FIFTEEN = "Please provide maximum of 15 characters only";

export const MAX_LENGTH_FIVE_THOUSAND = "Please provide maximum of 5000 characters only";

export const MAX_LENGTH_THOUSAND = "Please provide maximum of 1000 characters only";

export const MAX_LENGTH_HUNDRED = "Please provide maximum of 100 characters only";

export const MAX_LENGTH_FIVE_HUNDRED = "Please provide maximum of 500 characters only";

export const MAX_LENGTH_FIFTY = "Please provide maximum of 50 characters only";

export const MAX_LENGTH_FOUR = "Please provide maximum of 4 characters only";

export const MAX_LENGTH_TWENTY_MESSAGE = "Please provide 20 digits only";

export const MAX_LENGTH_ONE_TWENTY_MESSAGE = "Please provide 120 characters only";

export const MAX_LENGTH_THIRTY_MESSAGE="Please provide 30 characters only";

export const FREE_TRIAL_REQUIRED_MESSAGE = "Free trial is required";

export const SUBSCRIPTION_PLAN_TERM_REQUIRED_MESSAGE = "Please provide subscription plan term";

export const SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE = "Subscription plan type is required";

export const LAST_NAME_REQUIRED_MESSAGE = "Last Name is required";

export const FIRST_NAME_INVALID_MESSAGE ="Please provide valid FirstName";

export const LAST_NAME_INVALID_MESSAGE = "Please provide valid LastName";

export const EXTERNAL_ROLE_REQUIRED_MESSAGE = "Please select a role";

export const ROLES_REQUIRED_MESSAGE = "Please select at least one role"

export const TOPIC_REQUIRED_MESSAGE = "Topic is required";

export const TOPIC_INVALID_MESSAGE = "Please provide valid topic";

export const DESCRIPTION_REQUIRED_MESSAGE = "Description is required";

export const DESCRIPTION_INVALID_MESSAGE = "Please provide valid Description"

export const TITLE_REQUIRED_MESSAGE ="Title is required";

export const TITLE_INVALID_MESSAGE = "Please provide valid Title";

export const AMOUNT_REQUIRED_MESSAGE = "Amount is required";

export const AMOUNT_INVALID_MESSAGE = "Please provide valid Amount";

export const FROM_DATE_REQUIRED_MESSAGE = "From date is required";

export const TO_DATE_REQUIRED_MESSAGE = "To date is required";

export const CITY_REQUIRED_MESSAGE = "City is required";

export const CITY_INVALID_MESSAGE = "Please provide valid city name";

export const STATE_REQUIRED_MESSAGE = "State is required";

export const STATE_INVALID_MESSAGE = "Please provide valid state";

export const ZIPCODE_REQUIRED_MESSAGE = "Zip code is required";

export const ZIPCODE_INVALID_MESSAGE = "Please provide valid zipcode";

export const ZIPCODE_MIN_LENGTH_MESSAGE = "Please provide minimum 5 digits";

export const ZIPCODE_MAX_LENGTH_MESSAGE = "Please provide maximum of 5 digits";    

export const CONTACT_REQUIRED_MESSAGE = "Contact is required";

export const TO_DATE_ERROR_MESSAGE = "Please provide To date greater than from date";

export const OTP_REQUIRED_MESSAGE = "OTP is required";

export const OTP_INVALID_MESSAGE = "Please provide valid OTP";

export const DEACTIVATE_REQUIRED_MESSAGE = "Please select deactivate date";

export const REMARKS_REQUIRED_MESSAGE = "Remark is required";

export const SPECIAL_DISCOUNT_TYPE_REQUIRED = "Please select special discount type";

export const SUBSCRIPTION_START_DATE_REQUIRED_MESSAGE = "Subscription start date is required";

export const CURRENT_AND_NEW_PASSWORD_CHECKING = "Current password and new password should not be same";

export const NEW_PASSWORD_CURRENT_PASSWORD_CHECKING ="Confirm password and new password must be the same.";

export const CURRENT_PASSWORD_REQUIRED_MESSAGE = "Current Password is required";

export const NEW_PASSWORD_REQUIRED_MESSAGE = "New Password is required";

export const CONFIRM_PASSWORD_REQUIRED_MESSAGE = "Confirm Password is required";

export const HOME_ADDRESS_REQUIRED_MESSAGE = "Home address is required";

export const TAX_PERCENTAGE_REQUIED_MESSAGE = "Tax Percentange is required";

export const TAX_PERCENTAGE_VALID_MESSAGE = "Please provide a valid tax percentage (i.e 00.00)";

export const COMMON_MESSAGE = "This field is required";

export const RESET_PASSWORD_INVALID_MESSAGE = "Please provide vaild password";

export const MIN_LENGTH_EIGHT = "Please provide a minimum of 8 characters";

export const PERCENTANGE_INVALID_MESSAGE = "Please provide valid special discount";


export const PHONE_REQUIRED_MESSAGE="Contact is required"

export const PHONE_INVALID_MESSAGE="Please provide valid Contact"


export const FREE_TRIAL_INVALID_MESSAGE = "Free trial must be between 1 and 90 days.";

export const MIN_LENGTH_ONE_MESSAGE = "Please provide minimum of 1 digit";

export const MAX_LENGTH_TWO_MESSAGE = "Please provide maximum of 2 digits";

export const SPECIAL_DISCOUNT_REQUIRED_MESSAGE="Special Discount is required";

export const MIN_LENGTH_FOUR_MESSAGE = "Please provide minimum of 4 digit";

export const MIN_LENGTH_SIX_MESSAGE = "Please provide maximum of 6 digit";

export const MIN_LENGTH_TWO = "Please provide minimum of 2 characters";

export const EMAIL_REGEX_PATTERNS = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

