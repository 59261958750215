import {createSlice} from '@reduxjs/toolkit';

export const hybridhoa = createSlice({

    name:'hybridhoa',
    initialState: {
        token:null,
        sideNavState:true,
        userLoginDetails:null,
        userDetails:null,
        roles:[],
        pageIndexes: {},
        
      },
      reducers: {
       
        setToken: (state, action) => {
          state.token = action.payload
        },
        setSideNavState: (state, action) => {
          state.sideNavState = action.payload
        },
        setUserLoginDetails :(state, action) => {
          state.userLoginDetails = action.payload
        },
        setUserDetails :(state, action) => {
          state.userDetails = action.payload
        },
        setRoles :(state, action) => {
          state.roles = action.payload
        },
        setPageIndex: (state, action) => { 
          const { url, pageIndex } = action.payload;
          state.pageIndexes[url] = pageIndex; 
        },
        clearAuthToken: (state) => {
          state.token = null;
        },
                 
      }
    })
    
    export const { setToken,setSideNavState,setRoles,clearAuthToken,setUserLoginDetails,setUserDetails,setPageIndex } = hybridhoa.actions
    
    export default hybridhoa.reducer