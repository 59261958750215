import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, PAYMENT_DETAILS } from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomHeading from "../../components/CustomHeading";
import { CustomStyle } from "../../components/CustomStyle";
import { Button, Card, CardContent } from "@mui/material";
import { Info } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment/moment";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";

function PaymentGateway() {
  const { groupId, groupAdminDetailsId } = useParams();
  const [selectedRowGroupId, setSelectedRowGroupId] = useState("");
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ssn, setSsn] = useState("");
  const [role, setRole] = useState("");
  const [accountNumber, setAccountNunmber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [bussinessEmail, setBussinessEmail] = useState("");
  const [hoaDescription, setHoaDescription] = useState("");
  const [oragnizationType, setOragnizationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dateofBirth, setDateofBirth] = useState("");
  const navigate = useNavigate();
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 600,

      fontSize: 15,
    },
  });
  const handleClick = () => {
    navigate(-1);
  };
  const getPaymentDetails = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(
        `${BASE_URL}${PAYMENT_DETAILS}/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      if (response.status === 200) {
        const paymentdata = response.data;
        setFirstName(paymentdata.legalFirstName);
        setLastName(paymentdata.legalLastName);
        setOragnizationType(paymentdata.orgType);
        setRole(paymentdata.legalRole);
        setAccountNunmber(paymentdata.accountNumber);
        setBussinessEmail(paymentdata.businessEmail);
        setSsn(paymentdata.ssn);
        setHoaDescription(paymentdata.hoaDesc);
        setDateofBirth(paymentdata.dob);
        setRoutingNumber(paymentdata.routingNumber);
        console.log("paymentdetails", paymentdata);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [groupId]);

  const data = [
    {
      groupName: "Basic Details",
      items: [
        {
          label: "Legal First Name",
          data: `${firstName === null ? "" : firstName}`,
          text: "First Name of the Admin",
        },
        {
          label: "Legal Last Name",
          data: `${lastName === null ? "" : lastName}`,
          text: "Last Name of the Admin",
        },
        {
          label: "Date Of Birth",
          data: `${
            dateofBirth === null
              ? ""
              : moment(dateofBirth).format("MM/DD/YYYY")
          }`,
          text: "Date of Birthday",
        },
        {
          label: "Last Four digit of Social Security Number",
          data: `${ssn === null ? "" : ssn}`,
          text: "Last Four digit of Social Security Number",
        },
      ],
    },
    {
      groupName: "Organization Details",
      items: [
        {
          label: "Organization Type",
          data: `${oragnizationType === null ? "" : oragnizationType}`,
          text: "Organization Type",
        },
        {
          label: "HOA Desc",
          data: `${hoaDescription === null ? "" : hoaDescription}`,
          text: "HOA Desc",
        },
      ],
    },

    {
      groupName: "Role",

      items: [
        {
          label: "Role",
          data: `${role === null ? "" : role}`,
          text: "Enter your role",
        },
      ],
    },

    {
      groupName: "Business website",

      items: [
        {
          label: "Website",
          data: `${bussinessEmail === null ? "" : bussinessEmail}`,
          text: "Enter your website",
        },
      ],
    },
    {
      groupName: "Banking Details",

      items: [
        {
          label: "Routing Number",
          data: `${routingNumber === null ? "" : routingNumber}`,
          text: "Enter your Routing Number",
        },
        {
          label: "Account Number",
          data: `${accountNumber === null ? "" : accountNumber}`,
          text: "Enter your Account Number",
        },
      ],
    },
  ];

  
  return (
    
      <CustomViewHeading
        onClick={handleClick}
        title={"View PaymentHOA's Payment Gateway Informations"}
      >
        <CustomViewWithTooltip data={data} />
      </CustomViewHeading>
  
  );
}

export default PaymentGateway;
