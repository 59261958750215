// import React from 'react'
// import LoginRight from '../components/login/LoginRight'
// import LoginForm from '../components/login/LoginForm'

// function LoginPage() {
//   return (
//     <div className="bg-gray-50 pr-2 pl-2">
//     <div className="container mx-auto flex flex-col lg:flex-row">
//       <LoginRight />
//        <LoginForm/>
      
//     </div>
//   </div>
//   )
// }

// export default LoginPage


import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from 'axios'; // Import axios for making HTTP requests
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA if you're using it
import { toast } from 'react-toastify';
import CustomToastContainer from '../../components/CustomToastContainer';
import CustomInputField from '../../components/CustomInputField';
import { EMAIL_INVALID_MESSAGE, EMAIL_REQUIRED_MESSAGE, PASSWORD_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { EMAIL_REGEX_PATTERN } from '../../utills/ApplicationRegex';
import logo from "../../assets/img/logo.png";
import CustomButton from '../../components/CustomButton';
import {
  setRoles,
  setToken,
  setUserDetails,
  setUserLoginDetails,
} from "../../redux/Hybridhoa";
import { BASE_URL, LOGIN } from '../../utills/ApplicationRouting';


// Import constants and utilities if needed


function LoginPage() {

  const onSubmit = (data) => {
    // if (!captchaToken) {
    //   toast.error('Please complete the captcha');
    //   return;
    // }
    if (loginAttempts >= 3 && !isCaptchaValid) {
      toast.error('You must complete the CAPTCHA to proceed');
      return;
    }

    const postData = {
      email: data.email,
      password: data.password,
      captchaToken: captchaToken,
    };

    axios
      .post(`${BASE_URL}${LOGIN}`, postData)
      .then((response) => {
        console.log(response.data.data);

        if (response.status === 200) {
          console.log(response);
          console.log(response.data.user.login);
          console.log(response.data.user.details);
          console.log(response.data.user.login.roles);
          console.log(response.data.token + "Response Token");
          console.log(response.message + "Response Message");
          localStorage.setItem("token", response.data.token);
          dispatch(setUserLoginDetails(response.data.user.login));
          dispatch(setUserDetails(response.data.user.details));
          dispatch(setRoles(response.data.user.login.roles));
          dispatch(setToken(response.data.token));
          if (response.status === 200) {
            console.log(response.data);
            toast.success(response.data);
          }
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status !== 200) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
         // Increment login attempts
         setLoginAttempts(prevAttempts => prevAttempts + 1);

         // If login attempts exceed 3, reset attempts and show CAPTCHA
         if (loginAttempts >= 3) {
           setCaptchaToken(null); // Reset CAPTCHA token
           // setLoginAttempts(0); // Reset login attempts
         }
       }
     });
   };
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

 
    
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const { token } = useSelector(state => state.hybridhoa);
  const dispatch = useDispatch();
  const storedToken = localStorage.getItem("token");
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  
  const handleVerify = (token) => {
    if (token) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
    console.log(token);
  };

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors},  
  } = useForm();
    



  return (
    <div  >
     <div class="grid  grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-1  gap-4 ">
 
<div className=' '>
  

  
    </div>

    <div >
      <CustomToastContainer/>
      <div className="flex justify-center items-center h-screen overflow-hidden  " >
    

    <div className="w-full max-w-md p-6 md:p-8 ">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6 space-y-4 md:space-y-6 ">
          <div className="mb-6 absolute top-0 left-1/2 transform -translate-x-1/2 md:left-36">
  <img className="w-44 h-14 mx-auto md:mx-0" src={logo} alt="logo" />
</div>



          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center text-size-20px">
            System Portal
          </h1>
          <form className="space-y-2 md:space-y-4"  onSubmit={handleSubmit(onSubmit)} >
                <div>
                  <CustomInputField
                    fieldName="email"
                    requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                    fieldPattern={EMAIL_REGEX_PATTERN}
                    patternErrorMessage={EMAIL_INVALID_MESSAGE}
                    fieldId="login-email"
                    fieldType="text"
                    fieldLabel="Email"
                    fieldControl={control}
                    fieldError={errors}
                  />
                </div>
                <div className="relative z-0 mb-6">
                   {/* <CustomInputField
                     fieldName="password"
                     requiredErrorMessage={PASSWORD_REQUIRED_MESSAGE}
                     fieldId="login-password"
                     fieldType="password"
                     fieldLabel="Password"
                     fieldControl={control}
                     fieldError={errors}
                     
                     /> */}
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: PASSWORD_REQUIRED_MESSAGE }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        variant="standard"
                        label="Password"
                        fullWidth
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                 <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  
                
                </div>
                {loginAttempts >= 3 && (
                      <div className="flex justify-center">
                        <ReCAPTCHA
                          sitekey="6Lc6O5AqAAAAAAAiMlNjiSQNK1K-wsispctL-Ebh" // Replace with your reCAPTCHA site key
                          onChange={handleVerify}
                        />
                      </div>
                    )}
                {/* <br/> */}
                <div className="flex justify-center  pt-4">
                  <CustomButton fieldType="submit" buttonName="Login" />
                </div>
                <br/>
                <hr />
                <div className="flex items-center justify-center">
                <Link
                  to="/forgot"
                  className="text-sm font-medium text-primary-600 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  )
}

export default LoginPage;