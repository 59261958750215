import {
  DeleteOutline,
  EditNoteOutlined,
  PersonAdd,
  PersonAddDisabledOutlined,
  VpnKeyOutlined,
  Telegram,
} from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
  DialogActions,
  DialogContent,
  FormHelperText,
  Typography,
} from "@mui/material";
import {
  COMMON_MESSAGE,
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_EIGHT,
  MIN_LENGTH_FOUR_MESSAGE,
  MIN_LENGTH_LASTNAME_MESSAGE,
  MIN_LENGTH_SIX_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  OTP_INVALID_MESSAGE,
  OTP_REQUIRED_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  RESET_PASSWORD_INVALID_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,
  ONLY_DIGITS,
  PASSWORD_REGEX,
} from "../../utills/ApplicationRegex";
import CustomInputField from "../../components/CustomInputField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import axios from "axios";
import {
  ACTIVE_GROUP_ADMIN,
  ADMIN_RESET_PASSWORD,
  BASE_URL,
  DEACTIVE_GROUP_ADMIN,
  GET_ALL_GROUP_ADMIN,
  GROUP,
  GROUP_ADMIN,
  PROMOTE_AS_PRIMARY,
  SYSTEM_ADMIN_RESET_PASSWORD,RESEND_EMAIL,
  SEND_OTP,
  VERIFY_OTP,
} from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import { request } from "../../services/AxiosConfig";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomMaterialTableActions from "../../components/CustomMaterialTableActions";

function GroupAdmin() {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [addOpen, setAddOpen] = useState(false);
  const [addOtpOpen, setAddOtpOpen] = useState(false);
  const [addSentOtpOpen, setAddSentOtpOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deletePrimaryOpen, setDeletePrimaryOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOtp] = useState(false);
  const [passwordOtpOpen, setPasswordOtp] = useState(false);
  const [passwordSentOtp, setPasswordSentOtp] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [activeOpen, setActiveOpen] = useState(false);
  const [activeConfirmOpen, setActiveConfirmOpen] = useState(false);
  const [deactiveOpen, setDeactiveOpen] = useState(false);
  const [deactiveConfirmOpen, setDeactiveConfirmOpen] = useState(false);
  const [deactivePrimaryConfirmOpen, setDeactivePrimaryConfirmOpen] =
    useState(false);
  const [promoteOpen, setPromoteOpen] = useState(false);
  const [promoteOtpOpen, setPromoteOtpOpen] = useState(false);
  const [promoteSentOtp, setPromoteSentOtp] = useState(false);
  const [adminDetails, setAdminDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [resendOpen, setResendOpen] = useState(false);
  const [resendFailOpen, setResendFailOpen] = useState(false);
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );

  const [selectedRowFirstName, setSelectedRowFirstName] = useState(null);
  const [selectedRowLastName, setSelectedRowLastName] = useState(null);
  const [selectedRows, setSelectedRow] = useState(null);
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [emailData, setEmailData] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [updateRes, setUpdateRes] = useState([]);
  const [primary, setPrimary] = useState("");
  const [isResendModalOpen, setIsResendModalOpen] = useState(false); 
  const selectedRow = updateRes;

  const [show, setShow] = useState(false);
  //  Add Start
  // console.log(groupId);
  const handleEditFirstName = (e) => {
    setFirstNameData(e.target.value);
  };
  const handleEditLastName = (e) => {
    setLastNameData(e.target.value);
  };
  const handleEditEmail = (e) => {
    setEmailData(e.target.value);
  };
  // add code end

  // password Reset Start

  // Password Reset End

  const getAdminDetailsById = async (id) => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_ADMIN}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setUpdateRes(data.adminId);
        setFirstNameData(data.firstName);
        setLastNameData(data.lastName);
        setEmailData(data.email);

        setValue("phoneNumber",data.contact)

        setPrimary(data.isPrimary);

        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  const handleUpdateClose = (status) => {
    if (status == 200) {
      setUpdateOpen(false);
    }
  };
  const handleUpdateIconClose = () => {
    setUpdateOpen(false);
  };
  // update code start
  const handleUpdateOpen = (selectedRow) => {
    setUpdateOpen(true);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.adminId);
    setSelectedRowId(selectedRow.original.adminId);

    getAdminDetailsById(selectedRow.original.adminId);
  };


  const handleResendOpen = (selectedRow) => {
console.log("selectedRow",selectedRow);
    setResendOpen(true)
    setIsResendModalOpen(true)
    setSelectedRowId(selectedRow.adminId);
    
  };

  const handleResendFailOpen = (selectedRow) => {
   
    setResendFailOpen(true)

    setSelectedRowId(selectedRow.adminId);
    
  };
  const handleResendModalClose = () => {
    setIsResendModalOpen(false); // Close the deactivate modal
  };

  const onSubmitUpdate = (data) => {
    const putData = {
      firstName: firstNameData.trim(),
      lastName: lastNameData.trim(),
      email: emailData.trim(),

      contact :data.phoneNumber

    };

    if (firstNameData.trim() =="" || firstNameData == null) {
      setError("updatefirstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (lastNameData.trim() == "" || lastNameData == null) {
      setError("updatelastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (emailData.trim() == "" || emailData == null) {
      setError("updateemail", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    console.log(firstNameData);
    console.log(lastNameData);
    console.log(emailData);

    request({
      url: `${GROUP_ADMIN}/${selectedRow}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleUpdateClose(res.status);
          getAllAdminDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  // update code end

  // Active code start

  const onSubmitActiveConfirm = () => {
    const AdminId = selectedRows.adminId;
    console.log(selectedRows.adminId);
    // return;
    setIsLoading(true)

    request({ url: `${ACTIVE_GROUP_ADMIN}/${AdminId}`, method: "get" })
      .then((res) => {
        console.log(res);
        getAllAdminDetails();
      })
      .catch((error) => {
        setIsLoading(false)

        console.log(error);
      });
  };
  //  Active code end

  // deActive code start

  const handleBack = () => {
    navigate(-1);
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset, clearErrors,
    formState: { errors },
    setError,
  } = useForm();

  const getAllAdminDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GROUP_ADMIN}/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data.length);
        if (response.data.length) {
          setShow(true);
        }
        setAdminDetails(data);
        console.log(data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllAdminDetails();
  }, []);

  // Add code

  const handleAddClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = (status) => {
    if (status == 200) {
      setAddOpen(false);
    }
  };
  const handleAddIconClose = () => {
    setAddOpen(false);
  };


    const onSubmitAdd = (data) => {

      const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);

      if (!isValidPhoneNumberForCountry) {
        setIsLoading(false);
        return;
      }
      
      const postData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        contact: data.phoneNumber,
        groupId:groupId,
      };
      console.log(data);
      setIsLoading(true);
      request({ url: GROUP_ADMIN, method: "post", data: postData })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log("Success");
  
            setIsLoading(false);
            reset()
            handleAddClose(res.status);
            getAllAdminDetails();
          }else if(res.status!=200){
            setIsLoading(false)

          }
        })
        .catch((res) => {

          setIsLoading(false);
          handleAddClose(res.status);
          getAllAdminDetails();
        })
      
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  // code end

  // delete code start

  const handleDeleteOpen = (selectedRow) => {
    setDeleteOpen(true);
    setSelectedRow(selectedRow);
    console.log(selectedRow);
    setSelectedRowFirstName(selectedRow.firstName);
    setSelectedRowLastName(selectedRow.lastName);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    
  };

  const handlePrimaryDeleteOpen = (selectedRow) => {
    setDeletePrimaryOpen(true);
    setSelectedRow(selectedRow);
    console.log(selectedRow);
    setSelectedRowFirstName(selectedRow.firstName);
    setSelectedRowLastName(selectedRow.lastName);
  };

  const handleDeletePrimaryClose = () => {
    setDeletePrimaryOpen(false);
  };

  const onSubmitDelete = () => {
    const AdminId = selectedRows.adminId;
    console.log(selectedRows.adminId);
    // return;
    request({ url: `${GROUP_ADMIN}/${AdminId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getAllAdminDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // delete code end

  // Deactivate code start

  const handledeactiveConfirmClose = () => {
    setDeactiveConfirmOpen(false);
  };

  const handledeactivePrimaryConfirmClose = () => {
    setDeactivePrimaryConfirmOpen(false);
  };
  const handledeactiveConfirmIconClose = () => {
    setDeactiveConfirmOpen(false);
  };

  const handleDeactiveClick = (selectedRow) => {
    setDeactiveConfirmOpen(true);
    setSelectedRow(selectedRow);
    setSelectedRowFirstName(selectedRow.firstName);
    setSelectedRowLastName(selectedRow.lastName);
    console.log(selectedRow.firstName);
  };
  const handlePrimaryDeactiveClick = (selectedRow) => {
    setDeactivePrimaryConfirmOpen(true);
    setSelectedRow(selectedRow);
    setSelectedRowFirstName(selectedRow.firstName);
    setSelectedRowLastName(selectedRow.lastName);
    console.log(selectedRow.firstName);
  };

  const onSubmitDeactiveConfirm = (data) => {
    const AdminId = selectedRows.adminId;
    console.log(selectedRows.adminId);
    // return;
    request({ url: `${DEACTIVE_GROUP_ADMIN}/${AdminId}`, method: "get" })
      .then((res) => {
        console.log(res);
        getAllAdminDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Deactivate code end

  // Promote code start

  const handlePromoteClose = () => {
    setPromoteOpen(false);
  };
  const handlePromoteIconClose = () => {
    setPromoteOpen(false);
  };
  const handleResendClose = () => {
    setResendOpen(false);
  };
  const handleResendIconClose = () => {
    setResendOpen(false);
  };

  const handleResendFailClose = () => {
    setResendFailOpen(false);
  };
  const handleResendFailIconClose = () => {
    setResendFailOpen(false);
  };

  const handlePrimaryOpen = (selectedRow) => {
    setPromoteOpen(true);
    setSelectedRow(selectedRow);
    setSelectedRowFirstName(selectedRow.firstName);
    setSelectedRowLastName(selectedRow.lastName);
    console.log(selectedRow.adminId);
  };

  const onSubmitPromote = (data) => {
    const AdminId = selectedRows.adminId;
    console.log(selectedRows.adminId);

    const postData = {
      groupId: groupId,
    };
    console.log(data);
    setIsLoading(true);
    request({
      url: `${PROMOTE_AS_PRIMARY}/${AdminId}`,
      method: "post",
      data: postData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");

          setIsLoading(false);

          getAllAdminDetails();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const   onResendSubmit = async (data) => {
    // const AdminId = selectedRows.adminId;
    // console.log(selectedRows.adminId);
    // return;

   

    request({ url: `${RESEND_EMAIL}/${selectedRowId}`, method: "get" })
    .then((res) => {
      console.log(res);
      getAllAdminDetails();
    })
    .catch((error) => {
      console.log(error);
    });
  };


  // Promote code end

  // Activate code

  const handleActiveConfirmClose = () => {
    setActiveConfirmOpen(false);
  };
  const handleActivateClick = (selectedRow) => {
    setActiveConfirmOpen(true);
    setSelectedRow(selectedRow);
    setSelectedRowFirstName(selectedRow.firstName);
    setSelectedRowLastName(selectedRow.lastName);
  };
  // Activate code end

  // password Reset Start

  const handleResetPasswordOpen = () => {
    setResetPasswordOtp(true);
    setSelectedRowId(adminId);
  };
  // const handleSendOtpModelOpen=(rowData)=>{
  //   console.log("rowData",rowData);
  //   setEmail(rowData.original.email)
  //   setAdminId(rowData.original.adminId)
  //   setSendOtpModel(true)
  // }
  const handleResetPasswordClose = (status) => {
    if (status == 200) {
      setResetPasswordOtp(false);
    }
  };
  const handleResetPasswordIconClose = () => {
    setResetPasswordOtp(false);
  };

  const onSubmitReset = (data) => {
    const postData = {
      password: data.resetpw,
    };
    console.log(data);
    setIsLoading(true);
    request({
      url: `${ADMIN_RESET_PASSWORD}/${selectedRowId}`,
      method: "post",
      data: postData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");

          setIsLoading(false);
          handleResetPasswordClose(res.status);
          getAllAdminDetails();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  // Password Reset End

  const groupAdminListTable = useMemo((rowData) => [
    // {
    //     accessorKey:'serialNo',
    //     header:'Id',
    //     size:25
    // },
    {
      accessorKey: "firstName",
      header: "First Name",
      size: 25,
    },
    {
      accessorKey: "lastName",
      header: "Last Name",
      size: 25,
    },
    {
      accessorKey: "contact",
      header: "Contact",
      size: 25,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 25,
    },
    {
      accessorKey: "isActive",
      accessorFn: (row) => (row && row.isActive ? "Active" : "InActive"),
      header: "Status",
      size: 25,
    },
    {
      accessorKey: "isPrimary",
      accessorFn: (row) => (row && row.isPrimary ? "Yes" : "No"),
      header: "Primary",
      size: 25,
    },
  ]);

  const rowActions = (row) => {
    const actions = [
      // {
      //   label: "Resend",
      //   icon: <Telegram color="primary" />,
      //   click: () => handleResendOpen(row),
      // },
      {
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleUpdateOpen(row),
      },

      // {
        
      //   label: "Reset Password",
      //   icon: <VpnKeyOutlined color="primary" />,
      //   click: ()=>handleSendOtpModelOpen(row),
      // },
    ];
    if (!row.original.emailVerified) {
      actions.push({
        label: "Resend",
        icon: <Telegram color="primary" />,
        click: () => handleResendOpen(row.original),
      });
    }
    if (row.original.emailVerified) {
      actions.push({
        label: "Resend",
        icon: <Telegram color="primary" />,
        click: () => handleResendFailOpen(row.original),
      });
    }

    if (!row.original.isActive) {
      actions.push({
        label: "Activate",
        icon: <PersonAdd color="primary" />,
        click: () => handleActivateClick(row.original),
      });
    }
    if (row.original.isActive && !row.original.isPrimary) {
      actions.push({
        label: "Deactivate",
        icon: <PersonAddDisabledOutlined color="primary" />,
        click: () => handleDeactiveClick(row.original),
      });
    }

    if (row.original.isActive && row.original.isPrimary) {
      actions.push({
        label: "Deactive",
        icon: <PersonAddDisabledOutlined color="primary" />,
        click: () => handlePrimaryDeactiveClick(row.original),
      });
    }
    if (!row.original.isPrimary) {
      actions.push({
        label: "Promote as Primary",
        icon: <PersonAdd color="primary" />,
        click: () => handlePrimaryOpen(row.original),
      });
    }

    if (row.original.isActive && !row.original.isPrimary) {
      actions.push({
        label: "Delete",
        icon: <DeleteOutline color="error" />,
        click: () => handleDeleteOpen(row.original),
      });
    }

    if (row.original.isActive && row.original.isPrimary) {
      actions.push({
        label: "Delete",
        icon: <DeleteOutline color="error" />,
        click: () => handlePrimaryDeleteOpen(row.original),
      });
    }

    return actions;
  };

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleAddClickOpen}
      />

      <CustomButton fieldType="button" buttonName="Back" click={handleBack} />
    </>
  );

  //  Add Group Admin Code Start

  const addModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleAddClose}
      />
    </>
  );

  const addModelContent = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitAdd)}
        // className="space-y-4 md:space-y-6 bg-yellow"
      >
        <DialogContent dividers>
          <CustomInputField
            requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
            fieldName="firstName"
            fieldId="firstName"
            fieldType="text"
            fieldLabel="First Name"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
          />

          <CustomInputField
            fieldName="lastName"
            requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
            minLength={1}
            minLengthErrorMessage={MIN_LENGTH_LASTNAME_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
            fieldId="lastName"
            fieldType="text"
            fieldLabel="Last Name"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
          />
          <CustomInputField
            fieldName="email"
            requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
            fieldPattern={EMAIL_REGEX_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
          />
          <br />
          <br />

          {/* <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: CONTACT_REQUIRED_MESSAGE }}
            render={({ field, fieldState }) => (
              <>
                <PhoneInput
                  error={fieldState.invalid}
                  country={"us"}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
                <FormHelperText style={{ color: "#D32F2F" }}>
                  {fieldState.invalid ? fieldState.error?.message : ""}
                </FormHelperText>
              </>
            )}
          /> */}
           <Controller
  name="phoneNumber"
  control={control}
  rules={{ required: PHONE_REQUIRED_MESSAGE,
    validate: {
      isValidPhoneNumber: valueadd => isValidPhoneNumber(valueadd) || "Invalid phone number"
    } }}
  render={({ field, fieldState }) => (
    <>
      <CustomPhoneInput
        fieldControl={control}
        label="Phone Number"
        clearErrors={clearErrors}
        country="US"
        value={field.valueadd} 
        onChange={(valueadd) => field.onChange(valueadd)} 
        error={fieldState.invalid ? fieldState.error : null}
        helperText={fieldState.invalid ? fieldState.error?.message : ''}
      />
    </>
  )}
/>
        </DialogContent>
        <DialogActions>{addModelAction}</DialogActions>
      </form>
    </>
  );

  // Add Group Admin Code End

  // Group Admin Delete Code Start

  const okCancelButtonActions = (
    <>
     <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
     
    </>
  );
  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          Are you sure you want to delete {selectedRowFirstName}{" "}
          {selectedRowLastName}
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const okCancelPrimaryButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleDeletePrimaryClose}
      />
    </>
  );
  const deletePrimaryModel = (
    <>
      <DialogContent dividers>
        <Typography>
        To delete the primary group admin, please assign another group admin as the primary admin.
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelPrimaryButtonActions}</DialogActions>
    </>
  );

  // Group Admin Delete Code End

  // Reset password Code start

  const resetPasswordModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleResetPasswordClose}
      />
    </>
  );

  const resetPasswordModelContent = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitReset)}
        // className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <CustomInputField
            requiredErrorMessage={COMMON_MESSAGE}
            fieldPattern={PASSWORD_REGEX}
            patternErrorMessage={RESET_PASSWORD_INVALID_MESSAGE}
            minLength={8}
            minLengthErrorMessage={MIN_LENGTH_EIGHT}
            fieldName="resetpw"
            fieldId="resetpw"
            fieldType="text"
            fieldLabel="Reset Password"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            
          />
        </DialogContent>
        <DialogActions>{resetPasswordModelAction}</DialogActions>
      </form>
    </>
  );

  // Update Group Admin Code Start

  const updateActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update"
        click={handleUpdateClose}
      />
      {isLoading && <CustomLoading />}
    </>
  );
  const updateModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitUpdate)}
        // className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <CustomInputField
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
            fieldName="updatefirstName"
            fieldId="updatefirstName"
            fieldType="text"
            fieldLabel="First Name"
            fieldControl={control}
            fieldError={errors}
            value={firstNameData}
            fieldRequiredIcon="*"
            onInput={handleEditFirstName}
          />

          <CustomInputField
            fieldName="updatelastName"
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
            minLength={1}
            minLengthErrorMessage={MIN_LENGTH_LASTNAME_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
            fieldId="updatelastName"
            fieldType="text"
            fieldLabel="Last Name"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            value={lastNameData}
            onInput={handleEditLastName}
          />
         {primary ? 
         <>
              <CustomInputField
            fieldName="updateemail"
            fieldPattern={EMAIL_REGEX_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldId="updateemail"
            fieldType="text"
            fieldLabel="Email"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            value={emailData}
            onInput={handleEditEmail}
          />
          
         </>
         :
         <CustomInputField
           fieldName="updateemail"
           fieldPattern={EMAIL_REGEX_PATTERN}
           patternErrorMessage={EMAIL_INVALID_MESSAGE}
           fieldId="updateemail"
           fieldType="text"
           fieldLabel="Email"
           fieldControl={control}
           fieldError={errors}
           fieldRequiredIcon="*"
           value={emailData}
           onInput={handleEditEmail}
           disabled={true}
         />
       
         }
       
          <br />
          <br />

          {/* <Controller
            name="updatephoneNumber"
            control={control}
            rules={{ required: CONTACT_REQUIRED_MESSAGE }}
            render={({ field, fieldState }) => (
              <>
                <PhoneInput
                  error={fieldState.invalid}
                  country={"us"}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
                <FormHelperText style={{ color: "#D32F2F" }}>
                  {fieldState.invalid ? fieldState.error?.message : ""}
                </FormHelperText>
              </>
            )}
          /> */}

<Controller
  name="phoneNumber"
  control={control}
  rules={{ required: PHONE_REQUIRED_MESSAGE,
    validate: {
      isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
    } }}
  render={({ field, fieldState }) => (
    <>
      <CustomPhoneInput
        fieldControl={control}
        label="Phone Number"
        clearErrors={clearErrors}
        country="US"
        value={field.value} 
        onChange={(value) => field.onChange(value)} 
        // onChange={handlePhoneChange}
        error={fieldState.invalid ? fieldState.error : null}
        helperText={fieldState.invalid ? fieldState.error?.message : ''}
      />
    </>
  )}
/>
        </DialogContent>
        <DialogActions>{updateActions}</DialogActions>
      </form>
    </>
  );

  // Update Group Admin Code End

  const activeConfirmActions = (
    <>
    
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleActiveConfirmClose}
      />
        <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleActiveConfirmClose}
      />
    </>
  );
  const activeConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitActiveConfirm)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you confirm to activate {selectedRowFirstName}{" "}
          {selectedRowLastName}
        </Typography>
      </DialogContent>
      <DialogActions>{activeConfirmActions}</DialogActions>
    </form>
  );

  // Active Group Admin Code End

  // Deactive Group admin code start

  const deactiveConfirmActions = (
    <>
    <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handledeactiveConfirmClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handledeactiveConfirmClose}
      />
      
    </>
  );
  const deactiveConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitDeactiveConfirm)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you confirm to deactivate {selectedRowFirstName}{" "}
          {selectedRowLastName}{" "}
        </Typography>
      </DialogContent>
      <DialogActions>{deactiveConfirmActions}</DialogActions>
    </form>
  );

  const deactivePrimaryConfirmActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handledeactivePrimaryConfirmClose}
      />
    </>
  );
  const deactivePrimaryConfirmModel = (
    <>
      <DialogContent dividers>
        <Typography>
        To deactivate the primary group admin, please assign another group admin as the primary admin.
        </Typography>
      </DialogContent>
      <DialogActions>{deactivePrimaryConfirmActions}</DialogActions>
    </>
  );

  // const resendModel = (
  //   <form onSubmit={handleSubmit(onResendSubmit)} className="space-y-4 md:space-y-6">
  //     <div>
    
    
  //     <DialogContent dividers>
  //       <div className="text-center">
         
  //         <Typography>
  //           {""}
           
  //         </Typography>
  //       </div>
  //     </DialogContent>
  //       {/* <DialogActions>{activateButtons}</DialogActions> */}
  //       <CardContent>
  //     <div className="flex justify-end mt-2 space-x-4">
  //       <CustomButton fieldType="submit" buttonName="Resend" />
  //       <CustomButton
  //         fieldType="button"
  //         buttonName="Cancel"
  //         click={handleResendModalClose} // Assuming you have a function to handle cancel action
  //       />
  //     </div>
  //   </CardContent>
  //   </div>
  // </form>
  // );

  //Propmote code start
  const promoteActions = (
    <>
     
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handlePromoteClose}
      />
       <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handlePromoteClose}
      />
    </>
  );
  const ResendActions = (
    <>
     
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleResendClose}
      />
       <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleResendClose}
      />
    </>
  );
  const ResendFailActions = (
    <>
     
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleResendFailClose}
      />
      
    </>
  );
  const promoteConfirmModel = (
    <form
      onSubmit={handleSubmit(onSubmitPromote)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you confirm to Promote as primary {selectedRowFirstName}{" "}
          {selectedRowLastName}{" "}
        </Typography>
      </DialogContent>
      <DialogActions>{promoteActions}</DialogActions>
    </form>
  );
  const resendModel = (
    <form
      onSubmit={handleSubmit(onResendSubmit)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
        Are you sure want to Resend Email ?
        </Typography>
      </DialogContent>
      <DialogActions>{ResendActions}</DialogActions>
    </form>
  );

  const resendFailModel = (
  <>
    <DialogContent dividers>
        <Typography>
    Your profile has already verified
        </Typography>
      </DialogContent>
      <DialogActions>{ResendFailActions}</DialogActions>
  </>
    
   
  );
  // Promote code end


  //send email otp start

  const [sendOtpModel,setSendOtpModel]=useState(false)
const [email,setEmail]=useState("")
const [adminId,setAdminId]=useState("")

const onOtpSubmit = (data) => {
  const putData = {
    email: email,
  };

    setIsLoading(false);


  request({
    url: `${SEND_OTP}/${adminId}`,
    method: "post",
    data: putData,
  })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        setIsLoading(false);
        handleSendOtpModelClose(res.status);
        handleVerifyOtpModelOpen()
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};

const handleSendOtpModelOpen=(rowData)=>{
  console.log("rowData",rowData);
  setEmail(rowData.original.email)
  setAdminId(rowData.original.adminId)
  setSendOtpModel(true)
}
const handleSendOtpModelClose=()=>{
  setSendOtpModel(false)
}

const handleSendOtpModelIconClose=()=>{
  setSendOtpModel(false)
}
const SendOtpModelAction = (
  <>
    <CustomButton
      fieldType="submit"
      buttonName="Send"
      // click={handleResetPasswordClose}
    />
  </>
);
const sendOtp = (
  <>
    <form
      onSubmit={handleSubmit(onOtpSubmit)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
       
            <CustomInputField
          fieldName="updateemail"
          fieldPattern={EMAIL_REGEX_PATTERN}
          patternErrorMessage={EMAIL_INVALID_MESSAGE}
          fieldId="updateemail"
          fieldType="text"
          fieldLabel="Email"
          fieldControl={control}
          fieldError={errors}
          fieldRequiredIcon="*"
          value={email}
          onInput={handleEditEmail}
          disabled
        />
      </DialogContent>
      <DialogActions>{SendOtpModelAction}</DialogActions>
    </form>
  </>
);
 //send email otp end

 //otp model

 const [otp,setOtp]=useState("")
 const [verifyOtpModel,setVerifyOtpModel]=useState(false)

const handleVerifyOtpModelOpen=()=>{
  setVerifyOtpModel(true)
}
const handleVerifyOtpModelClose=()=>{
  setVerifyOtpModel(false)
  setOtp("")
}

const handleVerifyOtpModelIconClose=()=>{
  setVerifyOtpModel(false)
  setOtp("")
}

const onVerifySubmit = (data) => {
  const putData = {
   otp:otp,
  };

    setIsLoading(false);


  request({
    url: `${VERIFY_OTP}/${adminId}`,
    method: "post",
    data: putData,
  })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        setIsLoading(false);
        handleVerifyOtpModelClose(res.status);
        setOtp("")
        handleResetPasswordOpen()
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};

const VerifyOtpModelAction = (
  <>
    <CustomButton
      fieldType="submit"
      buttonName="Submit"
      // click={handleResetPasswordClose}
    />
  </>
);

 const OtpModel = (
  <>
    <form
      onSubmit={handleSubmit(onVerifySubmit)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
       
            <CustomInputField
          fieldName="verifyOtp"
          fieldPattern={ONLY_DIGITS}
          requiredErrorMessage={OTP_REQUIRED_MESSAGE}
          patternErrorMessage={OTP_INVALID_MESSAGE}
          minLength={4}
          minLengthErrorMessage={MIN_LENGTH_FOUR_MESSAGE}
          maxLength={6}
          maxLengthErrorMessage={MIN_LENGTH_SIX_MESSAGE}
          fieldId="verifyOtp"
          fieldType="text"
          fieldLabel="Verify OTP"
          fieldControl={control}
          fieldError={errors}
          fieldRequiredIcon="*"
          value={otp}
          onInput={(e)=>setOtp(e.target.value)}
          
        />
      </DialogContent>
      <DialogActions>{VerifyOtpModelAction}</DialogActions>
    </form>
  </>
);

  return (
    <div>
      <CustomToastContainer />
      <CustomHeading title="Group Admin" />
      {isLoading && <CustomLoading />}

      <CustomModel
        title="Add Group Admin"
        submit={onSubmitAdd}
        content={addModelContent}
        action={addModelAction}
        openStatus={addOpen}
        closeStatus={handleAddClose}
        iconCloseStatus={handleAddIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Delete Group Admin"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Delete Group Admin"
        content={deletePrimaryModel}
        action={okCancelPrimaryButtonActions}
        openStatus={deletePrimaryOpen}
        closeStatus={handleDeletePrimaryClose}
        iconCloseStatus={handleDeletePrimaryClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Reset Password"
        submit={onSubmitReset}
        content={resetPasswordModelContent}
        action={resetPasswordModelAction}
        openStatus={resetPasswordOpen}
        closeStatus={handleResetPasswordClose}
        iconCloseStatus={handleResetPasswordIconClose}
        reset={reset}
        isLoading={isLoading}
      />
<CustomModel
        title="Update Group Admin"
        submit={onSubmitUpdate}
        content={updateModel}
        action={updateActions}
        openStatus={updateOpen}
        closeStatus={handleUpdateClose}
        iconCloseStatus={handleUpdateIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Send Otp"
        submit={onSubmitUpdate}
        content={sendOtp}
        action={SendOtpModelAction}
        openStatus={sendOtpModel}
        closeStatus={handleSendOtpModelClose}
        iconCloseStatus={handleSendOtpModelIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Verify Otp"
        submit={onSubmitUpdate}
        content={OtpModel}
        action={VerifyOtpModelAction}
        openStatus={verifyOtpModel}
        closeStatus={handleVerifyOtpModelClose}
        iconCloseStatus={handleVerifyOtpModelIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Group Admin Activataion"
        submit={onSubmitActiveConfirm}
        content={activeConfirmModel}
        action={activeConfirmActions}
        openStatus={activeConfirmOpen}
        closeStatus={handleActiveConfirmClose}
        iconCloseStatus={handleActiveConfirmClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />

      <CustomModel
        title="Group Admin Deactivation"
        submit={onSubmitDeactiveConfirm}
        content={deactiveConfirmModel}
        action={deactiveConfirmActions}
        openStatus={deactiveConfirmOpen}
        closeStatus={handledeactiveConfirmClose}
        iconCloseStatus={handledeactiveConfirmIconClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />

      <CustomModel
        title="Group Admin Deactivation"
        content={deactivePrimaryConfirmModel}
        action={deactivePrimaryConfirmActions}
        openStatus={deactivePrimaryConfirmOpen}
        closeStatus={handledeactivePrimaryConfirmClose}
        iconCloseStatus={handledeactivePrimaryConfirmClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />
      <CustomModel
        title="Promote as primary"
        submit={onSubmitPromote}
        content={promoteConfirmModel}
        action={promoteActions}
        openStatus={promoteOpen}
        closeStatus={handlePromoteClose}
        iconCloseStatus={handlePromoteIconClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />

<CustomModel
        title="Resend Email"
        submit={onResendSubmit}
        content={resendModel}
        action={ResendActions}
        openStatus={resendOpen}
        closeStatus={handleResendClose}
        iconCloseStatus={handleResendIconClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />
      <CustomModel
        title="Resend Email"

        content={resendFailModel}
        action={ResendFailActions} 
        openStatus={resendFailOpen}
        closeStatus={handleResendFailClose}
        iconCloseStatus={handleResendFailIconClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />
      <CustomMaterialTableActions
        columns={groupAdminListTable}
        data={adminDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
      />
    </div>
  );
}

export default GroupAdmin;
