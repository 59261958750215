import {
  AccountBalanceOutlined,
  CreditCardOutlined,
  DeleteOutline,
  EditNoteOutlined,
  ListAltOutlined,
  PersonAddDisabledOutlined,
  PersonAddOutlined,
} from "@mui/icons-material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CustomStyle } from "../../components/CustomStyle";
import {
  GIF_LIST,
  BASE_URL,
 
} from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import { request } from "../../services/AxiosConfig";

import {
  DEACTIVATE_REQUIRED_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REMARKS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import CustomInputField from "../../components/CustomInputField";
import dayjs from "dayjs";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomMaterialTableActions from "../../components/CustomMaterialTableActions";
import moment from "moment";
function SubscriberList() {
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [notificationData, setNotificationData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );
  const navigate = useNavigate();
  const [editId, setEditId] = useState("");
  const handleEditClick = (selectedRow) => {
    console.log(selectedRow.original.customerNotificationId);
    navigate(`/notificationUpdate/${selectedRow.original.customerNotificationId}`);
    
  };


  const handleAddClickOpen = () => {
    navigate(`/notificationAdd`);
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const getAllNotifications = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GIF_LIST}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        // setGroupDetails(data);
        setNotificationData(data)

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  const handleDeleteClickOpen = (selectedRow) => {
    console.log("bylawid",selectedRow);
    setEditId(selectedRow.original.customerNotificationId);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setIsLoading(false)
  };
  
  const onSubmitDelete = async () => {
    setIsLoading(true);

    request({
      url: `${GIF_LIST}/${editId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getAllNotifications();
          setIsLoading(false);

        }else{
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.res);
        if (error.res) {
          setIsLoading(false);

          console.log("error.response.status" + error.res.status);
        }
      });
   
  };
  const okCancelButtonActions = (
    <>
     <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
     
    </>
  );
  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete this Notification ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
 
  const subscriberListTable = useMemo((rowData) => [
   {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },

  {
    accessorKey: 'imageUrl',
    header: 'Image URL',
    accessorFn: (row) => row.imageUrl ? row.imageUrl.slice(12) : '',
  },
   {
            accessorKey:"startDate",
            header:"Start Date",
            accessorFn: (row) => moment(row.startDate).format("MM/DD/YYYY"),

        },
        {
          accessorKey:"endDate",
          header:"End Date",
          accessorFn: (row) => moment(row.endDate).format("MM/DD/YYYY"),

      },
  {
      accessorKey:'isActive',
      accessorFn: (row) => (row && row.isActive ? "Active" : "InActive"),
      header:'Status'
  },
  ]);

  const rowActions = (row) => {
    const actions = [
    
      {
        label: "Edit",
        icon: <CreditCardOutlined color="primary" />,
        click: () => handleEditClick(row),
      },
      {
          label: "Delete",
      // icon: <Delete color="primary" />,
      icon: <DeleteOutline color="error" />,

      click: () => handleDeleteClickOpen(row),
    },
    ];
  

    return actions;
  };
  

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Notification" />
      <CustomToastContainer />

      <CustomModel
        title=" Delete Notification"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}

      />

      <CustomMaterialTableActions
        columns={subscriberListTable}
        data={notificationData}
        rowActions={rowActions}
        // isLoading={isLoading}
        // onRowClick={handleEditClick}
        actionButtons={tableActionButtons}
      />
    
    </div>
  );
}

export default SubscriberList;
