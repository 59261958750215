import React, { useEffect, useRef, useState } from "react";
import CustomInputField from "../../components/CustomInputField";

import {
  ADDRESS_INVALID_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  COMMON_MESSAGE,
  CONTACT_REQUIRED_MESSAGE,
  COUNTRY_INVALID_MESSAGE,
  CURRENT_AND_NEW_PASSWORD_CHECKING,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  ENTITY_NUMBER_INVALID_MESSAGE,
  ENTITY_NUMBER_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FORMATION_DATE_REQUIRED_MESSAGE,
  FREE_TRIAL_INVALID_MESSAGE,
  FREE_TRIAL_REQUIRED_MESSAGE,
  GROUP_NAME_INVALID_MESSAGE,
  GROUP_NAME_REQUIRED_MESSAGE,
  HOUSE_COUNT_INVALID_MESSAGE,
  HOUSE_COUNT_REQUIERD_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_FIFTY,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FOUR,
  MAX_LENGTH_ONE_TWENTY_MESSAGE,
  MAX_LENGTH_FIFTEEN,
  MAX_LENGTH_THIRTY_MESSAGE,
  MAX_LENGTH_THREE_MESSAGE,
  MAX_LENGTH_TWO_FIFTY,
  MAX_LENGTH_TWO_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_ONE,
  MIN_LENGTH_ONE_MESSAGE,
  MIN_LENGTH_TEN,
  MIN_LENGTH_VALIDATE_MESSAGE,
  NEW_PASSWORD_CURRENT_PASSWORD_CHECKING,
  PERCENTANGE_INVALID_MESSAGE,
  SUBSCRIPTION_PLAN_TERM_REQUIRED_MESSAGE,
  SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE,
  SUBSCRIPTION_START_DATE_REQUIRED_MESSAGE,
  ZIPCODE_INVALID_MESSAGE,
  ZIPCODE_MAX_LENGTH_MESSAGE,
  ZIPCODE_MIN_LENGTH_MESSAGE,
  ZIPCODE_REQUIRED_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  MIN_LENGTH_THREE_VALIDATE_MESSAGE,
  MAX_LENGTH_TWENTY_MESSAGE,
  MIN_LENGTH_LASTNAME_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  SPECIAL_DISCOUNT_REQUIRED_MESSAGE,
  MIN_LENGTH_TWO,
} from "../../utills/ApplicationConstants";
import { Controller, set, useForm } from "react-hook-form";
import CustomHeading from "../../components/CustomHeading";
import CustomButton from "../../components/CustomButton";
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERN,
  NINETY_PERCENTANGE,
  ONE_TO_NINETY_NINE,
  ONLY_ALPHABETIC,
  ONLY_ALPHANUMBERIC,
  ONLY_DIGITS,
  ONLY_DIGITS_HYPEN,
  ONLY_FOUR_NUMBERS,
  START_ALPHANUMERIC_ALL_SPECIAL_CHARACTERS,
  GROUP_ADMIN_EMAIL_REGEX_PATTERN,
  NINETY_PERCENTANGE_PATTEN,
  ALPHANUMBERIC_WITH_SPECTIAL_CHARACTER,
  ONLY_DIGITS_WITH_HYPEN,
  ALPHANUMBERIC_PUNCHUCATE,
  EMAIL_REGEX_PATTERNS,
} from "../../utills/ApplicationRegex";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "./../../components/CustomDatePicker";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomRadioButton from "./../../components/CustomRadioButton";
import {
  BASE_URL,
  GET_SUBSCIPTION,
  GROUP,
} from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CustomCheckBox from "../../components/CustomCheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InfoOutlined } from "@mui/icons-material";
import CustomDateEditPicker from "../../components/CustomDateEditPicker";

function AddSubscriber() {
  const [dueType, setDueType] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [formationDate, setFormationDate] = useState("");
  const [type, setType] = useState("");
  const [planTerm, setPlanTerm] = useState("1 YEAR");
  const [planType, setPlanType] = useState("");
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(dayjs());
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [virtualManager, setVirtualManager] = useState(false);
  const [decPhnNumber, setDecPhnNumber] = useState(false);
  const [domainReg, setDomainReg] = useState(false);
  // const [webHosting, setWebHosting] = useState(false);
  const [arc, setARC] = useState(false);
  const [pricing, setPricing] = useState("");
  const [specialDiscountDisabled, setSpecialDiscountDisabled] = useState(true);
  const [discount, setDiscount] = useState("");
  const [houseCount, setHouseCount] = useState("");
  const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState("");
  const [planTypeFrPrice, setPlanTypeFrPrice] = useState("");
  const [isFieldEnabled, setIsFieldEnabled] = useState(false);
  const [specialDiscount, setSpecialDiscount] = useState("");
  const [specialDiscountError, setSpecialDiscountError] = useState("");
  const [ein, setEin] = useState("");
  const [discTypeShow, setDiscTypeShow] = useState(false);
  const [refTotal, setRefTotal] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );
  const [remarks, setRemarks] = useState("");

  const [discountType, setDiscountType] = useState();
  const [freeTrial, setFreeTrial] = useState(null);
  const handleClick = () => {
    navigate(-1);
  };

  const virtualManagerChange = (e) => {
    setVirtualManager(e.target.checked);
  };
  const decPhnNumberChange = (e) => {
    setDecPhnNumber(e.target.checked);
  };
  const domainRegChange = (e) => {
    console.log("e", e.target.checked);
    setDomainReg(e.target.checked);
    console.log("domainReg", domainReg);
  };
  const webHostingChange = (e) => {
    setWebHosting(e.target.checked);
  };

  const arcChange = (e) => {
    console.log("arc", arc);
    console.log(e.target.checked);
    setARC(e.target.checked);
  };
  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [additionalPrices, setAdditionalPrices] = useState({
    virtualManager: 0,
    dedicatedPhone: 0,
    domainRegistration: 0,
    webHosting: 0,
    virtualarc: 0,
  });

  //basic
  const [basic, setBasic] = useState(true);
  const [domain, setDomain] = useState(false);
  const [dedicated, setDedicated] = useState(false);
  const [arcs, setArcs] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [webHosting, setWebHosting] = useState(false);
  //extended
  const [extended, setExtended] = useState(true);
  const [itServices, setItServices] = useState(true);
  const [accounting, setAccounting] = useState(true);
  const [domainExt, setDomainExt] = useState(false);
  const [dedicatedExt, setDedicatedExt] = useState(false);
  const [arcsExt, setArcsExt] = useState(false);
  const [virtualExt, setVirtualExt] = useState(false);
  const [webHostingExt, setWebHostingExt] = useState(false);
  const [endPeriod,setEndPeriod] = useState('');

  //premium
  const [premium, setPremium] = useState(true);

  const getAdditionalPrice = async (fieldName) => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${GET_SUBSCIPTION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        const priceObject = data.find((item) => item.type === planType);
        console.log(
          "priceObject",
          priceObject ? parseFloat(priceObject[fieldName]) : 0
        );
        return priceObject ? parseFloat(priceObject[fieldName]) : 0;
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return 0; // Return a default value in case of an error
    }
  };

  const fetchAdditionalPrice = async (fieldName, checkboxState) => {
    const additionalPrice = await getAdditionalPrice(fieldName);

    // Check if the checkbox is already checked
    const isCheckboxChecked = checkboxState; // Assuming checkboxState is a boolean

    // Add additional price only if checkbox is checked
    if (isCheckboxChecked) {
      setAdditionalPrices((prevPrices) => ({
        ...prevPrices,
        [fieldName]: additionalPrice,
      }));
    } else {
      // If checkbox is unchecked, set the additional price to 0
      setAdditionalPrices((prevPrices) => ({
        ...prevPrices,
        [fieldName]: 0,
      }));
    }

    // Use the additionalPrice as needed
    console.log("additionalPrice", additionalPrice);
  };

  useEffect(() => {
    const fetchAdditionalPrices = async () => {
      await fetchAdditionalPrice("virtualManager", virtualExt);
      await fetchAdditionalPrice("dedicatedPhone", dedicatedExt);
      await fetchAdditionalPrice("domainRegistration", domainExt);
      await fetchAdditionalPrice("webHosting", webHostingExt);
      await fetchAdditionalPrice("virtualarc", arcsExt);
    };

    fetchAdditionalPrices();
  }, [planType, virtualExt, dedicatedExt, domainExt, webHostingExt, arcsExt]);

  const handleCheckboxChange = async (fieldName) => {
    const price = await getAdditionalPrice(fieldName);

    console.log("Updating state for", fieldName, "with price", price);
   
    setAdditionalPrices((prevPrices) => ({
      ...prevPrices,
      [fieldName]: !prevPrices[fieldName] ? price : 0,
    }));
  };
  const handleBasicChange = () => {
    setBasic((prevChecked) => !prevChecked);
  };

  const handleVirtualChange = () => {
    setVirtual((prevChecked) => !prevChecked);
  };

  const handleDedicatedChange = () => {
    setDedicated((prevChecked) => !prevChecked);
  };

  const handleArcChange = () => {
    setArcs((prevChecked) => !prevChecked);
  };

  const handleDomainChange = () => {
    setDomain((prevChecked) => !prevChecked);
  };

  const handleWebHosting = () => {
    setWebHosting((prevChecked) => !prevChecked);
  };

  const handleExtendedChange = () => {
    setExtended((prevChecked) => !prevChecked);
  };

  const handleItservices = () => {
    setItServices((prevChecked) => !prevChecked);
  };

  const handleAccountingChange = () => {
    setAccounting((prevChecked) => !prevChecked);
  };

  const handleVirtualChangeExt = () => {
    setVirtualExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  };

  const handleDedicatedChangeExt = () => {
    setDedicatedExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  };

  const handleArcChangeExt = () => {
    setArcsExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  };

  const handleDomainChangeExt = () => {
    setDomainExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  };

  const handleWebHostingExt = () => {
    setWebHostingExt((prevChecked) => !prevChecked);
    // setSpecialDiscount("")
  };

  const handlePremiumChange = () => {
    setPremium((prevChecked) => !prevChecked);
  };

  const formatEin = (value) => {
    const cleanedValue = value.replace(/-/g, "");

    const formattedValue = cleanedValue.replace(/(\d{3})(?=\d)/g, "$1-");

    return formattedValue;
  };

  const einChange = (e) => {
    const inputValue = e.target.value;
    // const formattedValue = formatEin(inputValue);
    const formattedValue = inputValue;
    setEin(formattedValue);
  };

  const handleHouseCountChange = (e) => {
    setHouseCount(e.target.value);
    setSpecialDiscount(""); 
    setSpecialDiscountError("");
    calculateTotalSubscription();
  };
  const handlePlanTypeChange = (e, field) => {
    setPlanType(e.target.value); // Update state with the selected plan type
    field.onChange(e.target.value); // Trigger the Controller's onChange method
    setSpecialDiscount(""); 
    setArcsExt(false);
    setARC(false);
    setWebHostingExt(false)
    setDomainExt(false)
    setDedicatedExt(false)
    setVirtualExt(false)
    calculateTotalSubscription()
    // Reset special discount if needed
  };
  const calculateDiscount = (price) => {
    const discountAmount = price * 0.1;
    const discountedPrice = price - discountAmount;
    return discountedPrice;
  };

  // const calculateTotalSubscription = () => {
  //   if (houseCount && discount) {
  //     const total = parseInt(houseCount) * parseFloat(discount);
  //     setTotalSubscriptionAmount(total.toFixed(2));
  //     setRefTotal(total.toFixed(2));
  //     setDiscTypeShow(true);
  //   }
  // };

  // const calculateTotalSubscription = () => {
  //   if (houseCount !== "" && discount !== "") {
  //     const totalAdditionalPrice = Object.values(additionalPrices).reduce(
  //       (acc, price) => acc + price,
  //       0
  //     );
  //     console.log("totalAdditionalPrice", totalAdditionalPrice);
  //     const basePrice = parseFloat(planTypeFrPrice);
  //     const total =
  //       parseInt(houseCount) * parseFloat(discount) + totalAdditionalPrice;

  //     // Apply special discount if applicable
  //     const specialDiscountAmount = parseFloat(specialDiscount) || 0;
  //     const discountedTotal = total - specialDiscountAmount;

  //     setTotalSubscriptionAmount(discountedTotal.toFixed(2));
  //     setRefTotal(total.toFixed(2));
  //     setDiscTypeShow(true);
  //   } else {
  //     const total = 0;
  //     setTotalSubscriptionAmount(total);
  //   }
  // };

  const calculateTotalSubscription = () => {
    if (houseCount !== "" && discount !== "") {
      console.log(houseCount, "house count");
      console.log(discount, "discount");
      console.log(additionalPrices);
      const totalAdditionalPrice = Object.values(additionalPrices).reduce(
        (acc, price) => acc + price,
        0
      );

      console.log(totalAdditionalPrice, "total additional price");
      // console.log("totalAdditionalPrice", totalAdditionalPrice)
      const basePrice = parseFloat(planTypeFrPrice);
      console.log(basePrice, "base price");
      const total = (parseInt(houseCount) * parseFloat(discount));
      console.log(total, "total");
      // Apply special discount if applicable
      const specialDiscountAmount = specialDiscount || 0;
      console.log(specialDiscountAmount,"Special discount amount");
      console.log(specialDiscount,"special discount");
     
      
      if(discountType=="PERCENTAGE"){ 
        const specialDiscountPercentage = (parseFloat(total) / 100)*specialDiscount;
        console.log(specialDiscountPercentage,"specail discount percentage");
  

      if (totalAdditionalPrice) {
        console.log("inside if ");
        console.log(total,"-",specialDiscountPercentage);
        
        const discountedTotal = total - specialDiscountPercentage;
        // const totalValueAfterAddon = discountedTotal + totalAdditionalPrice
        let totalValueAfterAddon = discountedTotal;
      
        if (virtualExt) {
          console.log("virtual",additionalPrices.virtualManager);
          totalValueAfterAddon += additionalPrices.virtualManager;
        }
        if (dedicatedExt) {
          console.log("dedicated extended",additionalPrices.dedicatedPhone);
          totalValueAfterAddon += additionalPrices.dedicatedPhone;
        }
        if (webHostingExt) {
          console.log("web hosting",additionalPrices.webHosting);
          totalValueAfterAddon += additionalPrices.webHosting;
        }
        if (arcsExt) {
          console.log("arc",additionalPrices.virtualarc);
          totalValueAfterAddon += additionalPrices.virtualarc;
        }
        console.log(discountedTotal, "discount total");
        console.log(totalValueAfterAddon, "total value after addon features");
        setTotalSubscriptionAmount(totalValueAfterAddon.toFixed(2));
        setRefTotal(total.toFixed(2));
        setDiscTypeShow(true);
      } else {
        console.log("inside else");
        // setTotalSubscriptionAmount("")
        console.log(total,"total");
        const discountedTotal = total - specialDiscountPercentage;
        console.log(total,"-",specialDiscountPercentage);
        console.log(discountedTotal, "discount total");
        // console.log(totalValueAfterAddon, "total value after addon features");
        setTotalSubscriptionAmount(discountedTotal.toFixed(2));
        setRefTotal(total.toFixed(2));
        setDiscTypeShow(true);
      }
    }else{
    
      console.log(specialDiscount, "special discount");
      console.log(specialDiscountAmount, "special discount amount");

      if (totalAdditionalPrice) {
        console.log("inside if ");
        console.log(total,"-",specialDiscountAmount);
        
        const discountedTotal = total - specialDiscountAmount;
        // const totalValueAfterAddon = discountedTotal + totalAdditionalPrice
        let totalValueAfterAddon = discountedTotal;
      
        if (virtualExt) {
          console.log("virtual",additionalPrices.virtualManager);
          totalValueAfterAddon += additionalPrices.virtualManager;
        }
        if (dedicatedExt) {
          console.log("dedicated extended",additionalPrices.dedicatedPhone);
          totalValueAfterAddon += additionalPrices.dedicatedPhone;
        }
        if (webHostingExt) {
          console.log("web hosting",additionalPrices.webHosting);
          totalValueAfterAddon += additionalPrices.webHosting;
        }
        if (arcsExt) {
          console.log("arc",additionalPrices.virtualarc);
          totalValueAfterAddon += additionalPrices.virtualarc;
        }
        console.log(discountedTotal, "discount total");
        console.log(totalValueAfterAddon, "total value after addon features");
        setTotalSubscriptionAmount(totalValueAfterAddon.toFixed(2));
        setRefTotal(total.toFixed(2));
        setDiscTypeShow(true);
      } else {
        console.log("inside else");
       
        console.log(total,"total");
        const discountedTotal = total - specialDiscountAmount;
        console.log(total,"-",specialDiscountAmount);
        console.log(discountedTotal, "discount total");
        // console.log(totalValueAfterAddon, "total value after addon features");
        setTotalSubscriptionAmount(discountedTotal.toFixed(2));
        setRefTotal(total.toFixed(2));
        setDiscTypeShow(true);
      }
    }
  
     

    } else {
      const total = 0;
      setTotalSubscriptionAmount(total);
    }
  };

  useEffect(() => {
    calculateTotalSubscription();
  }, [additionalPrices, houseCount, discount, planTypeFrPrice,totalSubscriptionAmount,specialDiscount]);
 
  const getAllSubscriptionDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_SUBSCIPTION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const price = data.find((item) => item.type === planType)?.price;
        if (price) {
          setPlanTypeFrPrice(price);
          const discountedPrice = calculateDiscount(price);
          setDiscount(discountedPrice);
          calculateTotalSubscription();
        }
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllSubscriptionDetails();
  }, [planType]);

  // useEffect(() => {
  //   calculateTotalSubscription();
  // }, [houseCount, discount]);

  // const typeChange = (e) => {
  //   setType(e.target.value);
  //   console.log(e.target.value);
  //   if (e.target.value) {
  //     setSubscriptionStartDate(dayjs());
  //     setFreeTrial(e.target.value);
  //   } else {
  //     setSubscriptionStartDate("");
  //     setSubscriptionEndDate(dayjs(""));
  //   }
  // };

  const handleDaysInputChange = (event) => {
    const inputDays = event.target.value;
    setType(inputDays);
  };

  useEffect(() => {
    // Update subscription end date based on entered days
    if (planTerm === "1 YEAR" && type !== 0 && type !== "") {
      console.log("workinggg");
      if (/^\d+$/.test(type)) {
        const newEndDate = dayjs()
          .add(1, "year")
          .add(parseInt(type, 10), "days")
          .subtract(1, "day");
        setSubscriptionEndDate(newEndDate);
      }
    } else {
      const defaultEndDate = dayjs().add(1, "year").subtract(1, "day");
      setSubscriptionEndDate(defaultEndDate);
    }
  }, [type, planTerm]);

  useEffect(() => {
    // Update subscription end date based on entered days
    if (planTerm === "1 YEAR" && type !== 0 && type !== "") {
      console.log("workinggg");
      if (/^\d+$/.test(type)) {
        const newEndDate = dayjs()
          
          .add(parseInt(type, 10), "days")
          .add(1, "month")
          .subtract(1, "day");
        setEndPeriod(newEndDate);
      }
    } else {
      const defaultEndDate = dayjs().add(1, "month").subtract(1, "day");
      setEndPeriod(defaultEndDate);
    }
  }, [type, planTerm]);
  

  useEffect(() => {
    // Update subscription end date based on entered days
    if (planTerm === "1 YEAR" && type !== 0 && type !== "") {
   
      if (/^\d+$/.test(type)) {
        const newStartDate = dayjs()
          .add(parseInt(type, 10), "days");
          setSubscriptionStartDate(newStartDate);
      }
    } else {
      setSubscriptionStartDate(dayjs());
    }
  }, [type, planType]);

  const calculateEndDate = () => {
    if (subscriptionStartDate && planTerm) {
      let endDate = dayjs(subscriptionStartDate).format("YYYY-MM-DD");
      if (freeTrial) {
        endDate = dayjs(endDate).add(freeTrial, "day");
      }
      endDate = dayjs(endDate).add(parseInt(planTerm), "year");
      return endDate;
    }
    return null;
  };

  // useEffect(() => {
  //   const calculatedEndDate = calculateEndDate();
  //   setSubscriptionEndDate(calculatedEndDate);
  // }, [subscriptionStartDate, freeTrial]);

  const handleSpecialDiscountChange = (e) => {
    const newSpecialDiscount = e.target.value;
    setSpecialDiscount(newSpecialDiscount);

    if (discountType === "DOLLAR") {
      if (newSpecialDiscount === "") {
        calculateTotalSubscription();
        setSpecialDiscountError("");
      } else {
        const specialDiscountAmount = parseFloat(newSpecialDiscount);
  
        if (isNaN(specialDiscountAmount) || specialDiscountAmount < 0 || specialDiscountAmount > refTotal) {
          setSpecialDiscountError(`The special discount amount should be between 0 and ${refTotal}`);
        } else if (!/^\d+(\.\d+)?$/.test(newSpecialDiscount)) {
          setSpecialDiscountError(PERCENTANGE_INVALID_MESSAGE);
        } else {
          const updatedTotalSubscriptionAmount = refTotal - specialDiscountAmount;
          setTotalSubscriptionAmount(updatedTotalSubscriptionAmount.toFixed(2));
          setSpecialDiscountError("");
        }
      }
    } else {
      setSpecialDiscountError("");
    }
  };

  const handleSpecialPercDiscountChange = (e) => {
    const newSpecialDiscount = e.target.value;
    setSpecialDiscount(newSpecialDiscount);

    if (newSpecialDiscount === "") {
      calculateTotalSubscription();
      setSpecialDiscountError("");
    } else {
      const specialDiscountPercentage = parseFloat(newSpecialDiscount);
      if (specialDiscountPercentage < 1 || specialDiscountPercentage > 90) {
        setSpecialDiscountError(
          "Special discount percentage must be between 1 to 90"
        );

        setTotalSubscriptionAmount(totalSubscriptionAmount);
      } else if (!NINETY_PERCENTANGE_PATTEN.test(specialDiscountPercentage)) {
        setSpecialDiscountError(PERCENTANGE_INVALID_MESSAGE);

        setTotalSubscriptionAmount(totalSubscriptionAmount);
      } else {
        const discountAmount = (refTotal * specialDiscountPercentage) / 100;

        const updatedTotalSubscriptionAmount = refTotal - discountAmount;
        setTotalSubscriptionAmount(updatedTotalSubscriptionAmount.toFixed(2));
        setSpecialDiscountError("");
      }
    }
  };

  const handleRadioChange = (value) => {
    setSpecialDiscount("");
    calculateTotalSubscription();
    setIsFieldEnabled(true);
    setDiscountType(value);
    setSpecialDiscountError();
  };

  const onSubmit = (data) => {
    console.log(domainReg);
    const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);
    let hasErrors = false;

    // // Perform form validation
    // if (errors) {
    //     console.log("workinggg")
    //     focus();
    //     hasErrors = true;
    // }

    if (discountType === "DOLLAR" || discountType === "PERCENTAGE") {
      console.log("working");
      if (
        specialDiscount === "" ||
        specialDiscount === null ||
        Number(specialDiscount) === 0
      ) {
        setError("specialDiscount", {
          type: "manual",
          message: SPECIAL_DISCOUNT_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
    }

    if (!isValidPhoneNumberForCountry) {
      setIsLoading(false);
      return;
    }
    if (specialDiscountError != "") {
      return;
    }

    if (parseInt(data.houseCount) < 1) {
      setError("houseCount", {
        type: "manual",
        message: "House count must be between 1 and 9999.",
      });
      return;
    }

    if (parseInt(type) < 1) {
      setError("freeTrial", {
        type: "manual",
        message: "Free trial must be between 1 and 90 days.",
      });
      return;
    }
    if (hasErrors) {
      return; // Stop form submission if errors exist
    }

    setIsLoading(true);
    const postData = {
      groupName: data.groupName,
      ein: data.entityNumber,
      formationDate: dayjs(formationDate).format("YYYY-MM-DD"),
      address: data.address,
      country: data.country,
      zipCode: data.zipcode,
      houseCount: data.houseCount,
      // groupAdminContact: "8754332839",
      contact: data.phoneNumber,
      groupAdminFirstName: data.firstName,
      groupAdminLastName: data.lastName,
      groupAdminEmail: data.email,
      freeTrial: type,
      planTerm: planTerm,
      planType: planType,
      price: totalSubscriptionAmount,
      endPeriod:endPeriod,
      // pricePayable:totalSubscriptionAmount,
      discount: discount,
      discountType: discountType,
      specialDiscount: specialDiscount,
      startDate: dayjs(subscriptionStartDate).format("YYYY-MM-DD"),
      endDate: dayjs(subscriptionEndDate).format("YYYY-MM-DD"),
      publishKey: data.publishkey,
      secretKey: data.secretkey,
      accountId: data.accountId,
      purchaseMail: data.purchasesMail,
      salesMail: data.salesMail,
      expensesMail: data.expensesMail,
      suppliersMail: data.suppliersMail,
      basic: basic,
      virtualManager: virtual || virtualExt,
      domainRegistration: domain || domainExt,
      dedicatedPhone: dedicated || dedicatedExt,
      webHosting: webHosting || webHostingExt,
      virtualArc: arcs || arcsExt,
      itServices: itServices,
      accounting: accounting,
      premium: premium,
      extended: extended,
      remarks: remarks,
    };
    console.log(data);
    console.log(postData);

    // return;
    request({ url: GROUP, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/subscribers`);
          }, 4000);
          // setIsLoading(false);
        } else if (res.status != 200) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDueChange = (event) => {
    setDueType(event.target.value);
    console.log(event.target.value + "+++++++++++++++");
  };
  const minDate = dayjs().subtract(100, "year").startOf("day");
  const maxDate = dayjs().endOf("day");

  const formationDateChange = (selectedFromDate) => {
    setFormationDate(selectedFromDate);
  };

  const focus = () => {
    const hasErrors = Object.keys(errors).length > 0;
    console.log("working");
    if (hasErrors) {
      const firstErrorField = Object.keys(errors)[0];
      const firstErrorInputElement = document.getElementById(firstErrorField);

      if (firstErrorInputElement) {
        firstErrorInputElement.focus();
      }
    }
  };

  useEffect(() => {
    focus();
  }, [errors]);

  const renderTooltip = (message) => (
    <Tooltip
      title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
      placement="right"
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Subscriber" />

        <div className="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <CustomHeading title="HOA Details" />
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                requiredErrorMessage={GROUP_NAME_REQUIRED_MESSAGE}
                fieldPattern={ALPHANUMBERIC_PUNCHUCATE}
                patternErrorMessage={GROUP_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                multiline={true}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldName="groupName"
                fieldId="groupName"
                fieldType="text"
                fieldLabel="Group Name(HOA Name)"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
                 tooltipMessage="Enter the name of your group"
              />

              <CustomInputField
                requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                patternErrorMessage={ADDRESS_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_TWO}
                maxLength={250}
                multiline={true}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldName="address"
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
               tooltipMessage="Enter your full address. Ensure the information is accurate for correspondence and verification purposes."
              />

              <CustomInputField
                fieldName="country"
                fieldId="country"
                fieldType="text"
                fieldLabel="Country"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={COUNTRY_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={50}
                multiline={true}
                maxLengthErrorMessage={MAX_LENGTH_FIFTY}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
              tooltipMessage="Enter the name of your county. This information is important for regional identification and processing purposes."
              />

              <CustomInputField
                requiredErrorMessage={ZIPCODE_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldName="zipcode"
                fieldId="zipcode"
                fieldType="text"
                fieldLabel="ZipCode"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
             tooltipMessage="Enter your ZIP Code. This 5-digit code helps identify your specific geographic location for mail delivery and services."
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: PHONE_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: (value) =>
                      isValidPhoneNumber(value) || "Invalid phone number",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Contact"
                      clearErrors={clearErrors}
                      country="US"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      // onChange={handlePhoneChange}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={
                        fieldState.invalid ? fieldState.error?.message : ""
                      }
                      tooltipMessage={"Enter your phone number."}
                    />
                  </>
                )}
              />

              <CustomInputField
                requiredErrorMessage={ENTITY_NUMBER_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS_WITH_HYPEN}
                patternErrorMessage={ENTITY_NUMBER_INVALID_MESSAGE}
                minLength={3}
                minLengthErrorMessage={MIN_LENGTH_THREE_VALIDATE_MESSAGE}
                maxLength={20}
                maxLengthErrorMessage={MAX_LENGTH_TWENTY_MESSAGE}
                fieldName="entityNumber"
                fieldId="entityNumber"
                fieldType="text"
                fieldLabel="Entity Identity Number"
                placeholder={"000-000-000"}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
                value={ein}
                onInput={einChange}
                tooltipMessage={
                  "Enter your Identity Entity Number (IEN). This unique identifier is used to securely manage your account. Please ensure it is entered correctly and keep it confidential."
                }
              />
              <CustomDateEditPicker
                fieldName="formationDate"
                label="Formation Date"
                fieldRequiredIcon="*"
                control={control}
                requiredErrorMessage={FORMATION_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={formationDateChange}
                tooltipMessage={
                  "Select the date on which the group was officially formed. "
                }
              />
            </section>

            <br />

            {/* <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: CONTACT_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    error={fieldState.invalid}
                    country={"us"}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </>
              )}
            /> */}
            <br />
            <CustomHeading title="Group Admin Details" />
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                multiline={true}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
                tooltipMessage={
                  "Enter the first name of the group administrator. This will be used for identification and contact purposes."
                }
              />

              <CustomInputField
                fieldName="lastName"
                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_LASTNAME_MESSAGE}
                maxLength={60}
                multiline={true}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
                tooltipMessage={
                  "Enter the last name of the group administrator. This will be used for identification and contact purposes."
                }
              />

              <CustomInputField
                fieldName="email"
                requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                multiline={true}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
                tooltipMessage={
                  "Enter the email of the group administrator. This will be used for identification and contact purposes."
                }
              />
              {/* <Controller
                name="phoneNumberAdmin"
                control={control}
                rules={{ required: CONTACT_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <>
                    <PhoneInput
                      error={fieldState.invalid}
                      country={"us"}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </>
                )}
              /> */}
            </section>

            <CustomHeading title="Subscription Details" />
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                requiredErrorMessage={HOUSE_COUNT_REQUIERD_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={HOUSE_COUNT_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_ONE}
                maxLength={4}
                maxLengthErrorMessage={MAX_LENGTH_FOUR}
                fieldName="houseCount"
                fieldId="houseCount"
                fieldType="text"
                fieldLabel="House Count"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                fieldRequiredIcon="*"
                onInput={handleHouseCountChange}
                tooltipMessage={
                  "Enter the total number of houses. This information is used for planning and subscription purposes."
                }
              />

              <CustomInputField
                fieldPattern={ONE_TO_NINETY_NINE}
                patternErrorMessage={FREE_TRIAL_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_ONE}
                maxLength={2}
                maxLengthErrorMessage={MAX_LENGTH_TWO_MESSAGE}
                requiredErrorMessage={FREE_TRIAL_REQUIRED_MESSAGE}  
                fieldName="freeTrial"
                fieldId="freeTrial"
                fieldType="text"
                fieldLabel="Free Trial (in days)"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                onInput={handleDaysInputChange}
                value={type}
                tooltipMessage={
                  "Enter your days to start your free trial."
                }
              />

              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <Controller
                    name="subscriptionPlanTerm"
                    control={control}
                    defaultValue={planTerm}
                    rules={{
                      required: SUBSCRIPTION_PLAN_TERM_REQUIRED_MESSAGE,
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Please Select Subscription Plan Term
                          <span style={{ color: "#D32F2F" }}> * </span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          onChange={(e) => {
                            setPlanTerm(e.target.value);
                            field.onChange(e.target.value);
                          }}
                          label=" Please Select Subscription Plan Term"
                          defaultValue={planTerm}
                        >
                          <MenuItem value="1 YEAR">1 Year</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div>{renderTooltip("Select the term duration for your subscription plan. Choose the option that best fits your needs and budget.")}</div>
              </div>

              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <Controller
                    name="subscriptionPlanType"
                    control={control}
                    rules={{
                      required: SUBSCRIPTION_PLAN_TYPE_REQUIRED_MESSAGE,
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Please Select Subscription Plan Type
                          <span style={{ color: "#D32F2F" }}> * </span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={planType}
                          onChange={(e) => handlePlanTypeChange(e, field)}
                          label=" Please Select Subscription Plan Type"
                        >
                          {/* <MenuItem value="free">Free</MenuItem> */}
                          <MenuItem value="BASIC">Basic</MenuItem>
                          <MenuItem value="EXTENDED">Extended</MenuItem>
                          <MenuItem value="PREMIUM">Premium</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div>{renderTooltip("Select the type of subscription plan you prefer. Choose from options such as Basic, Extended, or Premium, based on your desired features and budget.")}</div>
              </div>

              {planType === "BASIC" ? (
                <>
                  <section>
                    <CustomHeading title="Features included in this plan" />
                    <CustomCheckBox
                      fieldName="basic"
                      control={control}
                      labelName="Basic"
                      checked={basic}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("basic");
                        handleBasicChange();
                      }}
                    />
                  </section>
                  {/* <section>
                    <CustomHeading title="Add-on features" />
                    <CustomCheckBox
                      fieldName="virtualManager"
                      control={control}
                      labelName="Virtual Manager"
                      checked={virtual}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("virtualManager");
                        handleVirtualChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="dedicatedPhone"
                      control={control}
                      labelName="Dedicated Phone Number"
                      checked={dedicated}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("dedicatedPhone").then(() => {
                          calculateTotalSubscription();
                        });
                        handleDedicatedChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="domainRegistration"
                      control={control}
                      labelName="Domain Registration"
                      checked={domain}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("domainRegistration");
                        handleDomainChange()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="webHosting"
                      control={control}
                      labelName="Web Hosting"
                      checked={webHosting}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("webHosting");
                        handleWebHosting()
                      }}
                    />
                    <CustomCheckBox
                      fieldName="virtualarc"
                      control={control}
                      labelName="ARC"
                      checked={arcs}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("virtualarc");
                        handleArcChange()
                      }}
                    />
                  </section> */}
                </>
              ) : null}

              {planType === "EXTENDED" ? (
                <>
                  <section>
                    <CustomHeading title="Features included in this plan" />
                    <CustomCheckBox
                      fieldName="extended"
                      control={control}
                      labelName="Extended"
                      checked={extended}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("extended");
                        handleExtendedChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="itServices"
                      control={control}
                      labelName="IT Services"
                      checked={itServices}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("itServices");
                        handleItservices();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="accounting"
                      control={control}
                      labelName="Accounting"
                      checked={accounting}
                      onChange={() => {
                        handleCheckboxChange("accounting");
                        handleAccountingChange();
                      }}
                      disabled
                    />
                  </section>
                  <section>
                    <CustomHeading title="Add-on features" />
                    <CustomCheckBox
                      fieldName="virtualManager"
                      control={control}
                      checked={virtualExt}
                      labelName="Virtual Manager"
                      onChange={() => {
                        console.log("Checkbox changed!");
                        handleCheckboxChange("virtualManager");
                        handleVirtualChangeExt();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="dedicatedPhone"
                      control={control}
                      labelName="Dedicated Phone Number"
                      checked={dedicatedExt}
                      onChange={() => {
                        handleCheckboxChange("dedicatedPhone");
                        handleDedicatedChangeExt();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="domainRegistration"
                      control={control}
                      labelName="Domain Registration"
                      checked={domainExt}
                      onChange={() => {
                        handleCheckboxChange("domainRegistration");
                        handleDomainChangeExt();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="webHosting"
                      control={control}
                      labelName="Web Hosting"
                      checked={webHostingExt}
                      onChange={() => {
                        handleCheckboxChange("webHosting");
                        handleWebHostingExt();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="virtualarc"
                      control={control}
                      labelName="ARC"
                      checked={arcsExt}
                      onChange={() => {
                        handleCheckboxChange("virtualarc");
                        handleArcChangeExt();
                      }}
                    />
                  </section>
                </>
              ) : null}

              {planType === "PREMIUM" ? (
                <>
                  <section>
                    <CustomHeading title="Features included in this plan" />
                    <CustomCheckBox
                      fieldName="premium"
                      control={control}
                      labelName="Premium"
                      checked={premium}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("premium");
                        handlePremiumChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="itServices"
                      control={control}
                      labelName="IT Services"
                      checked={itServices}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("itServices");
                        handleItservices();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="accounting"
                      control={control}
                      labelName="Accounting"
                      checked={accounting}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("accounting");
                        handleAccountingChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="virtualManager"
                      control={control}
                      labelName="Virtual Manager"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("virtualManager");
                        handleVirtualChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="dedicatedPhone"
                      control={control}
                      labelName="Dedicated Phone number"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("dedicatedPhone");
                        handleDedicatedChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="domainRegistration"
                      control={control}
                      labelName="Domain Registration / Year"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("domainRegistration");
                        handleDomainChange();
                      }}
                    />
                    <CustomCheckBox
                      fieldName="webHosting"
                      control={control}
                      labelName="Web Hosting"
                      checked={true}
                      disabled
                      onChange={() => {
                        handleCheckboxChange("webHosting");
                        handleWebHosting();
                      }}
                    />
                  </section>

                  <section>
                    <CustomHeading title="Add-on features" />
                    <CustomCheckBox
                      fieldName="virtualarc"
                      control={control}
                      labelName="ARC"
                      checked={arcsExt}
                      onChange={() => {
                        handleCheckboxChange("virtualarc");
                        handleArcChangeExt();
                      }}
                    />
                  </section>
                </>
              ) : null}

              <CustomInputField
                fieldName="pricing"
                disabled={true}
                fieldId="pricing"
                fieldType="text"
                fieldLabel="Pricing per unit"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                value={planTypeFrPrice}
                tooltipMessage={
                  "This is the cost associated with each individual unit."
                }
              />

              <CustomInputField
                fieldName="discount"
                disabled={true}
                fieldId="discount"
                fieldType="text"
                fieldLabel="Discount"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                value={discount}
                tooltipMessage={
                  "This discount will be automatically deducted from the total price."
                }
              />

              <section>
                {discountType === "DOLLAR" ? (
                  <>
                    <CustomInputField
                      fieldName="specialDiscount"
                      fieldId="specialDiscount"
                      fieldType="number"
                      fieldLabel="Special Discount"
                      fieldControl={control}
                      fieldError={errors}
                      disabled={!isFieldEnabled}
                      onInput={handleSpecialDiscountChange}
                      value={specialDiscount}
                      tooltipMessage={
                        "Enter a special discount Dollor to be applied. This discount will also apply over total price."
                      }
                    />
                  </>
                ) : (
                  <CustomInputField
                    fieldName="specialDiscount"
                    fieldId="specialDiscount"
                    fieldType="number"
                    fieldLabel="Special Discount"
                    fieldControl={control}
                    fieldError={errors}
                    disabled={!isFieldEnabled}
                    value={specialDiscount}
                    onInput={handleSpecialPercDiscountChange}
                    tooltipMessage={
                      "Enter a special discount percentage to be applied. This discount will also apply over total price."
                    }
                  />
                )}
                {specialDiscountError && (
                  <p class="text-red-600 text-xs">{specialDiscountError}</p>
                )}

                <CustomRadioButton
                  fieldName="specialDiscountType"
                  fieldControl={control}
                  fieldError={errors}
                  valueOne="PERCENTAGE"
                  labelOne="Percentage"
                  valueTwo="DOLLAR"
                  labelTwo="Dollar"
                  onChange={handleRadioChange}
                  disabled={!discTypeShow}
                />
              </section>
              <CustomInputField
                fieldName="totalSubscriptionAmount"
                disabled={true}
                fieldId="totalSubscriptionAmount"
                fieldType="text"
                fieldLabel="Total Subscription Amount"
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                value={totalSubscriptionAmount}
                tooltipMessage={
                  "The total subscription amount will be calculated automatically based on selected subscription options."
                }
              />
              {specialDiscount ? (
                <>
                  <CustomInputField
                    fieldName="remarks"
                    fieldId="remarks"
                    fieldType="text"
                    fieldLabel="Remarks"
                    fieldControl={control} // Assuming control is defined elsewhere
                    fieldError={errors} // Assuming errors is defined elsewhere
                    fieldRequiredIcon="*"
                    // disabled={!isFieldEnabled}
                    onInput={(event) => {
                      setRemarks(event.target.value);
                    }}
                    value={remarks}
                    tooltipMessage={
                      "Add any additional comments or notes here. This field is optional and can be used to provide further context or instructions."
                    }
                  />
                  <br />
                </>
              ) : null}

              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Subscription Start Date"
                      value={subscriptionStartDate}
                      sx={{ width: "100%" }}
                      onChange={(date) => setSubscriptionStartDate(date)}
                      slotProps={{
                        textField: {
                          variant: "standard",
                        },
                      }}
                      disabled
                    />
                  </LocalizationProvider>
                </div>
                <div>{renderTooltip("The subscription start date is automatically set based on the activation of your subscription plan.")}</div>
              </div>
              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Subscription End Date"
                      value={subscriptionEndDate}
                      sx={{ width: "100%" }}
                      onChange={(date) => setSubscriptionEndDate(date)}
                      slotProps={{
                        textField: {
                          variant: "standard",
                        },
                      }}
                      disabled
                    />
                  </LocalizationProvider>
                </div>
                <div>{renderTooltip("The subscription end date is automatically set based on the activation of your subscription plan.")}</div>
              </div>

              <CustomInputField
                fieldName="publishkey"
                fieldId="publishkey"
                fieldType="text"
                fieldLabel="Publish key"
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={120}
                maxLengthErrorMessage={MAX_LENGTH_ONE_TWENTY_MESSAGE}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "The publishable key from Stripe is a secure token provided by the Stripe payment processing platform. It is used to authenticate and authorize payment transactions. Please ensure this key is kept confidential and securely managed."
                }
              />

              <CustomInputField
                fieldName="secretkey"
                fieldId="secretkey"
                fieldType="text"
                fieldLabel="Secret key"
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={120}
                maxLengthErrorMessage={MAX_LENGTH_ONE_TWENTY_MESSAGE}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "The secret key from Stripe is a confidential token used for server-side communication with the Stripe API. Treat this key with the utmost confidentiality and do not expose it in client-side code or public repositories."
                }
              />

              <CustomInputField
                fieldName="accountId"
                fieldId="accountId"
                fieldType="text"
                fieldLabel="Account Id"
                fieldControl={control}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={30}
                maxLengthErrorMessage={MAX_LENGTH_THIRTY_MESSAGE}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "The Account ID from Stripe is a unique identifier assigned to your Stripe account. It is used for authentication and identification purposes within the Stripe platform. Keep this ID confidential to maintain account security and integrity."
                }
              />
              <CustomInputField
                fieldName="purchasesMail"
                fieldId="purchasesMail"
                fieldType="text"
                fieldLabel="Purchases Mail"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "Enter the email address designated for purchase communication."
                }
              />
              <CustomInputField
                fieldName="salesMail"
                fieldId="salesMail"
                fieldType="text"
                fieldLabel="Sales Mail"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "Enter the email address designated for sales-related communication. This email will be used for inquiries, quotations, and other sales-related correspondence."
                }
              />
              <CustomInputField
                fieldName="expensesMail"
                fieldId="expensesMail"
                fieldType="text"
                fieldLabel="Expenses Mail"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "Enter the email address designated for expenses-related communication. This email will be used for submitting receipts, expense reports, and other expenses-related correspondence."
                }
              />
              <CustomInputField
                fieldName="suppliersMail"
                fieldId="suppliersMail"
                fieldType="text"
                fieldLabel="Suppliers Mail"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldControl={control}
                fieldError={Object.keys(errors).length > 0}
                tooltipMessage={
                  "Enter the email address designated for communication with suppliers. This email will be used for inquiries, orders, and other supplier-related correspondence."
                }
              />
            </section>

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Add" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AddSubscriber;
