// export const EMAIL_REGEX_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const EMAIL_REGEX_PATTERN = /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i;

export const GROUP_ADMIN_EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9!#$%&'*+=?.+^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const ONLY_ALPHABETIC = /^[a-zA-Z ]*$/;

export const ONLY_ALPHABETIC_REGEX_PATTERN =/^[a-zA-Z](?:[-_ ]?[a-zA-Z])*$/ ;

export const ALPHABETIC_WITH_SPECIAL_CHARACTERS = /^[a-zA-Z][a-zA-Z0-9#\-_ ]*[a-zA-Z]$/;

export  const ALPHANUMBERIC_ALL_SPECIAL_CHARACTERS = /^[a-zA-Z][a-zA-Z0-9!@#$%^&*()_+={}[\]:;<>,.?\/\\~-]*[a-zA-Z]$/;

export const START_ALPHANUMERIC_ALL_SPECIAL_CHARACTERS = /^[a-zA-Z][a-zA-Z0-9!@#$%^&*()_+={}[\]:;<>,.?\/\\~-]*$/;

export const ONLY_ALPHANUMBERIC = /^[a-zA-Z][a-zA-Z0-9 ]*[a-zA-Z]$/;

export const ONLY_ALPHABETIC_WITH_WHITE_SPACE = /^\s*[a-zA-Z][a-zA-Z ]*[a-zA-Z]\s*$/

export const ALPHABETIC_WITH_UNDERSCORE = /^[a-zA-Z](?:[-_ ]?[a-zA-Z])*$/

export const ONLY_DIGITS = /^\d+$/;

export const DIGITS_ONE_TO_FOUR_DECIMAL =/^(?:[1-9]|[1-9]\d{1,3}|10000)$/

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+:";'.,/?><|`~=])[A-Za-z\d!@#$%^&*()_:"+;'.,/?><|`~=]{8,128}$/;

export const ONLY_DIGITS_ZIPCODE = /^(?!00000)\d{5}$/;


export const ONLY_NUMBERS = /^\d{3}/;

export const FIVE_DIGITS_TWO_DECIMAL = /^\d{1,5}(\.\d{0,2})?$/

export const TWO_DIGITS_TWO_DECIMAL = /^\d{1,2}(\.\d{0,2})?$/

export const ALL_SENARIO_EMAIL_REGEX = /^(?!\s*$)(?:(?!.*\.{2,})(?!.*@.*@.*)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\s*,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*)$/

export const ONLY_DIGITS_HYPEN = /^\d{3}-\d{3}-\d{3}$/

export const ONLY_DIGITS_WITH_HYPEN = /^[0-9\-]+$/

// export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+:";'.,/?><|`~=])[A-Za-z\d!@#$%^&*()_+:";'.,/?><|`~=]{8,128}$/;

export const ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN = /^[a-zA-Z0-9,.\-\p{P} ]*$/

export const NINETY_PERCENTANGE = /^([1-8]?\d(\.\d{1,2})?|90(\.0{1,2})?)$/

export const NINETY_PERCENTANGE_PATTEN =/^(?:[1-9](?:\.\d{1,2})?|[1-8][0-9](?:\.\d{1,2})?|90(?:\.0{1,2})?)$/


export const ALPHANUMBERIC_PUNCHUCATE = /^[a-zA-Z ][a-zA-Z0-9 '/ ]*[a-zA-Z0-9 ]$/;

export const ALPHANUMBERIC_WITH_SPECTIAL_CHARACTER= /^(?=.*[a-zA-Z])[a-zA-Z0-9 ,'/]+$/;

export const ONE_TO_NINETY_NINE = /\b([1-9]|[1-8][0-9]|90)\b$/

export const ONLY_FOUR_NUMBERS = /^\d{4}/;
export const EMAIL_REGEX_PATTERNS = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // Updated pattern




