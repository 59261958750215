import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import {
  BASE_URL,
  BILLING_DETAILS,
  GROUP,
} from "../../utills/ApplicationRouting";
import {
  FileDownload,
  FileDownloadOutlined,
  FileUploadOutlined,
  ListAlt,
} from "@mui/icons-material";
import { CustomStyle } from "../../components/CustomStyle";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CustomLoading from "../../components/CustomLoading";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import CustomMaterialTableActions from "../../components/CustomMaterialTableActions";

function BillingHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [billingDetails, setBillingDetails] = useState([]);
  const { token } = useSelector((state) => state.hybridhoa);
  const navigate = useNavigate();
  const { groupId } = useParams();
  console.log(groupId, "testCase");
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const getAllBillingHistory = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);
      setIsLoading(true);

      const response = await axios.get(
        `${BASE_URL}${BILLING_DETAILS}/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      console.log(response);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setBillingDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllBillingHistory();
  }, [groupId]);

  const billingHistoryTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "#",
      Cell: ({ row }) => row.index + 1,
    },

    {
      accessorKey: "paymentDate",
      header: "Paid Date",
      accessorFn: (row) => moment(row.paymentDate).format("MM/DD/YYYY"),
    },

    {
      accessorKey: "amount",
      header: "Subscription Amount",
    },
    {
      accessorKey: "plantype",
      header: "Plan type",
      accessorFn: (row) =>convertToPascalCase (`${row.groupSubscriptionDetails ? row.groupSubscriptionDetails.planType : ""} `)
       
    },
  ]);
  // const csvConfig = mkConfig({
  //     fieldSeparator: ",",
  //     decimalSeparator: ".",
  //     useKeysAsHeaders: true,
  //   });
  //   const handleExportData = () => {
  //     const csv = generateCsv(csvConfig)(billingDetails);
  //     download(csvConfig)(csv);
  //   };
  // const tableActionButtons = (
  //   <>
  //   {billingDetails && billingDetails.length > 0 && (
  //     <Button
  //       color="primary"
  //       variant="contained"
  //       onClick={handleExportData}
  //       startIcon={<FileDownload />}
  //     >
  //       <span className={CustomStyle.containedButtonStyle}>
  //         Export All Data
  //       </span>
  //     </Button>
  //   )}
  //   </>
  // );
  const handleViewClick = (selectedRow) => {
    console.log(selectedRow.original.url);
    window.open(selectedRow.original.url, "_blank");
  };
  const rowActions = [
    {
      label: "Download Invoice",
      icon: <FileDownloadOutlined color="primary" />,
      click: (row) => handleViewClick(row),
    },
  ];
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Billing History" />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      {isLoading && <CustomLoading />}
      <CustomMaterialTableActions
        columns={billingHistoryTable}
        data={billingDetails}
        rowActions={rowActions}
        isLoading={isLoading}
      />
    </div>
  );
}

export default BillingHistory;
