import { Button, Card, CardContent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewPage from "../../components/CustomViewPage";
import { BASE_URL, PAYMENT_DETAILS,GROUP } from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomLoading from "../../components/CustomLoading";
import { set } from "react-hook-form";

function ViewBylaw() {
  const navigate = useNavigate();

  const { token } = useSelector((state) => state.hybridhoa);
  const [subscriberDetails, setSubscriberDetails] = useState([]);
  const [adminData,setAdminData]=useState('')
  const [dueType, setDueType] = useState("");
  const [groupName,setGroupName]=useState("");
  const [country,setCountry]=useState("");
  const [formationDate, setFormationDate] = useState("");
  const [address,setAddress]=useState("");
  const [type, setType] = useState("");
  const [planTerm, setPlanTerm] = useState("");
  const [planType, setPlanType] = useState("");
  const [subscriptionStartDate, setSubscriptionStartDate] = useState();
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();
  const [virtualManager, setVirtualManager] = useState(false);
  const [decPhnNumber, setDecPhnNumber] = useState(false);
  const [domainReg, setDomainReg] = useState(false);
  const [houseCount, setHouseCount] = useState("");
  const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState("");
  const [planTypeFrPrice, setPlanTypeFrPrice] = useState("");
  const [isFieldEnabled, setIsFieldEnabled] = useState(false);
  const [specialDiscount, setSpecialDiscount] = useState('');
  const [trialPeriod,setTrialPeriod]= useState('');
  const [specialDiscountError, setSpecialDiscountError] = useState('');
  const [ein,setEin]=useState('');
  const [zipcode,setZipcode]=useState('');
//   const [publishKey,setPublishKey]=useState('');
//   const [secretKey,setSecretKey]=useState('');
  const [accountId,setAccountId]=useState('');
  const [purchaseMail,setPurchaseMail]=useState('');
  const [publishKey,setPublishKey]=useState('');
  const [secretKey,setSecretKey]=useState('');
 
  const [salesMail,setSalesMail]=useState('');
  const [suppliersMail,setSuppliersMail]=useState('');
  const [expensesMail,setExpensesMail]=useState('');
  const [discTypeShow, setDiscTypeShow] = useState(false);
  const [refTotal, setRefTotal] = useState("");
  const [discountedPrice,setDiscountedPrice]=useState('');
  const [discountType,setDiscountType]= useState('');
  const [discount,setDiscount]= useState('');
  const [remarks,setRemarks]= useState('');

  
 //basic
 const [basic,setBasic]=useState(true);
 const [domain,setDomain]=useState(false);
 const [dedicated,setDedicated]=useState(false);
 const [arcs,setArcs]=useState(false);
 const [virtual,setVirtual]=useState(false);
 const [webHosting,setWebHosting]=useState(false);
 //extended
 const [extended,setExtended]=useState(true);
 const [itServices,setItServices]=useState(true);
 const [accounting,setAccounting]=useState(true);
 const [domainExt,setDomainExt]=useState(false);
 const [dedicatedExt,setDedicatedExt]=useState(false);
 const [arcsExt,setArcsExt]=useState(false);
 const [virtualExt,setVirtualExt]=useState(false);
 const [webHostingExt,setWebHostingExt]=useState(false);
 const [contact,setContact]=useState("")

 //premium
 const [premium,setPremium]=useState(true)
  const [isLoading, setIsLoading] = useState(true);
  const handleClick = () => {
    navigate(-1);
  };
  const { groupId } = useParams();

  const getSubscriberById = async () => {
    // setIsLoading(true);

    try {
      setIsLoading(true);
      const storedToken = token;

    //   console.log("ByLawId" + byLawId);
      const response = await axios.get(`${BASE_URL}${PAYMENT_DETAILS}/${groupId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        setSubscriberDetails(data);
        setIsLoading(false);
        console.log("Bylaw" + data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSubscriberById();
  }, [groupId]);

  
  const getGroupAdminDetailsById = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${GROUP}/${groupId}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setIsLoading(false);
        setAdminData(data);
        setEin(data.groupDetails.ein)
        setCountry(data.groupDetails.country)
        setIsLoading(false);
        setGroupName(data.group.groupName);
        setFormationDate(data.group.createdDateAndTime);
        setAddress(data.groupDetails?.address);
        setZipcode(data.groupDetails.zipcode)
        setContact(data.groupDetails.contact)
        setHouseCount(data.groupSubscriptionDetails.houseCount)
        setPlanTerm(data.groupSubscriptionDetails.planTerm)
        setPlanType(data.groupSubscriptionDetails.planType)
        setTotalSubscriptionAmount(data.groupSubscriptionDetails.price)
        setDiscount(data.groupSubscriptionDetails.discount)
        setSpecialDiscount(data.groupSubscriptionDetails.specialDiscount)
        setTrialPeriod(data.groupSubscriptionDetails.trialPeriod)
        setDiscountType(data.groupSubscriptionDetails.discountType);
        setPublishKey(data.groupPaymentConfig.publishKey)
        setSecretKey(data.groupPaymentConfig.secretKey)
        setAccountId(data.groupPaymentConfig.accountId)
        setPurchaseMail(data.groupAccountsConfig.purchaseMail)
        setSalesMail(data.groupAccountsConfig.salesMail)
        setSuppliersMail(data.groupAccountsConfig.supplierMail)
        setExpensesMail(data.groupAccountsConfig.expenseMail)
        // setVirtual(data.groupSubscriptionDetails.virtualManager)
        setVirtualExt(data.groupSubscriptionDetails.virtualManager)
        // setDedicated(data.groupSubscriptionDetails.dedicatedPhone)
        setDedicatedExt(data.groupSubscriptionDetails.dedicatedPhone)
        // setArcs(data.groupSubscriptionDetails.virtualArc)
        setArcsExt(data.groupSubscriptionDetails.virtualArc)
        // setDomain(data.groupSubscriptionDetails.domainRegistration)
        setDomainExt(data.groupSubscriptionDetails.domainRegistration)
        // setWebHosting(data.groupSubscriptionDetails.webHosting)
        setWebHostingExt(data.groupSubscriptionDetails.webHosting)
        setSubscriptionStartDate(data.groupSubscriptionDetails.startDate)
        setSubscriptionEndDate(data.groupSubscriptionDetails.endDate)
        setRemarks(data.groupSubscriptionDetails.remarks)

  } else {
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getGroupAdminDetailsById();
},[])


  const data = {
    pageTitle: "View Subscriber",
    buttons: [
      {
        buttonTitle: "Back",
        onClick: handleClick,
      },
    ],
    content: [
      {
        topic: "Group Name",
        description:groupName,
      },
      {
        topic: "Entity Identity Number ",
        description:ein,
      },
      {
        topic: "Formation Date",
        description:moment(formationDate).format("MM/DD/YYYY"),
      },
      {
        topic: "Address",
        description:address,
      },
      {
        topic: "Country",
        description:country,
      },
      {
        topic: "Zipcode",
        description: zipcode,
      },
      {
        topic: "Contact",
        description:contact,
      },
      {
        topic: "House Count",
        description:houseCount,
      },
      {
        topic: "Subscription Plan Term",
        description:planTerm,
      },
      {
        topic: "Subscription Plan Type ",
        description:planType,
      }, 
       {
        topic: "Trail Period (In Days)",
        description:trialPeriod,
      },
     
      {
        topic: "Discount",
        description:discount,
      },
      {
        topic: "Discount Type",
        description:discountType,
      },
      {
        topic: "Special Discount",
        description:specialDiscount,
      },
      {
        topic: "Total Subscription",
        description: totalSubscriptionAmount,
      },
      {
        topic: "Virtual Manager",
        description:virtualExt? "Yes" : "No",
      },
      {
        topic: "Dedicated Phone Number",
        description:dedicatedExt? "Yes" : "No",
      },
      {
        topic: "Web Hosting",
        description: webHostingExt? "Yes" : "No",
      },
      {
        topic: "ARC",
        description: arcsExt? "Yes" : "No",
      },
      {
        topic: "Domain Registration",
        description:domainExt? "Yes" : "No",
      },
      {
        topic: "Subscription Start Date",
        description: moment(subscriptionStartDate).format("MM/DD/YYYY"),
      },
      {
        topic: "Subscription End Date",
        description: moment(subscriptionEndDate).format("MM/DD/YYYY"),
      },
      {
        topic: "Publish key",
        description: publishKey,
      },
      {
        topic: "Secret key",
        description:  secretKey,
      },
      {
        topic: "Account id",
        description:  accountId,
      },
    
      {
        topic: "Purchase Mail",
        description: purchaseMail,
      },
      {
        topic: "Sales Mails",
        description: salesMail,
      },
      {
        topic: "Expense Mail",
        description:expensesMail,
      },
      {
        topic: "Suppliers Mail",
        description: suppliersMail,
      },
      {
        topic: "Remarks",
        description: remarks,
      },
      
    //   {
    //     topic: "Created Date",
    //     description: moment(subscriberDetails.createdDateAndTime).format("MM/DD/YYYY"),
    //   },
    ],
  };

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewPage data={data} />
    </div>
  );
}

export default ViewBylaw;
